import { apiService } from '../../../services';
import { ILotteryList } from '../../../types';
import { SET_KILL_PLAN_LOTTERY_LIST, SET_LOTTERY_LIST, SET_ALL_LATEST_RESULT } from '../../actionTypes';
import { AppDispatch, store } from '../../store';

import {category} from '../../../constants/utils/index'

const setLotteryList = (value:unknown) => ({
  type: SET_LOTTERY_LIST,
  value
});

const setAllLatestResult = (value:unknown) => ({
  type: SET_ALL_LATEST_RESULT,
  value
});

const setKillPlanLotteryList = (value:unknown) => ({
  type: SET_KILL_PLAN_LOTTERY_LIST,
  value
});

const GetLotteryList = () => (dispatch:AppDispatch) => {
  const {companyCode} = store.getState().appReducers
  apiService.get(`/lottery/list/${companyCode}`).then(res => {
    const { data } = res || {};
    const temp:ILotteryList = data?.data ?? { categorys:[],lotterys:[]}    
    dispatch(setLotteryList({...data.data}));
    dispatch(setKillPlanLotteryList(temp.lotterys.filter(item=> category.has(item.template) )));
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetAllLatestResult = () => (dispatch:AppDispatch) => {
  const {companyCode} = store.getState().appReducers
  apiService.get(`/lottery/peroids/${companyCode}`).then(res => {
    const { data } = res || {};
    if(data){
      dispatch(setAllLatestResult(data.data));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  GetLotteryList,
  GetAllLatestResult
};