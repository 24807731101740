import './styles.scss';

import { connect } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import templates from '../../../constants/templates';
import React, { useEffect, useRef, useState } from 'react';
import { apiService } from '../../../services';

import dayjs from 'dayjs';
import { IShuBanItem } from '../../../types';
import { Select, Skeleton } from 'antd';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


function MobileVerticalNumberTrend() {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { t } = useTranslation();  
  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const [selectedTemplate, setSelectedTemplate] = useState<string>(templateAndGame.template)
  const [trendData, setTrendData] = useState<IShuBanItem[]>();
  const [ball , setSelectedBall] = useState<number>(1);
  const [date, setDate] = useState(dayjs());
  const [type, setType] = useState('zs')

  useEffect(() => {
    if (!ref.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      const temp = document.getElementById('zsContainer') as HTMLDivElement;
      setHeight(temp?.clientHeight ?? 0);
      setWidth(temp?.clientWidth ?? 0)   
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  const getShuBanTrend = async () => {
    const lotteryCode = templateAndGame.code;
    try {
      const res = await apiService.get(`/trend/ShuBanHaoMaZS/${companyCode}/${lotteryCode}/${date.format('YYYY-MM-DD')}/${ball}/60`)      
      setTrendData(res.data.data.list)          
    } catch (error) {
      setTrendData(undefined)
      // console.log(error)
    }
  }

  useEffect(()=>{
    if(templateAndGame?.template && templates[selectedTemplate]){
      setSelectedTemplate(templateAndGame.template);
      if(templates[selectedTemplate].H_V_NumberTrend){
        setSelectedBall((templates[selectedTemplate]?.H_V_NumberTrend as Array<{value:number}>)[0]?.value)
      }
    }
  },[templateAndGame])

  useEffect(()=>{
    setTrendData(undefined)
    if(templateAndGame?.code){
      getShuBanTrend()
    }
  },[ball,date])  

  const draw = (canvas:HTMLCanvasElement,temp:HTMLCollectionOf<HTMLDivElement>) =>{
    const context = canvas.getContext("2d");
    if(context){      
      context?.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
      context.lineWidth = 1;
      context.strokeStyle = "#FFC400";

      for (let index = 0; index < temp.length - 1; index++) {
        const element = temp.item(index);
        const elementNext = temp.item(index + 1);      
        const start = {
          x: (element!).offsetLeft,
          y:(element!).offsetTop
        };
        const moveTo = {
          x: (elementNext!).offsetLeft,
          y:(elementNext!).offsetTop
        }      
        context.moveTo(start.x + 8,start.y + 16);
        context.lineTo(moveTo.x + 8,moveTo.y);
        context.stroke();      
      }
    }
  }

  useEffect(()=>{  
    if(type === 'zs'){
      const temp  = document.getElementsByClassName('ball');
      const canvas = document.getElementById("canvas") as HTMLCanvasElement;
      draw(canvas,temp as HTMLCollectionOf<HTMLDivElement>)
    }
  },[width, height,trendData, type])  

  const onDateChange = (date:Date)=>{
    setTrendData(undefined);
    setDate(dayjs(date))
  }
  
  return <div className='MobileVerticalNumberTrendWrapper'>
    <div className='text-[18px] font-bold'>
      {t('DRAW_RECORD')}
    </div>
    <div className=' flex items-center mt-[14px]'>
      <div className=' flex'>
        {[
          { label: '今天', value: dayjs() },
          { label: '昨天', value: dayjs().subtract(1, 'day') },
          { label: '前天', value: dayjs().subtract(2, 'day')},
        ].map((item) => {
          return (
            <div
              onClick={() => setDate(item.value)}
              className={` buttonWrapper mr-[16px] ${
                (item.value.format('YYYY-MM-DD') === date.format('YYYY-MM-DD') && 'selected') || ''
              }`}
              key={item.label}
            >
              {item.label}
            </div>
          )
        })}
      </div>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={date.toDate()} 
        maxDate={new Date()}
        onChange={(date)=>{
          if(date){
            onDateChange(date)
          }
        }}
      />
    </div>
    <div className='flex items-center mt-[14px]'>
      <div className='font-bold mr-4' >
        {
          t('NUMBER_SELECT')
        }
      </div>
      <Select
        className='w-[90px]'
        value={ball}
        onChange={value=>setSelectedBall(value)}
        options={
          templates[selectedTemplate].H_V_NumberTrend ?? []
        }
      >
      </Select>
    </div>
    <div className=' flex mt-[14px]'>
      <div className={` buttonWrapper mr-[16px] ${type === 'zs' ? 'selected' : ''} `} onClick={()=> setType('zs')}>
        {(templates[selectedTemplate].H_V_NumberTrend!).find(x=>x.value === ball)?.label || (templates[selectedTemplate].H_V_NumberTrend!).find(x=>x.value === ball)?.rank}走势
      </div>
      <div className={` buttonWrapper ${type === 'ds' ? 'selected' : ''}`} onClick={()=> setType('ds')}>
        大小/单双
      </div>
    </div>
    
    <div className='flex w-full' ref={ref}>
      <div className='w-[120px] columnPart'>
        <div className='flex justify-center items-center'>期号</div>
        {
          (trendData ? trendData.reverse() : Array(10).fill('loading')).map((item:IShuBanItem | string, index)=> {
            return <div key={index} className='flex justify-center items-center'>
              {
                item === 'loading' ? <Skeleton.Button active block /> : <div>{`${(item as IShuBanItem).term}` }</div>
              }
            </div>
  
          } )
        }
      </div>
      <div className='flex overflow-x-auto whitespace-nowrap w-full'>
        {
          (templates[selectedTemplate].ShuBanHaoMaTrend!).mobile.header(ball,type).map((Func,index, arr)=> {
            return <div id={arr.length - 1 === index ? "zsContainer" : `zs_${index}`} className=' columnPart flex-auto relative' key={index}>
              <div>{Func()}</div>
              {
                (trendData ? trendData.reverse() : Array(10).fill('loading')).map((item:IShuBanItem | string, subIndex)=> {
                  const RowFunc:(item: IShuBanItem) => JSX.Element = (templates[selectedTemplate].ShuBanHaoMaTrend!).mobile.rowProperty(ball, type)[index]                
                  return <React.Fragment key={subIndex}>
                    {
                      item === 'loading' 
                        ? <Skeleton.Button active block /> 
                        : <div>{RowFunc ? RowFunc(item as IShuBanItem) : ''}</div>
                    }
                  </React.Fragment>
                } )
              }
              {type === 'zs' && arr.length - 1 === index && <canvas id="canvas" width={width} height={height} className='absolute top-0'></canvas>}
            </div>
          } )
        }
      </div>
    </div>
  </div>    
}

export default connect(null, null)(MobileVerticalNumberTrend);