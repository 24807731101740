import './styles.scss';

import { connect } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import templates from '../../../constants/templates';
import { useEffect, useState } from 'react';
import { apiService } from '../../../services';
import { InputNumber } from 'antd';

import type { ScriptableTooltipContext, ScriptableContext } from 'chart.js';
import {ILatestItem, ILotteryList} from '../../../types/index'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { RootState } from '../../../redux/store';
import ExternalTooltipHandler from '../../common/ExternalTooltipHandler';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const mapStateToProps = (state:RootState) => ({
  lotteryList: state.lotteryReducers.lotteryList,
});

const numberRange:{[key:string]:{min:number,max:number}} = {
  "PKS":{
    min:1,
    max:10
  },
  "YDH":{
    min:1,
    max:6
  },
  "SSC":{
    min:0,
    max:9
  },
  "KLSF":{
    min:1,
    max:20
  },
  "SYXW":{
    min:1,
    max:11
  }
}
interface toolTipProp{
  x:string,
  y:number,
  items:Array<ILatestItem>
}

const colors = ['#DBD40B', '#1111F2', '#3C342C', '#F4680C', '#18EDED', '#C181EF', '#758EB7', '#E8296F', '#781F1F', '#0F9393'];
interface IProps{
  lotteryList:ILotteryList,
}

function HotNumber({lotteryList}:IProps) {
  const { t } = useTranslation();  
  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const [selectedTemplate, setSelectedTemplate] = useState<string>(templateAndGame.template)

  const [hotNumberData, setHotNumberData] = useState<{datasets:Array<{data:Array<{x:string,y:number}>}>}>({datasets:[{data:[{x:'',y:0}]}]});

  const [selectedNumber, setSelectedNumber] = useState<number>(1);
  const [installment, setInstallment] = useState(60);
  const [inputValue, setInputValue] = useState(60);
  const [isRndNum, setIsRndNum] = useState(0);

  const CustomTooltip = (obj:toolTipProp) => {  
    const type = templates[`${selectedTemplate}`].trendSubMenu?.find(x=>x.value === selectedNumber)?.label ?? ''
    const arrObj:Array<ILatestItem> = obj.items;   
    return (
      <div className="p-2">
        <div className='flex mb-2 justify-center whitespace-nowrap'>
          {`${type} 共开出：${obj.x}球 ${obj.y}期`}
        </div>
        {
          arrObj.map((obj,index:number)=> 
            <div key={index.toString()} className='my-2'>
              <div>{`${obj.issue}期`}</div>
              <div  className={`flex ${templates[`${selectedTemplate}`].templateWrapper}`}>
                {
                  templates[`${selectedTemplate}`].GenerateNumber(obj).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
                    return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
                  }) 
                }
                {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>+{obj.rnd}</div>}
              </div>
            </div>
          )
        }
      </div>
    );
  };

  const options = {
    maintainAspectRatio:false,
    layout: {
      padding:0
    },
    elements: {
      bar: {
        backgroundColor: (ctx:ScriptableContext<'bar'>) => {     
          return colors[ctx.dataIndex % 10]
        },
      }
    },
    scales:{
      x:{
        ticks:{
          callback: function(value:number | string) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newthis = this as any
            return `${newthis.getLabelForValue(value)}球`;
          },
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: (context:ScriptableTooltipContext<'bar'>)=>ExternalTooltipHandler<'bar', toolTipProp>(context, CustomTooltip),
      },
      legend:{
        display:false
      }
    }
  };

  const getHistory = async () => {
    const range = numberRange[selectedTemplate]
    const lotteryCode = templateAndGame.code;
    const tempData:Array<{x:string,y:number,items:Array<{draw_code:string,issue:string}>}> = Array.from({length:range.max - range.min + 1},(v,k)=>k + range.min).map(item=>({x:String(item),y:0,items:[]}))
    try {
      const res = await apiService.get(`/lottery/history/${companyCode}/${lotteryCode}`)
      const result = res.data;
      const data:Array<{draw_code:string,issue:string}> = result.data.list ? result.data.list.slice(0,installment) : [];
      for(let i = 0;i < data.length;i++){
        const num =  Number(data[i].draw_code.split(',')[selectedNumber - 1]);
        const temp = tempData.find(item=>item.x === String(num));
        if(temp){
          temp.y = temp.y + 1;
          temp.items.push(data[i]);
        }    
      }
      setHotNumberData({datasets:[{ data:tempData }]})      
    } catch (error) {
      setHotNumberData({datasets:[{data:[{x:'',y:0}]}]})
    }
  }

  useEffect(()=>{
    if(templateAndGame?.template && templates[selectedTemplate]){
      setIsRndNum(lotteryList.lotterys.find(x=>x.code === templateAndGame.code)?.isRndNum ?? 0)   
      setSelectedTemplate(templateAndGame.template);
      if(templates[selectedTemplate].hotAndMissingNumber){
        setSelectedNumber((templates[selectedTemplate]?.hotAndMissingNumber as Array<{value:number}>)[0]?.value)
      }
    }
  },[templateAndGame])

  useEffect(()=>{
    if(templateAndGame?.code){
      getHistory()
    }
  },[installment,selectedNumber])
  
  return <div className='hotNumberWrapper'>
    <div className='flex items-center'>
      <div className='text-[24px] font-bold mr-4' >
        {
          t('NUMBER_SELECT')
        }
      </div>
      <div className='flex flex-wrap flex-1'>
        {
          templates[selectedTemplate] && templates[selectedTemplate].hotAndMissingNumber?.map(item=> 
            <div 
              onClick={()=>setSelectedNumber(item.value)}
              className={`buttonWrapper ${selectedNumber == item.value ? 'selected' : ''}`}
              key={item.label}>
              {item.label}
            </div> )
        }
      </div>    
    </div>
    <div className='flex items-center my-5'>
      <div className='font-bold mr-4'>{t('SHOW_INSTALLMENT')}</div>
      <div className='flex items-center'>
        <InputNumber className='mr-4' size="large" min={1} value={inputValue} onChange={value=> setInputValue(value ?? 1)}  controls={false} />
        <div className='buttonWrapper selected cursor-pointer' onClick={()=>setInstallment(inputValue)}>{t('SETTING')}</div>
      </div>
    </div>
    <div className=' w-full relative flex justify-center h-[400px]'>
      {
        hotNumberData.datasets.length > 0 &&
          <Bar options={options} data={ {...hotNumberData}} />
      }
    </div>
  </div>
    
}

export default connect(mapStateToProps, null)(HotNumber);