export default function Template8() {
  return (
    <div className=' section '>
      <div></div>
      <div>
        中国福利彩票3D游戏（以下简称3D），是以一个3位自然数为投注号码的彩票，投注者从000-999的数字中选择一个3位数进行投注。3D在各省（区、市）保留各自奖池、单独派奖的基础上实行三统一，即统一名称标识、统一游戏规则、统一开奖号码。
      </div>
      <div>
        <div>投 注</div>
        3D每注投注金额为人民币2元。投注者可在中国福利彩票投注站进行投注。投注号码经投注机打印为兑奖凭证，交投注者保存，此兑奖凭证即为3D彩票。销售期号以开奖日界定，按日历年度编排。
        投注者投注时可自选号码，也可机选。自选号码投注即将投注者选定的号码输入投注机进行投注；机选号码投注即由投注机随机产生投注号码进行投注。投注者可只购买当期彩票（即”当期投注”），也可购买从当期起连续若干期的彩票（即”多期投注”）。3D每期每个号码的投注注数，由各省根据市场销售情况限量发行。
      </div>
      <div>
        3D的投注方式分为单选投注与组选投注。单选投注是将3位数以惟一的排列方式进行单注投注。组选投注是将投注号码的所有排列方式作为一注投注号码进行的单注投注。如果一注组选号码的3个数字各不相同，则有6种不同的排列方式，因而就有6个中奖机会，这种组选投注方式简称”组选6”；如果一注组选号码的3个数字有两个数字相同，则有3种不同的排列方式，因而就有3个中奖机会，这种组选投注方式简称”组选3”。
      </div>
      <div>
        <div>设 奖</div>
        3D的设奖奖金占彩票销售总额的50%。其中：当期奖金为销售总额的49%；调节基金为销售总额的1%。
      </div>
      <div>
        3D设置奖池，奖池由设奖奖金与实际中出奖金的差额组成。当期中出奖金未达设奖金额时，余额进入奖池；当期中出奖金超出当期奖金时，差额由奖池补充。当奖池总额不足时，由调节基金补足，调节基金不足时，用发行经费垫支。3D在各省（区、市）保留各自奖池。
      </div>
      <div>
        3D采用固定设奖。当期奖金设”单选”、”组选3”、”组选6”三个奖等，各奖等中奖奖金额按固定奖金结构设置，规定如下：
      </div>
      <div>“单选”投注：中奖金额除北京每注980元，其他省市每注1000元；</div>
      <div>
        “组选3”投注：中奖号码中任意两位数字相同（500.com注：开奖号码中的3个数字有两个相同，即为组三），所选号码与中奖号码相同且顺序不限，则该注彩票中奖。例如，中
        奖号码为 544 ，则中奖结果为： 544 、 454 、 445
        之一均可。中奖金额为每注320元（上海每注333元）。
      </div>
      <div>“组选6”投注：中奖金额为每注160元（上海每注166元）。</div>
      <div>
        <div>开 奖</div>
        中国福利彩票发行管理中心负责3D的开奖和摇奖工作，每天在北京开奖一次，各省（区、市）共享统一的号码。
        摇奖在北京市中国福利彩票3D摇奖大厅公开进行，在公正人员监督下，使用中国福利彩票发行管理中心指定的专用摇奖器，摇出中奖号码。
        3D摇奖时，摇出一个3位数的号码，构成3D的中奖号码。
      </div>
      <div>
        <div>中 奖</div>
        根据投注者所持3D 彩票上的投注号码与当期中奖号码相符情况，确定中奖等级。
      </div>
      <div>“单选”奖：投注号码与当期公布的中奖号码的3位数按位数全部相同，即中得单选奖。</div>
      <div>
        “组选3”奖：当期摇出的中奖号码3位数中有任意两位数字相同，且投注号码与中奖号码的数字相同，顺序不限，即中得”组选3”奖。
      </div>
      <div>
        “组选6”奖：当期摇出的中奖号码中3位数各不相同，且投注号码的三个数字与当期中奖号码相同，顺序不限，即中得”组选6”奖。
      </div>
      <div>
        <div>兑 奖</div>
        中奖彩票是兑奖凭证，中奖彩票因玷污、损坏的原因而不能正确识别的，不能兑奖。
      </div>
      <div>
        中奖者持中奖彩票在本省规定的兑奖期限内到本省内任意投注站兑奖，中奖金额较大的中奖者，可持中奖彩票及有效身份证件在兑奖期限内到本省福利彩票发行中心兑奖。
      </div>
    </div>
  )
}
