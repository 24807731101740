import { SET_DATA } from '../../actionTypes';
import { AnyAction } from 'redux'

interface DemoState {
  apiData: number
}

const initialState:DemoState = {
  apiData: 0,
};

const demoReducers = (state = initialState, action:AnyAction) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        apiData: action.value
      };

    default:
      return state;
  }
};

export default demoReducers;