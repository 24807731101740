import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';


import './styles.scss';
import FooterLinks from '../../../components/Web/FooterLinks';


function WebContent (){   
  const { pathname } = useLocation();
  return ( 
    <div className='webContentWrapper'>
      <motion.div
        className='animationLayer'
        key={pathname}
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        exit={{
          opacity: 0
        }}
        transition={{
          duration: 0.5
        }}
      >
        <Outlet />
        <FooterLinks />
      </motion.div>
    </div>
  );
}
export default connect(null, null)(WebContent);
