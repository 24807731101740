export default function Template12() {
  return (
    <div className=' sm:text-[18px] text-[14px] '>
      <div>
        <div className=' text-lg font-bold mb-4'>第一章 总 则</div>
        <div className=' section'>
          <div></div>
          <div>
            第一条
            根据财政部《彩票发行与销售管理暂行规定》和国家体育总局《体育彩票发行与销售管理暂行办法》以及《计算机销售体育彩票管理暂行办法》，制定本游戏规则。
          </div>
          <div>
            第二条
            排列5电脑体育彩票由国家体育总局体育彩票管理中心统一发行，在全国范围内采用计算机网络系统进行联合销售。
          </div>
          <div>第三条 “排列5”实行自愿购买，凡购买该彩票者即被视为同意并遵守本规则。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第二章 游戏方法</div>

        <div className=' section'>
          <div></div>
          <div>
            第四条 购买“排列5”时，由购买者从00000-99999的数字中选取1个5位数为投注号码进行投注。
          </div>
          <div>
            第五条
            购买者可在全国各省（区、市）体育彩票管理中心设置的投注站进行投注。投注号码可由投注机随机产生，也可通过投注单将购买者选定的号码输入投注机确定。投注号码经系统确认后打印出的兑奖凭证即为“排列5”电脑体育彩票，交购买者保存。
          </div>
          <div>第六条 “排列5”每注2元人民币。彩票不记名、不挂失，不返还本金，不流通使用。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第三章 设 奖</div>
        <div className=' section'>
          <div></div>
          <div>第七条 “排列5”设1个奖级，为固定奖。</div>
          <div>第八条 奖金分配：一等奖，单注固定奖金100000元。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第四章 奖金管理</div>
        <div className=' section'>
          <div></div>
          <div>
            第九条
            “排列5”按每期销售总额的50％计提奖金,计提奖金分为当期奖金和调节基金，其中49%为当期奖金，1%为调节基金。
          </div>
          <div>
            第十条
            “排列5”设置奖池，奖池由当期奖金与实际中出奖金的差额累计而成。若当期奖金大于当期中出奖金时，余额滚入奖池；若当期奖金小于当期中出奖金时，差额用奖池补足；若奖池不足时，用调节基金补足，调节基金不足时，从发行经费中垫支。
          </div>
          <div>
            第十一条
            调节基金还包括弃奖收入和逾期未退票的票款。调节基金专项用于支付各种不可预见情况下的奖金支出风险以及设立特别奖。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第五章 中 奖 </div>
        <div className=' section'>
          <div></div>

          <div>
            第十二条 所购彩票与开奖结果对照，符合以下情况即为中奖。
            一等奖，所选号码与中奖号码全部相同且顺序一致。例如：中奖号码为43751，则排列5的中奖结果为：43751。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第六章 开奖及公告</div>
        <div className=' section'>
          <div></div>

          <div>第十三条 “排列5”每天开奖一次，摇奖过程在公证人员监督下进行，通过电视台播出。</div>
          <div>
            第十四条 “排列5”单独摇奖，其中奖号码为全国联网电脑体育彩票排列5当期摇出的全部中奖号码。
          </div>
          <div>
            第十五条
            每期开奖后，国家体育总局体育彩票管理中心需将中奖号码、当期销售总额、各奖等中奖情况以及奖池资金余额等信息，通过新闻媒体向社会公布，并将开奖结果通知各销售终端。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第七章 兑 奖</div>
        <div className=' section'>
          <div></div>
          <div>
            第十六条
            “排列5”兑奖当期有效。每期自开奖次日起60天为兑奖期，逾期未兑，视为弃奖纳入调节基金。
          </div>
          <div>第十七条 兑奖机构有权查验中奖者的中奖彩票及有效身份证件，兑奖者应予配合。</div>
          <div>第十八条 凡伪造、涂改中奖彩票，冒领奖金者，送交司法机关追究法律责任。</div>
        </div>
      </div>

      <div>
        <div className=' text-lg font-bold mb-4'>第八章 附 则</div>
        <div className='section'>
          <div>第十九条 本游戏规则解释权属国家体育总局体育彩票管理中心。</div>
          <div>第二十条 本游戏规则自下发之日起执行。</div>
        </div>
      </div>
    </div>
  )
}
