import { Button, ConfigProvider, DatePicker, InputNumber, Select, Skeleton } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import useZongHeLuZhu, { IShowData } from './useLuZhu'
import './styles.scss'
import { ILuZhuProps } from './type'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EmptyIcon } from '../../../assets/image/data_empty.svg'
import useOnScreen from './useOnScreen'
import { useAppSelector } from '../../../redux/hooks'

function Table({ data, loading }: { data?: IShowData; loading?: boolean }) {
  const { count = [], lzList = [], lzOption, rankOption } = data || {}
  const [cnt, setCnt] = useState(4)
  const [confirmed, setConfirmed] = useState(false)
  const { t } = useTranslation()

  /** 下拉框选项，根据 返回的count 当中解析*/
  const selectOptions = useMemo(
    () =>
      count.map((i) => {
        const label = i.split(':')?.[0]
        return { label, value: label }
      }),
    [count],
  )

  const [selected, setSelected] = useState(selectOptions?.[0]?.label || '') //

  // 统计 结果出现多少次以上的
  const totalCnt =
    (confirmed && lzList.filter((i) => i.filter((lz) => lz == selected).length >= cnt).length) || 0

  const contentScrollRef = useRef<HTMLDivElement>(null)

  // 表格数据变化 滚动到最右边
  useEffect(() => {
    const scrollWidth = contentScrollRef.current?.scrollWidth
    contentScrollRef.current?.scrollTo({ left: scrollWidth })
  }, [lzList])

  return (
    /********
     *
     * ！！！！！注意表格数据是，逆序显示
     *
     *
     */
    <div className=' tableArea'>
      <div className=' header'>
        <div className=' headerWithoutNewest'>
          <div className=' selectArea'>
            {t('CONTINUOUS_OCCUR')}
            <InputNumber min={1} value={cnt} onChange={(v) => setCnt(v || 0)} className=' select' />
            {t('LUZHU_ABOVE')}
            <Select
              options={selectOptions}
              value={selected}
              onChange={(v) => setSelected(v)}
              className=' select'
            />
            <span
              className={`button confirm ${confirmed && 'active'}`}
              onClick={() => setConfirmed(!confirmed)}
            >
              {t('CONFIRM')}
            </span>
            <span>
              {' '}
              {t('OCCURRENCES')} {totalCnt}
            </span>
          </div>
          <div className=' rightHeader'>
            {t('LUZHU_TOTAL')}:
            {count.map((item) => (
              <span key={item}>{item}</span>
            ))}
            <span className=' button active text '> {rankOption?.label || ''} </span>
            <span className=' button active text'> {lzOption?.label || ''} </span>
          </div>
        </div>

        <div className=' newest'>{t('NEWEST')}</div>
      </div>

      {(loading && (
        <div className=' grid grid-cols-4 '>
          {new Array(16).fill(0).map((i, index) => {
            return (
              <Skeleton.Button
                key={index}
                // 表格最右边几个不要border
                className={` 
                border-r-[1px] border-b-[1px] border-solid border-[#c9c9c9] p-[10px]
                ${(index + 1) % 4 == 0 && 'border-r-0'} 
                `}
                block
                active
              />
            )
          })}
        </div>
      )) || <></>}

      <div className=' content' ref={contentScrollRef}>
        {lzList.map((item, index) => {
          const ifHighLight = confirmed && item.every((i) => i == selected) && item.length >= cnt
          return (
            <div className={` col ${ifHighLight && 'highLight'}`} key={index}>
              {item.map((r, rIndex) => {
                return (
                  <div className={` text ${index % 2 && 'active'}`} key={rIndex}>
                    {r}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function LuZhu({ title, lotteryCode, rankingOptions, luZhuOptions }: ILuZhuProps) {
  const { date, setDate, showData, luzhu, setLuZhu, rankings, setRankings, loading } =
    useZongHeLuZhu({
      rankingOptions,
      luZhuOptions,
      lotteryCode,
    })
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const showLoadingMore = useOnScreen<HTMLDivElement>(ref)

  const [endIndex, setEndIndex] = useState(3)

  useEffect(() => {
    setEndIndex(3)
  }, [showData.length]) // 只要hook的数据出来 有变化，render数量，永远只是在3个

  useEffect(() => {
    if (showLoadingMore) {
      setEndIndex(endIndex + 3)
    }
  }, [showLoadingMore])

  const showDataSplit = showData.slice(0, endIndex)
  const hideLoadingMore = showDataSplit.length >= showData.length

  return (
    <div className=' LuZhuWebWrapper boxShadow'>
      <div className=' titleArea'>
        <div className=' title'>{title}</div>
        <div className=' dateFilterArea'>
          <div className=' dateQucikChoose'>
            {[
              { label: t('TODAY'), value: dayjs().format('YYYY-MM-DD') },
              { label: t('YESTERDAY'), value: dayjs().subtract(1, 'day').format('YYYY-MM-DD') },
              {
                label: t('THE_DAY_BEFORE_YESTERDAY'),
                value: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
              },
            ].map((item) => {
              return (
                <Button
                  onClick={() => setDate(item.value)}
                  className={` button date ${
                    (item.value === date.format('YYYY-MM-DD') && 'active') || ''
                  }`}
                  key={item.label}
                >
                  {item.label}
                </Button>
              )
            })}
          </div>
          <DatePicker
            placeholder=''
            value={date}
            format='YYYY-MM-DD'
            showToday={false}
            onChange={(date, dateString) => setDate(dateString)}
          />
        </div>
      </div>
      {/* 如果只有一个选项，删除这个显示区域。适用于，总和/冠亚和路珠 */}
      {(rankings?.length > 1 && (
        <div className=' filterArea'>
          <div className=' filterTitle'>{t('FILTER_RANKING')}</div>
          <div className=' filterItemsWithCheckAll'>
            <div className=' filterItems'>
              {rankings.map((i, index) => {
                return (
                  <div
                    onClick={() =>
                      setRankings([
                        ...rankings.slice(0, index),
                        { ...i, checked: !i.checked },
                        ...rankings.slice(index + 1),
                      ])
                    }
                    className={` button ${i.checked && 'active'}`}
                    key={i.value}
                  >
                    {i.label}
                  </div>
                )
              })}
            </div>
            {(rankings?.length > 1 && (
              <div className=' filterItems'>
                <div
                  className='button text '
                  onClick={() => setRankings(rankings.map((i) => ({ ...i, checked: true })))}
                >
                  {t('CHECK_ALL')}
                </div>
                <div
                  className='button text '
                  onClick={() => setRankings(rankings.map((i) => ({ ...i, checked: false })))}
                >
                  {t('CLEAR')}
                </div>
              </div>
            )) || <></>}
          </div>
        </div>
      )) || <></>}

      {/* 如果只有一个选项，删除这个显示区域。适用于，大小/单双/龙虎路珠 */}
      {(luzhu.length > 1 && (
        <div className=' filterArea'>
          <div className=' filterTitle'>{t('SELECT_LUZHU')}</div>
          <div className=' filterItems'>
            {luzhu.map((i, index) => {
              return (
                <div
                  onClick={() =>
                    setLuZhu([
                      ...luzhu.slice(0, index),
                      { ...i, checked: !i.checked },
                      ...luzhu.slice(index + 1),
                    ])
                  }
                  className={` button ${i.checked && 'active'}`}
                  key={i.value}
                >
                  {i.label}
                </div>
              )
            })}
          </div>
        </div>
      )) || <></>}

      {(loading && <Table loading />) || <></>}

      {(!loading && !showData.length && (
        <div className=' p-4 flex items-center justify-center'>
          <EmptyIcon className=' w-[200px]' />
        </div>
      )) || <></>}

      {(!loading && (
        <div>
          {showDataSplit.map((item) => {
            return <Table key={item.label} data={item} />
          })}
        </div>
      )) || <></>}

      {/* 加载更多 */}
      <div ref={ref} className={` flex justify-center p-[10px] ${hideLoadingMore && ' hidden'}`}>
        Loading...
      </div>
    </div>
  )
}

export * from './utils'
export * from './type'
export * from './useLuZhu'
export * from './useOnScreen'

function LuZhuWithConfigProvider(props: ILuZhuProps) {
  const { theme = '' } = useAppSelector((state) => state.appReducers)

  const themeMap = {
    red: { colorPrimary: '#E86262' },
    blue: { colorPrimary: '#5D66D3' },
    green: { colorPrimary: '#4A9F5E' },
    orange: { colorPrimary: '#E38533' },
  }

  return (
    <ConfigProvider
      componentSize='large'
      theme={{
        token: {
          colorPrimary: themeMap[theme as keyof typeof themeMap].colorPrimary || '#E86262',
        },
      }}
    >
      <LuZhu {...props} />
    </ConfigProvider>
  )
}
export default LuZhuWithConfigProvider
