import { connect } from 'react-redux';
import WebContent from '../WebContent';
import WebHeader from '../WebHeader';

import './styles.scss';


function WebLayout (){   
  return ( 
    <div className='webLayoutWrapper h-full flex flex-col'>
      <WebHeader />
      <WebContent />
      {/* <div className="sideLanguage">
        <div className="menu">
          <div className="hoverMenu">
            <div className="mb-1" onClick={() => { i18n.changeLanguage('zh'); }}>
              <span>中文</span>
            </div>
            <div onClick={() => { i18n.changeLanguage('en'); }}>
              <span>ENG</span>
            </div>
          </div>
        </div>
        <div className="title">{i18n.language === 'zh' ? '中文' : 'ENG'}</div>
      </div> */}
    </div>
  );
}
export default connect(null, null)(WebLayout);
