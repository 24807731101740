import { SET_KILL_PLAN_LOTTERY_LIST, SET_LOTTERY_LIST, SET_ALL_LATEST_RESULT } from '../../actionTypes';
import { AnyAction } from 'redux'
import {ILatestItem, ILotteryList, ILotteryItem} from '../../../types/index';


const initialState:{lotteryList: ILotteryList,allLatestResult:ILatestItem[], killPlanLotterys:ILotteryItem[]} = {
  lotteryList: {
    categorys:[],
    lotterys:[],
  },
  allLatestResult:[],
  killPlanLotterys:[],
};

const lotteryReducers = (state = initialState, action:AnyAction) => {
  switch (action.type) {
    case SET_LOTTERY_LIST:
      return {
        ...state,
        lotteryList: action.value
      };
    case SET_KILL_PLAN_LOTTERY_LIST:
      return {
        ...state,
        killPlanLotterys: action.value
      };
    case SET_ALL_LATEST_RESULT:
      return {
        ...state,
        allLatestResult: action.value
      };

    default:
      return state;
  }
};

export default lotteryReducers;