import { AnimatePresence, motion } from 'framer-motion';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ILotteryList, ILotteryItem } from '../../../types';
import './styles.scss';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import appActions from '../../../redux/actions/appActions';
import { CaretLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';


const mapStateToProps = (state:RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
});
interface ICategoryItem {
  label?:string,
  value?:number
}

interface IProps{
  lotteryListData:ILotteryList, 
  mobileLotteryMenuIsVisible:boolean,
  setMobileLotteryMenuIsVisible:(value:boolean)=>void
}

function MobileSideMenu ({lotteryListData, mobileLotteryMenuIsVisible, setMobileLotteryMenuIsVisible}:IProps){
  const {t} = useTranslation()
  const navigate = useNavigate();
  const location = useLocation();  

  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const dispatch = useAppDispatch();

  const [lotteryData, setLotteryData] = useState<{[key:string]:ILotteryItem[]}>({});
  const [category, setCategory] = useState(new Map());

  useEffect(()=>{
    if(lotteryListData?.lotterys){
      const temp = (lotteryListData?.lotterys as ILotteryItem[]).filter(x=>x.template !== 'HK6').reduce((a:{[key:string]:ILotteryItem[]},b:ILotteryItem)=>{
        const categoryArr = b.category.split(',');
        for (let index = 0; index < categoryArr.length; index++) {
          const categoryCode = categoryArr[index];
          if(a[categoryCode]){
            a = {...a,[categoryCode]:a[categoryCode].concat(b)}
          }
          else{
            a =  {...a,[categoryCode]:[b].concat([])}
          }  
        }
        return a;
      },{})
      setLotteryData(temp);
      const tempMap = new Map(lotteryListData.categorys.map((obj:ICategoryItem) => [ obj.value,obj.label]));
      setCategory(tempMap)
    }
  },[lotteryListData])

  const onClickToNavigate = (x:ILotteryItem)=>{
    dispatch(appActions.setTemplateAndCode({template:x.template,code:x.code}));
    setMobileLotteryMenuIsVisible(false)
    return navigate('/lottery');
  }  
  
  return <AnimatePresence>
    {mobileLotteryMenuIsVisible && (
      <motion.div
        className='mobileSideMenuWrapper'
        initial={{ x: '100vw'}}
        animate={{ x: 0 }}
        exit={{x:'100vw'}}
        transition={{ duration: 0.5 }}
      >
        <div className='menuHeader flex items-center'>
          <div className=' w-1/4 flex items-center'>
            <CaretLeftOutlined onClick={()=> setMobileLotteryMenuIsVisible(false)} />
          </div>
          <div className=' w-1/2 flex items-center justify-center'>
            彩票大厅
          </div>
          <div className=' w-1/4 flex items-center justify-end text-[14px]' onClick={()=> {
            setMobileLotteryMenuIsVisible(false)
            navigate('/help')
          }}>
            <QuestionCircleOutlined />
            <div className='ml-1'>{t('HELP_CENTER')}</div>
          </div>
        </div>
        <div className='lotteryContent'>
          {
            Object.entries(lotteryData).map(
              ([key,value])=> 
                <div key={`${key}`} className=' mt-[10px]'>
                  <div className='categoryName'>{category.get(Number(key))}</div>
                  <div className='flex flex-wrap'>
                    {
                      value.map(x=>  <div onClick={()=>onClickToNavigate(x)} className={` mt-2 buttonWrapper ${templateAndGame.code === x.code && location?.pathname === '/lottery' ? 'selected' : ''}`} key={`${x.name}`}>{`${x.name}`}</div> )
                    }
                  </div>
                </div> 
            )
          }
        </div>
      </motion.div>
    )}
  </AnimatePresence>

}  


export default connect(mapStateToProps, null)(MobileSideMenu);
