import { apiService } from '../../../services';
import { SET_DATA } from '../../actionTypes';
import { useAppSelector } from '../../hooks';
import { AppDispatch } from '../../store';
const setData = (value:number) => ({
  type: SET_DATA,
  value
});

const GetList = () => (dispatch:AppDispatch) => {
  const { companyCode } = useAppSelector((state) => state.appReducers)
  apiService.get(`/lottery/list/${companyCode}`).then(res => {
    const { data } = res || {};
    dispatch(setData(data.data));
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  GetList,
};