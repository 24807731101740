import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import './styles.scss';
import { useEffect, useRef, useState } from 'react';

import { AppDispatch } from '../../../redux/store';
import appActions from '../../../redux/actions/appActions';

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  setBannerShow: (value:boolean) => dispatch(appActions.setBannerShow(value)),
});

function MobileContent (props: { setBannerShow: (value: boolean) => void }){   
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(pathname === '/');
  const scrollContainerRef = useRef<HTMLDivElement | null>(null); 

  useEffect(() => {
    const handleScroll = () => {
      if (pathname !== '/') return;
      const currentScrollPos = scrollContainerRef.current?.scrollTop; 
      const visible = currentScrollPos !== undefined && currentScrollPos < 50;
      setIsVisible(visible);
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    props.setBannerShow(isVisible);
  }, [isVisible]);
  
  return ( 
    <div className='mobileContentWrapper flex-1' ref={scrollContainerRef}>
      <motion.div
        key={pathname}
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        exit={{
          opacity: 0
        }}
        transition={{
          duration: 0.5
        }}
      >
        <Outlet />
      </motion.div>
    </div>
  );
}
export default connect(null, mapDispatchToProps)(MobileContent);