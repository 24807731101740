import {ILatestItem} from '../../../types/index';


const GenerateNumber = (obj:ILatestItem)=>{  
  const _ball = obj.draw_code.split('|')[0].split(',');
  const _result = obj.draw_code.split('|')[1].split(',');
  return _result.map((item)=> ({value:item, className:`BINGO_FT _${item}`})
  ).concat(_ball.map((item)=> ({value:item, className:'BINGO_FT textBox'}) ))
}

export const GenerateChannelResult = (obj:ILatestItem,index:string)=>{
  const _ball = obj.draw_code.split('|')[0].split(',');
  const _result = obj.draw_code.split('|')[1].split(',');
  const _dx = (obj.trend?.dx as string[]);
  const _ds = (obj.trend?.ds as string[]);  
  const _index = Number(index);
  const styleFlag = _dx[_index].includes('大') || _dx[_index].includes('单') || _ds[_index].includes('大') || _ds[_index].includes('单')
  return [
    <div className='h-full flex-1 flex justify-center items-center whitespace-nowrap border-r border-[#C9C9C9] last:border-r-0 ' key={'ball' + index + _ball[_index]}>{_ball[_index]}</div>, 
    <div  className='h-full flex-1 flex justify-center items-center whitespace-nowrap border-r border-[#C9C9C9] last:border-r-0 ' key={'result' + index + _result[_index]}>
      <div className={`BINGO_FT _${_result[_index]}`}>
        {_result[_index]}
      </div>
    </div>, 
    <div className={`${styleFlag ? 'text-red-400' : ''} h-full flex-1 flex justify-center items-center whitespace-nowrap border-r border-[#C9C9C9] last:border-r-0 `} key={'dx' + index + _dx[_index]}>{_dx[_index]}</div>, 
    <div className={`${styleFlag ? 'text-red-400' : ''} h-full flex-1 flex justify-center items-center whitespace-nowrap border-r border-[#C9C9C9] last:border-r-0 `} key={'ds' + index + _ds[_index]}>{_ds[_index]}</div> ]
}


export const GenerateMobileChannelResult = (obj:ILatestItem,index:string)=>{
  if(index === '0'){
    const _result  = GenerateNumber(obj);
    return <div className='BINGO_FTWrapper flex flex-wrap'>
      {
        _result.map((item, index)=> <div key={index} className={item.className}>{item.value}</div> )
      }
    </div> 
  }

  const _data = index === '2' ? (obj.trend?.dx as string[]) : (obj.trend?.ds as string[]);
  const arr = Array.from(Array(5),(x,index)=>index);
  return arr.map((item,index)=>{
    const _temp = _data[index];
    const styleFlag = _temp.includes('大') || _temp.includes('单') || _temp.includes('大') || _temp.includes('单');
    return <div key={item + index} className={`${styleFlag ? 'text-red-400' : ''} py-[13.5px]  h-full flex-1 flex justify-center items-center`}>{_temp}</div>
  } )
}


const BINGO_FT = {
  GenerateNumber,
  templateWrapper:'BINGO_FTWrapper',
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
    {label:'RULES',menuCode:'RULES'},
  ],
  ChannelDrawResult:{
    web:{
      header:[[{label:'第一球',value:'0'}],[{label:'第二球',value:'1'}],[{label:'第三球',value:'2'}],[{label:'第四球',value:'3'}],[{label:'第五球',value:'4'}]],
      rowProperty:[
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,'0')}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,'1')}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,'2')}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,'3')}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,'4')}</div>
        },
      ]
    },
    mobile:{
      header:[{label:'号码',value:'0'},{label:'单双',value:'1'},{label:'大小',value:'2'}],
      rowProperty:
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index)}</div>
        }    
    }
  },
}

export default BINGO_FT;