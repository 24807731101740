import { connect } from 'react-redux';

import AppIcon from '../../../assets/download/appIcon.png'

import {QRCodeSVG} from 'qrcode.react';

import './styles.scss';

function Download (){
  return ( 
    <div className='webDownloadWrapper flex flex-col justify-center items-center'>
      <div className=' text-[#111] font-bold text-[22px]'>请使用手机扫描二维码下载</div>
      <div className='mt-[25px]'>
        <img src={AppIcon} alt="" srcSet="" />
      </div>
      <div className='text-[#111] font-bold text-[16px] my-[25px]'>166开奖网</div>
      <div>
        <QRCodeSVG 
          value={window.location.href} 
          bgColor='#ea7245'
          fgColor='#ffffff'
        />
      </div>
    </div>
  );
}


export default connect(null, null)(Download);
