import { Table, TableColumnsType } from 'antd'

export default function Template10() {
  const onCell = (_: unknown, rowIndex?: number) => {
    if (rowIndex == 3) {
      return { rowSpan: 2 }
    }
    if (rowIndex == 4) {
      return { rowSpan: 0 }
    }
    if (rowIndex == 5) {
      return { rowSpan: 2 }
    }
    if (rowIndex == 6) {
      return { rowSpan: 0 }
    }
    if (rowIndex == 7) {
      return { rowSpan: 3 }
    }
    if (rowIndex == 8) {
      return { rowSpan: 0 }
    }
    if (rowIndex == 9) {
      return { rowSpan: 0 }
    }
    return {}
  }
  const columns: TableColumnsType<object> = [
    {
      title: '奖级',
      dataIndex: 'level',
      align: 'center',
      onCell,
      className: 'bgColor colBgColor',
    },
    {
      title: '中奖条件',
      dataIndex: 'condition',
      align: 'center',
      children: [
        { title: '红色球号码', align: 'center', dataIndex: 'condition_red', className: 'bgColor' },
        { title: '蓝色球号码', align: 'center', dataIndex: 'condition_blue', className: 'bgColor' },
      ],
      className: 'bgColor',
    },
    {
      title: '奖金分配',
      dataIndex: 'distribution',
      align: 'center',
      width: 400,
      onCell,
      className: 'bgColor',
    },
    {
      title: '说明',
      dataIndex: 'intro',
      align: 'center',
      onCell,
      className: 'bgColor',
    },
  ]
  function Point() {
    return <div className=' flex justify-center w-2 h-2 bg-[#3F3F3F] rounded-full'></div>
  }
  const data = [
    {
      level: '一等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution:
        '当奖池资金低于1亿元时，奖金总额为当期高等奖奖金的70%与奖池中累积的奖金之和，单注奖金按注均分，单注最高限额封顶500万元。 当奖池资金高于1亿元（含）时，奖金总额包括两部分，一部分为当期高等奖奖金的50%与奖池中累积的奖金之和，单注奖金按注均分，单注最高限额封顶500万元；另一部分为当期高等奖奖金的20%，单注奖金按注均分，单注最高限额封顶500万元。',
      intro: '选6+1中6+1',
    },
    {
      level: '二等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '当期高等奖奖金的30%。',
      intro: '选6+1中6+0',
    },
    {
      level: '三等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2, 3, 4, 5].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为3000元',
      intro: '选6+1中5+1',
    },
    {
      level: '四等奖',
      rowSpan: 2,
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2, 3, 4, 5].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为200元',
      intro: '选6+1中5+0或中4+1',
    },
    {
      level: '四等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2, 3, 4].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为200元',
      intro: '选6+1中5+0或中4+1',
    },
    {
      level: '五等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2, 3, 4].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为10元',
      intro: '选6+1中4+0或中3+1',
    },
    {
      level: '五等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2, 3].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为10元',
      intro: '选6+1中4+0或中3+1',
    },
    {
      level: '六等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1, 2].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为5元',
      intro: '选6+1中2+1或中1+ 1或中0+1',
    },
    {
      level: '六等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {[1].map((i) => (
            <Point key={i} />
          ))}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为5元',
      intro: '选6+1中2+1或中1+ 1或中0+1',
    },
    {
      level: '六等奖',
      condition_red: (
        <div className=' flex justify-center gap-1 '>
          {/* {[1, 2, 3].map((i) => (
            <Point key={i} />
          ))} */}
        </div>
      ),
      condition_blue: (
        <div className=' flex justify-center'>
          <Point />
        </div>
      ),
      distribution: '单注奖金额固定为5元',
      intro: '选6+1中2+1或中1+ 1或中0+1',
    },
  ]

  return (
    <div className=' sm:text-[18px] text-[14px] '>
      <div>
        <div className=' text-lg font-bold mb-4'>第一章 总 则</div>
        <div className=' section'>
          <div></div>
          <div>
            第一条
            本规则依据财政部《彩票发行与销售管理暂行规定》和《中国福利彩票（电脑型）联合发行与销售管理暂行办法》（以下简称《管理办法》）制定。
          </div>
          <div>
            第二条
            中国福利彩票“双色球”（以下简称“双色球”）是一种联合发行的“乐透型”福利彩票。采用计算机网络系统发行销售，定期电视开奖。
          </div>
          <div>
            第三条“双色球”由中国福利彩票发行管理中心（以下简称中福彩中心）统一组织发行，在全国销售。
          </div>
          <div>
            第四条
            参与“双色球”销售的省级行政区域福利彩票发行中心（以下称省中心）在中福彩中心的直接领导下，负责对本地区的“双色球”销售活动实施具体的组织和管理。
          </div>
          <div>第五条“双色球”彩票实行自愿购买，凡购买者均被视为同意并遵守本规则。 </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第二章 游 戏</div>

        <div className=' section'>
          <div></div>
          <div>第六条“双色球”彩票投注区分为红色球号码区和蓝色球号码区。</div>
          <div>
            第七条“双色球”每注投注号码由6个红色球号码和1个蓝色球号码组成。红色球号码从1–33中选择；蓝色球号码从1–16中选择。
          </div>
          <div>第八条“双色球”每注2元。</div>
          <div>第九条“双色球”采取全国统一奖池计奖。</div>
          <div>第十条“双色球”每周销售三期，期号以开奖日界定，按日历年度编排。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第三章 投 注</div>
        <div className=' section'>
          <div></div>
          <div>
            第十一条“双色球”的投注方法可分为自选号码投注和机选号码投注;其投注方式有单式投注和复式投注。
          </div>
          <div>第十二条 自选号码投注是指由投注者自行选定投注号码的投注。</div>
          <div>第十三条 机选号码投注是指由投注机为投注者随机产生投注号码的投注。</div>
          <div>
            第十四条
            单式投注是从红色球号码中选择6个号码，从蓝色球号码中选择1个号码，组合为一注投注号码的投注。
          </div>
          <div>第十五条 复式投注有三种：</div>
          <div>
            （一）红色球号码复式：从红色球号码中选择7–20个号码，从蓝色球号码中选择1个号码，组合成多注投注号码的投注。
          </div>
          <div>
            （二）蓝色球号码复式：从红色球号码中选择6个号码，从蓝色球号码中选择2–16个号码，组合成多注投注号码的投注。
          </div>
          <div>
            （三）全复式：从红色球号码中选择7–20个号码，从蓝色球号码中选择2–16个号码，组合成多注投注号码的投注。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第四章 设 奖</div>
        <div className=' section'>
          <div></div>
          <div>
            第十六条“双色球”设奖奖金为销售总额的50%，其中当期奖金为销售总额的49%，调节基金为销售总额的1%。
          </div>
          <div>
            第十七条“双色球”奖级设置分为高等奖和低等奖。一等奖和二等奖为高等奖，三至六等奖为低等奖。高等奖采用浮动设奖，低等奖采用固定设奖。当期奖金减去当期低等奖奖金为当期高等奖奖金。设奖如下：
          </div>

          <div>
            <Table
              scroll={{ x: true }}
              bordered
              tableLayout='fixed'
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>

          <div>第十八条 根据国家有关规定，“双色球”彩票单注奖金封顶的最高限额为1000万元。</div>
          <div>
            第十九条“双色球”设立奖池。奖池资金来源：未中出的高等奖奖金和超出单注封顶限额部分的奖金。奖池资金计入下期一等奖。
          </div>
          <div>
            第二十条
            当一等奖的单注奖金额低于二等奖的单注奖金额时，将一、二等奖的奖金相加，由一、二等奖中奖者平分；当二等奖的单注奖金额低于三等奖的单注奖金额的两倍时，补足为三等奖的单注奖金额的两倍。当期奖金不足部分由调节基金补充，调节基金不足时，用发行费垫支。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第五章 开 奖</div>
        <div className=' section'>
          <div></div>
          <div>
            第二十一条“双色球”由中福彩中心统一开奖，每周开奖三次。开奖前，省中心将当期投注的全部数据刻入不可改写的光盘，作为查验的依据。
          </div>
          <div>
            第二十二条“双色球”通过摇奖器确定中奖号码。摇奖时先摇出6个红色球号码，再摇出1个蓝色球号码，摇出的红色球号码按从小到大的顺序和蓝色球号码一起公布。
          </div>
          <div>第二十三条 开奖公告在各地主要媒体公布，并在各投注站张贴。</div>
          <div>第二十四条“双色球”的开奖结果以中国福利彩票发行管理中心公布的开奖公告为准。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第六章 中 奖</div>
        <div className=' section'>
          <div></div>
          <div>
            第二十五条“双色球”彩票以投注者所选单注投注号码（复式投注按所覆盖的单注计）与当期开出中奖号码相符的球色和个数确定中奖等级：
          </div>
          <div>一等奖：7个号码相符（6个红色球号码和1个蓝色球号码）（红色球号码顺序不限，下同）</div>
          <div>二等奖：6个红色球号码相符； 三等奖：5个红色球号码和1个蓝色球号码相符；</div>
          <div>四等奖：5个红色球号码或4个红色球号码和1个蓝色球号码相符；</div>
          <div>五等奖：4个红色球号码或3个红色球号码和1个蓝色球号码相符；</div>
          <div>六等奖：1个蓝色球号码相符（有无红色球号码相符均可）。 第二十六条</div>
          <div>
            一等奖和二等奖中奖者按各奖级的中奖注数均分该奖级的奖金；三至六等奖按各奖级的单注固定奖额获得奖金。
          </div>
          <div>
            第二十七条 当期每注投注号码只有一次中奖机会，不能兼中兼得（另行设奖按设奖规定执行）。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第七章 兑 奖</div>
        <div className=' section'>
          <div></div>
          <div>
            第二十八条“双色球”彩票兑奖当期有效。每期开奖次日起，兑奖期限为60天，逾期未兑奖者视为弃奖，弃奖奖金进入调节基金。
          </div>
          <div>
            第二十九条 中奖人须提交完整的兑奖彩票，因玷污、损坏等原因造成不能正确识别的，不能兑奖。
          </div>
          <div>
            第三十条
            一等奖中奖者，需持中奖彩票和本人有效身份证明，在兑奖期限内到各地省中心验证、登记和兑奖。其他奖级的兑奖办法由省中心规定并公布。
          </div>
          <div>第三十一条 按国家有关规定，单注奖金额超过一万元者，须缴纳个人偶然所得税。</div>
        </div>
      </div>

      <div>
        <div className=' text-lg font-bold mb-4'>第八章 附 则</div>
        <div>第三十二条 本规则未尽事宜，均按《管理办法》和有关规定执行。</div>
        <div>第三十三条 本规则由中国福利彩票发行管理中心负责解释。</div>
        <div>第三十四条 本规则由自发布之日起施行。</div>
        <div>为了更好的发挥双色球游戏调节基金的作用，决定取消“快乐星期天”特别派奖的活动。</div>
      </div>
    </div>
  )
}
