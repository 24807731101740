import { PaginationProps, DrawerProps, Divider, Empty, ConfigProvider } from 'antd'

import './styles.scss'
import useChanglong from '../../Web/ChangLong/hooks/useChanglong'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Checkbox, Select, Spin, Pagination, Drawer } from 'antd'
import { options } from '../../Web/ChangLong'
import { Fragment, useState } from 'react'
import { CaretLeftOutlined } from '@ant-design/icons'

import enUS from 'antd/locale/en_US'
import zhCN from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import { useAppSelector } from '../../../redux/hooks'

function MobileChangLong() {
  const {
    instalment,
    setInstalment,
    status,
    setStatus,
    audioChecked,
    setAudioChecked,
    tableShowData,
    categorys,
    setCategorys,
    checkedLotteryCodeSet,
    loading,
  } = useChanglong()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    pageSize: 10,
    nextIcon: null, // 去除左右箭头
    prevIcon: null,
  })

  // 移动端还需要 分页，继续处理
  /** 移动端 显示的数据 */
  const mobileTableShowData = tableShowData.slice(
    ((pagination?.current || 1) - 1) * 10,
    (pagination?.current || 1) * 10,
  )

  const [drawer, setDrawer] = useState<DrawerProps>({ open: false })

  return (
    <div className='MobileChangLongWrapper'>
      <div className=' content'>
        <div className=' filterArea'>
          <div className=' statusButtonArea'>
            <div
              onClick={() => setStatus('open')}
              className={`statusButton ${status == 'open' && 'active'}`}
            >
              {t('CONTINUOUS_OPEN')}
            </div>
            <div
              onClick={() => setStatus('close')}
              className={`statusButton ${status == 'close' && 'active'}`}
            >
              {t('CONTINUOUS_UNOPENED')}
            </div>
          </div>

          <div
            className=' button filterButton'
            onClick={() => setDrawer({ ...drawer, open: true })}
          >
            {t('FILTER')}
            {(checkedLotteryCodeSet.size && <span>({checkedLotteryCodeSet.size})</span>) || <></>}
          </div>
        </div>

        <div className=' tableArea'>
          <div className=' selectArea'>
            <div>
              {t('ONLY_SHOW')}
              <Select
                value={instalment}
                onChange={(v) => setInstalment(v)}
                className='select'
                options={options}
              />
              {t('INSTALMENT_ABOVE')}
            </div>
            <div className=' audioCheck'>
              <Checkbox checked={audioChecked} onChange={(e) => setAudioChecked(e.target.checked)}>
                {t('SOUND_ALARM')}
              </Checkbox>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>{t('LOTTERY')}</th>
                <th>{t('LOCATION')}</th>
                <th>{t('CONTINUOUS')}</th>
              </tr>
            </thead>

            {(!loading && (
              <tbody>
                {(mobileTableShowData || []).map((item, index) => {
                  return (
                    <tr key={item.lotteryName + index}>
                      <td>{item.lotteryName}</td>
                      <td>
                        <div>{item.location}</div>
                        <div
                          className='button ghost small luzhuChakan'
                          onClick={() => {
                            // 还需要设置submenu
                            navigate('/lottery')
                          }}
                        >
                          {t('CHECK_LUZHU')}
                        </div>
                      </td>
                      <td>{item.count}期</td>
                    </tr>
                  )
                })}
              </tbody>
            )) || <></>}
          </table>

          {(!loading && !mobileTableShowData.length && <Empty />) || <></>}

          {(!loading && tableShowData.length && (
            <div className=' paginationArea'>
              <Pagination
                {...pagination}
                total={tableShowData.length}
                onChange={(current, pageSize) => {
                  setPagination({ ...pagination, current, pageSize })
                }}
              />
            </div>
          )) || <></>}

          {(loading && (
            <div className=' loadingArea'>
              <Spin className=' spinClassName' />
            </div>
          )) || <></>}
        </div>
      </div>

      {/*
       这里可以继续优化，
       Drawer 抽取组件，categorys 放在组件内部控制，
       点击确定之后，再把最新的categorys传递给当前组件,
       现在点击 确定 等同于 点击关闭
       */}
      <Drawer
        {...drawer}
        width='100vw'
        title={
          <div className=' drawerHeader'>
            <CaretLeftOutlined
              onClick={() => setDrawer({ ...drawer, open: false })}
              style={{ fontSize: '24px' }}
            />
            <div className=' drawerHeaderTitle'>彩种选择</div>
            <div onClick={() => setDrawer({ ...drawer, open: false })}>{t('CONFIRM')}</div>
          </div>
        }
        closable={false} // 去掉 closeicon dom 节点
        headerStyle={{ padding: 0 }}
        getContainer={false} // dom挂载在当前位置，不然样式不生效，之后考虑抽取组件
        className='MobileChangLongDrawerWrapper'
        onClose={() => setDrawer({ ...drawer, open: false })}
      >
        <div className=' checkArea'>
          {categorys.map((item, index) => {
            return (
              <Fragment key={item.value}>
                <div className=' checkLotteryArea'>
                  <div className=' checkTitleArea'>
                    <div className='checkTitle'>{item.label}</div>
                    <Checkbox
                      checked={item.checked}
                      onChange={() => {
                        // 也要控制子菜单
                        const newCategorys: typeof categorys = [
                          ...categorys.slice(0, index),
                          {
                            ...item,
                            checked: !item.checked,
                            lotterys: (item.lotterys || []).map((i) => ({
                              ...i,
                              checked: !item.checked,
                            })),
                          },
                          ...categorys.slice(index + 1),
                        ]
                        setCategorys(newCategorys)
                      }}
                    >
                      {t('CHECK_ALL')}
                    </Checkbox>
                  </div>
                  <div className=' checkItemArea'>
                    {(item.lotterys || []).map((lottery, lotteryIndex) => {
                      return (
                        <div
                          className={`checkItem ${lottery.checked && 'checked'}`}
                          key={lottery.code}
                          onClick={() => {
                            // 也要影响主菜单是否该选中
                            const newLotterys = [
                              ...(item.lotterys || []).slice(0, lotteryIndex),
                              { ...lottery, checked: !lottery.checked },
                              ...(item.lotterys || []).slice(lotteryIndex + 1),
                            ]
                            const newCategorys: typeof categorys = [
                              ...categorys.slice(0, index),
                              {
                                ...item,
                                checked: newLotterys.every((i) => i.checked),
                                lotterys: newLotterys,
                              },
                              ...categorys.slice(index + 1),
                            ]
                            setCategorys(newCategorys)
                          }}
                        >
                          {lottery.name}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <Divider />
              </Fragment>
            )
          })}
        </div>
      </Drawer>
    </div>
  )
}

function MobileChangLongWithConfigProvider() {
  const { theme = '' } = useAppSelector((state) => state.appReducers)
  const { i18n } = useTranslation()

  const themeMap = {
    red: { colorPrimary: '#E86262' },
    blue: { colorPrimary: '#5D66D3' },
    green: { colorPrimary: '#4A9F5E' },
    orange: { colorPrimary: '#E38533' },
  }

  return (
    <ConfigProvider
      locale={i18n.language === 'zh' ? zhCN : enUS}
      componentSize='large'
      theme={{
        token: {
          colorPrimary: themeMap[theme as keyof typeof themeMap].colorPrimary || '#E86262',
        },
      }}
    >
      <MobileChangLong />
    </ConfigProvider>
  )
}

export default MobileChangLongWithConfigProvider
