import { Table } from 'antd'

export default function Template11() {
  return (
    <div className=' sm:text-[18px] text-[14px] '>
      排列三游戏规则
      “排列三”原为“7星彩”附加玩法，自2004年12月8日起从原有的七星彩玩法中分离出来，单独使用摇奖机、摇奖球进行摇奖，“排列三”、“排列5”共同摇奖，一次摇出5个号码，“排列三”的中奖号码为当期摇出的全部中奖号码的前3位，“排列5”的中奖号码为当期摇出的全部中奖号码，每日进行开奖。
      <div>
        <div className=' text-lg font-bold mb-4'>第一章 总 则</div>
        <div className=' section'>
          <div></div>
          <div>
            第一条
            根据财政部《彩票发行与销售管理暂行规定》和国家体育总局《体育彩票发行与销售管理暂行办法》特制定本游戏规则。
          </div>
          <div>
            第二条
            体彩排列3电脑体育彩票由国家体育总局体育彩票管理中心统一发行，在全国范围内采用计算机网络系统进行联合销售。
          </div>
          <div>第三条 体彩排列3实行自愿购买，凡购买该彩票者即被视为同意并遵守本规则。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第二章 游 戏</div>

        <div className=' section'>
          <div></div>
          <div>
            第四条 购买”体彩排列3”时，由购买者从000-999的数字中选取1个3位数为投注号码进行投注。
          </div>
          <div>
            第五条
            购买者可在全国各省（区、市）体育彩票管理中心设置的投注站进行投注。投注号码可由投注机随机产生，也可通过投注单将购买者选定的号码输入投注机确定。投注号码经系统确认后打印出的兑奖凭证即为体彩排列3电脑体育彩票，交购买者保存。
          </div>
          <div>
            第六条 “体彩排列3”电脑体育彩票每注2元人民币。不记名，不挂失，不返还本金，不流通使用。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第三章 奖级设定</div>
        <div className=' section'>
          <div></div>
          <div>
            第七条
            “体彩排列3”按”直选投注”、”组选3”、”组选6”等不同投注方式进行设奖，均设一个奖级，为固定奖。
          </div>
          <div>
            第八条 各奖级奖金分配： “直选投注”，单注固定奖金1000元。 “组选3”，单注固定奖金320元。
            “组选6”，单注固定奖金160元。
          </div>
          <div>
            第九条 销售总额的50％为奖金, 分为当期奖金和调节基金。其中，49%为当期奖金，1%为调节基金。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第四章 开奖及公告</div>
        <div className=' section'>
          <div></div>
          <div>
            第十条 “体彩排列3”天天开奖，摇奖过程在公证人员监督下进行，通过电视台播出。
            “体彩排列3”“排列5”同时开奖，一次产生5个号码，其中奖号码为当期摇出的全部中奖号码的前3位。
          </div>
          <div>
            第十一条
            每期开奖后，国家体育总局体育彩票管理中心需将中奖号码、当期销售总额、各奖等中奖情况以及奖池资金余额等信息，通过新闻媒体向社会公布，并将开奖结果通知各销售终端。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第五章 中奖办法</div>
        <div className=' section'>
          <div></div>
          <div>
            第十二条 所购彩票与开奖结果对照，符合以下情况即为中奖：
            “直选投注”：所选号码与中奖号码相同且顺序一致，则该注彩票中奖。例如，中奖号码为543，则中奖结果为：543。
          </div>
          <div>
            “组选
            3”：中奖号码中任意两位数字相同（500.com注：开奖号码中的3个数字有两个相同，即为组三），所选号码与中奖号码相同且顺序不限，则该注彩票中奖。
            例如，中 奖号码为 544 ，则中奖结果为： 544 、 454 、 445 之一均可。
          </div>
          <div>
            组选6”：
            所选号码与中奖号码相同且顺序不限，则该注彩票中奖。例如，中奖号码为543，则中奖结果为：543、534、453、435、345、354之一均可。
          </div>
          <div>第十三条 当期每注彩票只有一次中奖机会，不得兼中兼得，另行设立的特别奖项除外。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第六章 兑奖</div>
        <div className=' section'>
          <div></div>
          <div>
            第十四条“体彩排列3”兑奖当期有效。每期自开奖次日起60天为兑奖期，逾期未兑，视为弃奖纳入调节基金。
          </div>
          <div>第十五条兑奖机构有权查验中奖者的中奖彩票及有效身份证件，兑奖者应予配合。</div>
          <div>第十六条凡伪造、涂改中奖彩票，冒领奖金者，送交司法机关追究法律责任。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第七章 附则</div>
        <div className=' section'>
          <div></div>
          <div>第十七条本游戏规则解释权属国家体育总局体育彩票管理中心。</div>
          <div>第十八条本游戏规则自下发之日起执行.</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>1、直选组合复式 </div>
        <div className='section'>
          <div></div>
          <div>
            {`排列3玩法直选组合复式票是从0～9中选择M（M>=3)个不重复数字，组合成P(3,M)个单式投注，票面上标明“直选组合复式票”。`}
            <div>
              {`
            例如：彩民选择1、2、3、4这4个号码，就是要实现包含这4个数字的全部排列3直选号码：123、132、321、312、213、231、124、142、214、241、412、421、234、243、324、342、432、423、134、143、341、314、431、413共24注号码，能在一张彩票上表现出来。 
            `}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>2、直选组合胆拖复式 </div>
        <div className='section'>
          <div></div>
          <div>
            {`排列3玩法从0～9中选择M（0<M<=2)个不重复数字作为胆码，选择N（N＋M>=4）个不重复、且不包含在胆码中的数字作为拖码，组合成P(3,3)×C[(3-M),N]个直选投注，票面上标明“直选组合胆拖票”。`}
            <div>
              {`例如，胆码为数字1，拖码为2、3、4。该直选组合胆拖票是把123、132、321、312、213、231、124、142、214、241、412、421、134、143、341、314、431、413这18注号码在一张彩票上表现出来。`}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>3、直选跨度复式 </div>
        <div className='section'>
          <div></div>
          <div>
            什么叫跨度：排列3开奖号码中最大数与最小数之差称为排列3跨度，排列3共有0～9十种跨度。彩民投注时，猜排列3开奖时开奖号码的最大数与最小数之差的值，即猜跨度，称为跨度投注。跨度投注分为3种：直选跨度投注、组选3跨度投注、组选6跨度投注。
            <div>
              排列3玩法直选跨度票是满足跨度为n（0～9）的所有可能的排列3直选票，票面标明“直选跨度票”。
            </div>
            如，直选跨度票，当跨度为0时，包含000、111、222、333、444、555、666、777、888、999共10注直选票。
          </div>
          <div>下表是直选跨度票投注各跨度所包含的直选票注数：</div>
          <div>
            <Table
              scroll={{ x: true }}
              bordered
              columns={[
                { title: '跨度', dataIndex: 'kuadu', className: 'bgColor colBgColor' },
                { title: '0', dataIndex: '0', className: 'bgColor' },
                { title: '1', dataIndex: '1', className: 'bgColor' },
                { title: '2', dataIndex: '2', className: 'bgColor' },
                { title: '3', dataIndex: '3', className: 'bgColor' },
                { title: '4', dataIndex: '4', className: 'bgColor' },
                { title: '5', dataIndex: '5', className: 'bgColor' },
                { title: '6', dataIndex: '6', className: 'bgColor' },
                { title: '7', dataIndex: '7', className: 'bgColor' },
                { title: '8', dataIndex: '8', className: 'bgColor' },
                { title: '9', dataIndex: '9', className: 'bgColor' },
              ].map((i) => ({ ...i, align: 'center' }))}
              pagination={false}
              dataSource={[
                {
                  kuadu: '含直选票',
                  0: '10注',
                  1: '54注',
                  2: '96注',
                  3: '126注',
                  4: '144注',
                  5: '150注',
                  6: '144注',
                  7: '126注',
                  8: '96注',
                  9: '54注',
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 4、组选3跨度复式（排列3） </div>
        <div className='section'>
          <div></div>
          <div>
            组选3跨度票是满足跨度为n（0～9）的所有可能的组选3票，票面标明“组选3跨度票”。打印票面显示跨度值，不拆开显示组选3票。
            <div>如，组选3跨度票，当跨度为9时，包含099、900共2注组选3票；</div>
            <div>当跨度为8时，包含088、800、199、911共4注组选3票；</div>
          </div>
          <div>下表是组选3跨度票投注各跨度所包含的组选3票注数：</div>
          <div>
            （说明：当跨度为0时，没有符合跨度要求的组选3票投注，注数是0注，所以组选3跨度票不能选择跨度为0。）
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 5、组选6跨度复式（排列3） </div>
        <div className='section'>
          <div></div>
          <div>
            组选6跨度票是满足跨度为n（0～9）的所有可能的组选6票，票面标明“组选6跨度票”。打印票面显示跨度值，不拆开显示组选6票。
            <div>
              如，组选6跨度票，当跨度为9时，包含019、029、039、049、059、069、079、089共8注组选6票。
            </div>
          </div>
          <div>下表是组选6跨度票投注各跨度所包含的组选6票注数：</div>
          <div>
            <Table
              scroll={{ x: true }}
              bordered
              columns={[
                { title: '跨度', dataIndex: 'kuadu', className: 'bgColor colBgColor' },
                { title: '0', dataIndex: '0', className: 'bgColor' },
                { title: '1', dataIndex: '1', className: 'bgColor' },
                { title: '2', dataIndex: '2', className: 'bgColor' },
                { title: '3', dataIndex: '3', className: 'bgColor' },
                { title: '4', dataIndex: '4', className: 'bgColor' },
                { title: '5', dataIndex: '5', className: 'bgColor' },
                { title: '6', dataIndex: '6', className: 'bgColor' },
                { title: '7', dataIndex: '7', className: 'bgColor' },
                { title: '8', dataIndex: '8', className: 'bgColor' },
                { title: '9', dataIndex: '9', className: 'bgColor' },
              ].map((i) => ({ ...i, align: 'center' }))}
              pagination={false}
              dataSource={[
                {
                  kuadu: '含组选6票',
                  0: '0注',
                  1: '0注',
                  2: '8注',
                  3: '14注',
                  4: '18注',
                  5: '20注',
                  6: '20注',
                  7: '18注',
                  8: '14注',
                  9: '8注',
                },
              ]}
            />
          </div>
          <div>
            （说明：当跨度为0、1时，没有符合跨度要求的组选6票投注，注数是0注，所以组选6跨度票不能选择跨度为0、1。）
          </div>
          <div>
            <Table
              scroll={{ x: true }}
              bordered
              columns={[
                { title: '跨度', dataIndex: 'kuadu', className: 'bgColor colBgColor' },
                { title: '0', dataIndex: '0', className: 'bgColor' },
                { title: '1', dataIndex: '1', className: 'bgColor' },
                { title: '2', dataIndex: '2', className: 'bgColor' },
                { title: '3', dataIndex: '3', className: 'bgColor' },
                { title: '4', dataIndex: '4', className: 'bgColor' },
                { title: '5', dataIndex: '5', className: 'bgColor' },
                { title: '6', dataIndex: '6', className: 'bgColor' },
                { title: '7', dataIndex: '7', className: 'bgColor' },
                { title: '8', dataIndex: '8', className: 'bgColor' },
                { title: '9', dataIndex: '9', className: 'bgColor' },
              ].map((i) => ({ ...i, align: 'center' }))}
              pagination={false}
              dataSource={[
                {
                  kuadu: '含组选3票',
                  0: '0注',
                  1: '18注',
                  2: '16注',
                  3: '14注',
                  4: '12注',
                  5: '10注',
                  6: '8注',
                  7: '6注',
                  8: '4注',
                  9: '2注',
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>6、 组选3胆拖（排列3） </div>
        <div className='section'>
          <div></div>
          <div>
            {` 从0～9中选择1个数字作为胆码，选择N（2<=N<=9)个不重复、且不包含在胆码中的数字作为拖码，组合成2*N个组选3投注，票面上标明“组选3胆拖票”。`}
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>7、组选6胆拖（排列3） </div>
        <div className='section'>
          <div></div>
          <div>
            {`从0～9中选择M（1<＝M<=2）个不重复数字作为胆码，选择N（N＋M>=4）个不重复、且不包含在胆码中的数字作为拖码，组合成C[(3-M)，N]个组选6投注，票面上标明“组选6胆拖票”。`}
          </div>
          <div>
            {`“排列3”设奖方式： 按“直选投注”、“组选3”、“组选6”分别设奖，资金为：“直选投注”单注固定奖金1000元；“组选3”单注固定奖金320元；“组选6”单注固定奖金160元。`}
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>“排列3”设奖方式 ： </div>
        <div className='section'>
          <div></div>
          <div>
            {`按“直选投注”、“组选3”、“组选6”分别设奖，资金为：“直选投注”单注固定奖金1000元；“组选3”单注固定奖金320元；“组选6”单注固定奖金160元。`}
          </div>
        </div>
      </div>
    </div>
  )
}
