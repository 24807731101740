export default {
  translation: {
    CHANGE_SKIN:'Theme',
    SET_HOMEPAGE:'Set As Homepage',
    BOOK_MARK:'Bookmark',
    HELP_CENTER:'Help Center',

    HOME_PAGE:'Home',
    LOTTERY_HOME :'Lottery',
    PLAN_RECOMMENDATION:'Recommendation',
    CHANG_LONG_REMINDER:'Chang long',
    TREND_CHART:'Chart',
    DRAW_CALL :'Api',
    TRIAL_BET:'Trial',

    ABOUT_US:'About US',
    SERVICE_CENTER:'Service Center',
    BUSINESS_COOPERATION:'Cooperation',
    FREE_DUTY:'Agreement',
    FAST_LINK:'Links',
    DRAW_BUMBER:'Draw Number',
    SOFTWARE_DOWNLOAD:'Download',
    FREE_CALL:'Api Call',
    SELF_WEB_NAVIGATOR:'Website Navigator',
    ONLINE_SERVICE:'Online Service',

    NEWS_INFO:'News',
    DRAW_CENTER:'Draw Center',
    DRAW_RESULT:'Draw Result',
    DRAW_RECORD:'Draw Record',

    CHANNEL_HOME:'Channel Home',
    H_NUMBER_TREND:'横板号码走势',
    V_NUMBER_TREND:'竖版号码走势',
    HOT_NUMBER:'热门号码',
    NUMBER_MISSING:'号码遗漏',
    ZH_LUZHU:'综合路珠',
    GYH_LUZHU:'冠亚和路珠',
    DS_LUZHU:'单双路珠',
    DX_LUZHU:'大小路珠',
    LH_LUZHU:'龙虎路珠',
    RULES:'玩法规则',
    ZH_TREND:'总和走势',
    ZONG_HE_LUZHU:'Sum Luzhu',

    HK6_CHANNEL_HOME:'Channel Home',
    HK6_TEMA_SUM:'HK6_TEMA_SUM',
    HK6_TEMA_DAN_SHUANG:'HK6_TEMA_DAN_SHUANG',
    HK6_TEMA_WEI:'HK6_TEMA_WEI',
    HK6_TEMA_BO_SE:'HK6_TEMA_BO_SE',
    HK6_TEMA_DA_XIAO:'HK6_TEMA_DA_XIAO',
    HK6_TEMA_TOU_SHU:'特码HK6_TEMA_TOU_SHU头数',
    HK6_TEMA_HE_SHU:'HK6_TEMA_HE_SHU',
    HK6_TEMA_MISSING:'HK6_TEMA_MISSING',

    // 长龙提醒
    CHECK_ALL:'All',
    CLEAR:'Clear',
    CONTINUOUS_OPEN:'Open',
    CONTINUOUS_UNOPENED:'Unopened',
    ONLY_SHOW:"Only show",
    INSTALMENT_ABOVE:'above',
    SOUND_ALARM:'Sound Alarm',
    LOTTERY:"Lottery",
    LOCATION:"Location",
    CONTINUOUS_OPEN_INSTALMENT:'Continuous Open',
    LUZHU:'Lu zhu',
    CHECK:"Check",
    CHECK_LUZHU:'Lu zhu',
    CONTINUOUS:'Continuous',
    FILTER:'Filter',
    CONFIRM:"Confirm",

    // 路珠
    CONTINUOUS_OCCUR:'Continuous Occur',
    LUZHU_ABOVE:'above',
    OCCURRENCES:'times',
    LUZHU_TOTAL:'Total',
    NEWEST:'Newest',
    TODAY:'Today',
    YESTERDAY:'Yesterday',
    THE_DAY_BEFORE_YESTERDAY:'The day before yesterday',
    FILTER_RANKING:'Filter Ranking',
    SELECT_LUZHU:'Select Luzhu',

    // 开奖调用
    CLICK_TO_GET_CODE:'Click to get code',
    CHOOSE_DISPLAY_LOTTERY:'Display Lottery',
    CHOOSE_DISPLAY_TYPE:'Display Type',
    MULTIPLE_LOTTERY:'Multiple',
    SINGLE_LOTTERY:'Single',
    CUSTOME_LOTTERY_STYLE:'Customize Lottery Style',
    INFO_STYLE:'Info Style',
    INFO_CALL:'Info Call',
    BETTING_CALL:'Betting Call',
    LOTTERY_HALL_CALL:'Lottery Hall Call',
    COPY:'Copy',
    INFO_CONTENT:'We provide the most popular high-frequency lottery lottery historical data calling service for Betting.com. If your website has more than 10,000 IPs, you can contact us and customize exclusive historical lottery data for free. At present, we provide historical lottery data calls for more than 40 lottery types such as Guangdong Happy Ten, Beijing Racing, Chongqing Happy Zodiac, Chongqing Lucky Farm, Jiangsu Kuaisan, and Mark Six. You can click Generate Code for Calling, copy and paste it to the corresponding location on your website to complete the call. It can reduce the burden on the server and provide more comprehensive and rapid analysis of lottery data! ',
    CUSTOME_STYLE_PREVIEW:'Customize Style Preview',
    CUSTOME_STYLE_PREVIEW_WITH_BG:'Customize Style Preview With Background Color',
    CUSTOME_BACKGROUND_COLOR:'Customize Background Color',
    DATA_CALL_Q1:'1. Is there a charge for calling the lottery?',
    DATA_CALL_Q2:'2. What are the characteristics of data calling?',
    DATA_CALL_Q3:'3. Is the data call data stable?',
    DATA_CALL_Q4:'4. Does the calling address support https?',
    DATA_CALL_A1:'Answer: All high-frequency color calls are free forever',
    DATA_CALL_A2:'Answer: The high-frequency color variety is complete, the lottery is the fastest and most punctual, and multiple color types can be called at the same time, or they can be called individually',
    DATA_CALL_A3:'Answer: The data on this site is maintained and monitored by a dedicated person to ensure that the data is stable and tiDATA',
    DATA_CALL_A4:'Answer: Support, you only need to replace "http" in the URL with "https" to use',
    DATA_CALL_DESC:'Data call description',
    GET_CODE_SUCCESS:'Get code successfully',
    PLEASE_COPY:'Please copy the code into the HTML you want to call',

    //新闻资讯
    NEWS_CONTENT:'News Detail',
    READ:'Read',
    BACK:'Back',
    TOTAL:'Total',
    RESULTS:'Results',

    //热门号码
    NUMBER_SELECT:'号码选择',
    SHOW_INSTALLMENT:'显示期数',
    SETTING:'设置',
    LOTTERY_APP:'Lottery App',
  }
};