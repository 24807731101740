
import { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import MobileLayout from "./layout/mobile/MobileLayout";
import WebLayout from "./layout/web/WebLayout";
import appActions from "./redux/actions/appActions";
import { AppDispatch, RootState } from "./redux/store";
import { connect } from 'react-redux';
import { install } from 'resize-observer';

import Download from "./views/Mobile/Download";
import WebDownload from "./views/Web/Download";

import './App.scss';
import MobileApiCall from './views/Mobile/MobileApiCall';
import MobileLottery from './views/Mobile/MobileLottery';
import MobilePlan from './views/Mobile/MobilePlan';
import MobileChart from './views/Mobile/MobileChart';
import MobileChangLong from './views/Mobile/MobileChangLong';
import MobileHome from './views/Mobile/MobileHome';

import ApiCall from './views/Web/ApiCall';
import Chart from './views/Web/Chart';
import ChangLong from './views/Web/ChangLong';
import Plan from './views/Web/Plan';
import Lottery from './views/Web/Lottery';
import Home from './views/Web/Home';
import lotteryActions from './redux/actions/lotteryActions';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import noticeActions from './redux/actions/noticeActions';
import { AnimatePresence } from 'framer-motion';
import News from './views/Web/News';
import MobileNews from './views/Mobile/MobileNews';
import HelpCenter from './views/Web/HelpCenter';
import MobileHelpCenter from './views/Mobile/MobileHelpCenter';


import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import 'dayjs/locale/zh-cn'
import { useTranslation } from 'react-i18next';

import { setDefaultLocale, registerLocale } from  "react-datepicker";
import { enUS as EN, zhCN as ZH } from 'date-fns/locale'
import AboutUs from './views/Web/AboutUs';
import MobileAboutUs from './views/Mobile/MobileAboutUs';
import { ILotteryItem } from './types';

registerLocale('enUS', EN);
registerLocale('zhCN', ZH);


const mapStateToProps = (state:RootState) => ({
  theme: state.appReducers.theme,
  platform: state.appReducers.platform,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  setTheme: (theme:string) => dispatch(appActions.setTheme(theme)),
  setPlatForm: (platform:string) => dispatch(appActions.setPlatForm(platform)),
  GetLotteryList: () => dispatch(lotteryActions.GetLotteryList()),
  GetNotice: () => dispatch(noticeActions.GetNotice()),
  GetLotteryNews: (top = 10) => dispatch(noticeActions.GetLotteryNews(top)),
  setTemplateAndCode: (obj:{template:string, code:string}) => dispatch(appActions.setTemplateAndCode(obj)),
});

interface IProps {
  platform:string,
  theme:string,
  setTheme:(theme:string)=>void,
  setPlatForm:(platform:string)=>void,
  GetLotteryList:()=>void,
  GetNotice:()=>void,
  GetLotteryNews:(value?:number)=>void,
  setTemplateAndCode:(obj:{template:string, code:string})=>void,
}


if (!window.ResizeObserver) install();

function App({theme, setTheme, GetLotteryList,GetNotice,GetLotteryNews, setPlatForm, platform}:IProps) {
  const { lotteryList } = useAppSelector((state) => state.lotteryReducers);
  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();  

  const detectPlatform = () => {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) !== null;
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
    const bIsMidp = sUserAgent.match(/midp/i) !== null;
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
    const bIsUc = sUserAgent.match(/ucweb/i) !== null;
    const bIsAndroid = sUserAgent.match(/android/i) !== null;
    const bIsCE = sUserAgent.match(/windows ce/i) !== null;
    const bIsWM = sUserAgent.match(/windows mobile/i) !== null;
    if ((bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
      setPlatForm('Mobile')
      return 'Mobile';
    }
    setPlatForm('Web')
    return 'Web';
  };

  useEffect(() => {
    detectPlatform();
    GetLotteryNews();
    GetLotteryList();
    GetNotice();
    const temp:string = localStorage.getItem('themeColor') || '';
    if (!temp) {
      localStorage.setItem('themeColor', 'red');
    }else{
      setTheme(temp)
    }
  }, []);

  useEffect(()=>{
    if(lotteryList.lotterys.length > 0 && !templateAndGame.code && !templateAndGame.template){   
      const game = lotteryList.lotterys.find((x:ILotteryItem)=>x.template !== 'HK6');
      if(game){
        dispatch(appActions.setTemplateAndCode({template:game.template,code:game.code}))
      }
    }
  },[lotteryList, templateAndGame])

  setDefaultLocale(i18n.language === 'zh' ? 'zhCN' : 'enUS')
  
  return (
    <ConfigProvider locale={i18n.language === 'zh' ? zhCN : enUS}>
      <div className={`theme-${theme || 'red'} h-full`}>
        <div className="KG186App">
          <AnimatePresence mode='wait' >
            <Routes key={location.pathname} location={location}>
              <Route path='/download'  element={platform === 'Web' ? <WebDownload /> : <Download />} />
              <Route path="/" element={(platform === 'Web' ? <WebLayout /> : <MobileLayout />)}>
                <Route index element={(platform === 'Web' ? <Home /> : <MobileHome />)}  />
                <Route path="lottery" element={(platform === 'Web' ? <Lottery /> : <MobileLottery />)} />
                <Route path="plan" element={(platform === 'Web' ? <Plan /> : <MobilePlan />)} />
                <Route path="changlong" element={(platform === 'Web' ? <ChangLong /> : <MobileChangLong />)}  />
                <Route path="chart" element={(platform === 'Web' ? <Chart /> : <MobileChart />)} />
                <Route path="apicall" element={(platform === 'Web' ? <ApiCall /> : <MobileApiCall />)} />
                <Route path="news" element={(platform === 'Web' ? <News /> : <MobileNews />)} />
                <Route path="help" element={(platform === 'Web' ? <HelpCenter /> : <MobileHelpCenter />)} />
                <Route path="about" element={(platform === 'Web' ? <AboutUs /> : <MobileAboutUs />)} />
              </Route>
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

