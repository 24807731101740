export default function Template6() {
  return (
    <div className=' sm:text-[18px] text-[14px] '>
      <div className=' section'>
        <div>1.大小玩法</div>
        <div>数字14-27为大 数字0-13为小 出13押小回本金 出14押大回本本金。</div>
      </div>
      <div className=' section'>
        <div>2.单双玩法</div>
        <div>数字1，3，5，~27为单 数字0，2，4~26为双 出13压单回本出14押双回本。</div>
      </div>
      <div className=' section'>
        <div>3.极值玩法</div>
        <div>
          [极小0-5] 10倍 [极大22-27] 10倍 举例：买极小100元
          开奖结果为0-5其中一个数字就中1000元（包括本金）极大反之。
        </div>
      </div>
      <div className=' section'>
        <div>4.组合玩法</div>
        <div>数字14，16，~26为大双 数字0，2，4，~12为小双。</div>
        <div>数字15，17，~27为大单 数字1，3，5，~13为小单。</div>
        <div>开13，14组合回本，举例 买100元小单开13就回本金 买100元大双开14就回本金。</div>
        <div>其余买大单 小单 大双 小双 奖金为本金的4倍。</div>
      </div>
      <div className=' section'>
        <div>5.定位玩法(单点数字玩法)</div>
        <div>从数字0-27中选取一个数字 中奖以对应赔率含本金奖励。</div>
      </div>
      <div className=' section'>
        <div>6.波色</div>
        <div>
          绿波号码：1 , 4 , 7 , 10 , 16 , 19 , 22 , 25 蓝波号码：2 , 5 , 8 , 11 , 17 , 20 , 23 , 26
        </div>
        <div>红波号码：3 , 6 , 9 , 12 , 15 , 18 , 21 , 24</div>
        <div>赔率 1 : 3 开</div>
        <div>黄波下注色波任何一个都视为不中奖！</div>
      </div>

      <div className=' section'>
        <div>7.豹子</div>
        <div>当期3个开奖号码一致则中奖,赔率 1 : 50 , 如当期开奖结果为 3+3+3=9 则中奖豹子。</div>
      </div>
      <div className=' section'>
        <div>8.特码包三</div>
        选取三个特码,三个特码中有任一号码和开奖结果总和一致即中奖,赔率 1 : 3.5。
      </div>
    </div>
  )
}
