import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './styles.scss';
import { useLocation, useNavigate } from 'react-router-dom';


function MobileFooter (){   
  const { t } = useTranslation();  
  const navigate = useNavigate();
  const location = useLocation();  
    
  const onLinkClick = (tabName:string)=>{
    if(tabName === 'DRAW_CENTER'){
      return navigate('/');
    }
    if(tabName === 'CHANG_LONG_REMINDER'){
      return navigate('/changlong');
    }
    if(tabName === 'ABOUT_US'){
      return navigate('/about');
    }
    if(tabName === 'TREND_CHART'){
      return navigate('/chart');
    }
  }

  return ( 
    <div className='mobileFooterWrapper w-full flex'>
      {
        [
          {tabName:'DRAW_CENTER',path:'/'},
          {tabName:'CHANG_LONG_REMINDER',path:'/changlong'},
          {tabName:'ABOUT_US',path:'/ABOUT_US'},
          {tabName:'TREND_CHART',path:'/chart'},
        ].map(item=> {
          let linkIcon;
          try {
            linkIcon = require(`../../../assets/mobile/image/${item.tabName}.png`)
          } catch (error) {
            linkIcon = ''
          }
          return <div 
            onClick={()=> onLinkClick(item.tabName)}
            key={`menu_tab_${item.tabName.toString()}`} 
            className={`flex-1 text-white flex flex-col items-center justify-center ${location.pathname === item.path && 'menuTabActive'}`}>
            <img src={linkIcon} alt="" srcSet="" />
            <div>{t(item.tabName)}</div>
          </div> 
        }
        )
      }
    </div>
  );
}
export default connect(null, null)(MobileFooter);