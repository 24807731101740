export default function Template15() {
  return (
    <div>
      <div className=' section'>
        <div>该游戏的投注时间、开奖时间和开奖号码与台湾宾果彩完全同步。台湾宾果彩从1到80共八十个球，每次开20个球</div>
        <div>按开奖顺序，只取宾果彩开奖20个球的前面5个球，分别对应平码一~平码四及特码。</div>
        <div>开奖时间：7:00 至 23:55分 每期5分钟。</div>
        <div>开奖频率：每期4分30秒销售，30秒开奖，每日期数：203期</div>
        <div>具体游戏规则如下:</div>
      </div>
      <div className=' section'>
        <div>单双大小：根据平码一 ~ 平码四及特码开出的球号，判断胜负。</div>
        <div>单双：1,3,5,7,51等为单，2,4,6,68等为双</div>
        <div>数大、小：开奖号码尾数大于或等于5为尾大，小于或等于4为尾小</div>
        <div>和尾数大、小：所有5个开奖号码的数字总和数值的个位数大于或等于5为总尾大，小于或等于4为总尾小</div>
        <div>总和大小：数字总和值203-390为总大，数字总和值15到202为总小</div>
        <div>总和单双：数字总和值是双数为总和双，数字总和值是单数为总和单。</div>
      </div>
      <div className=' section'>
        <div>龙虎以第一球的中奖号码和第五球的中奖号码做为对奖号码。</div>
        <div>龙：开出之号码第一球的中奖号码大于第五球的中奖号码。如 第一球开出09 第五球开出01；第一球开出07 第五球开出04；第一球开出05 第五球开出01...中奖为龙。</div>
        <div>虎：开出之号码第一球的中奖号码小于第五球的中奖号码。如 第一球开出03 第五球开出04；第一球开出02 第五球开出08；第一球开出05 第五球开出08...中奖为虎。</div>
        <div>福：01,02,03,04,05,21,22,23,24,25,41,42,43,44,45,61,62,63,64,65</div>
        <div>禄：06,07,08,09,10,26,27,28,29,30,46,47,48,49,50,66,67,68,69,70</div>
        <div>寿：11,12,13,14,15,31,32,33,34,35,51,52,53,54,55,71,72,73,74,75</div>
        <div>喜：16,17,18,19,20,36,37,38,39,40,56,57,58,59,60,76,77,78,79,80</div>
      </div>
    </div>
  )
}
        