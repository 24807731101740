/* eslint-disable @typescript-eslint/no-explicit-any */
import demoActions from '../../../redux/actions/demoActions';
import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import './styles.scss';

import templates from '../../../constants/templates';
import { useEffect, useState } from 'react';
import { apiService } from '../../../services';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line  } from 'react-chartjs-2';
import type { ScriptableTooltipContext } from 'chart.js';
import { ILatestItem, ILotteryList } from '../../../types';
import Table from '../../common/Table';
import dayjs from 'dayjs';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import  ExternalTooltipHandler  from '../../common/ExternalTooltipHandler';
import MobileChangLongLiangMianSideMenu from '../MobileChangLongLiangMianSideMenu';
import { Pagination } from 'antd';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface toolTipProp{
  x:number,
  y:number,
  item:ILatestItem
}

interface ILiangMian{
  da:number[],
  xiao:number[],
  dan:number[],
  shuang:number[]
}

const mapStateToProps = (state:RootState) => ({
  lotteryList: state.lotteryReducers.lotteryList,
  templateAndGame:state.appReducers.templateAndGame,
  companyCode:state.appReducers.companyCode,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
});

interface IProps{
  lotteryList:ILotteryList,
  templateAndGame:{template:string; code:string},
  companyCode:string
}

function MobileChannelHome ({lotteryList, templateAndGame, companyCode}:IProps){
  const [historyData,setHistoryData] = useState<ILatestItem[]>();
  const [trendData, setTrendData] = useState<{datasets:Array<{data:Array<{x:number | string,y:number}>}>}>({datasets:[]});
  const [selectedTemplate, setSelectedTemplate] = useState<string>(templateAndGame.template);
  const [date, setDate] = useState(dayjs());

  const [tableSelectedIndex, setTableSelectedIndex] = useState('0')
  const [isRndNum, setIsRndNum] = useState(0);

  const [isGPC, setIsGPC] = useState(false);

  const [subListStatus, setSubListStatus] = useState(0);
  const [changLongData, setChangLongData] = useState<Array<{location:string,count:number}>>([]);
  const [liangMianData, setLiangMianData] = useState<ILiangMian>();

  const _pageSize = 10;

  const [paginationProps, setPaginationProps] = useState({page:1,pageSize:_pageSize})
  const [total, setTotal] = useState(0);

  const { lotterys = [] } = lotteryList;

  const CustomTooltip = (obj:toolTipProp) => {  
    const type = templateAndGame.template === 'PKS' ? '冠亚和' : '总和'
    return (
      <div className="p-2">
        <div className='flex mb-2 justify-center whitespace-nowrap'>
          {`${type} ${obj.x}期 和值为： ${obj.y}`}
        </div>
        <div className={`flex ${templates[`${selectedTemplate}`].templateWrapper}`}>
          {templates[`${selectedTemplate}`].GenerateNumber(obj.item).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
            return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
          }
          ) }
          {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>+{obj.item.rnd}</div>}
        </div>
      </div>
    );  
  };

  const options = {
    maintainAspectRatio:false,
    layout: {
      padding:0
    },
    elements:{
      line:{
        backgroundColor: '#FFC400',
        borderColor: '#FFC400',
      },
      point:{
        pointStyle: 'circle',
        pointRadius: 4,
        backgroundColor:'#FFC400',
      }
    },
    scales: {
      x: {
        ticks: {
          display: false
        }
      }
    },
    parsing:{
      xAxisKey: 'x',
      yAxisKey: 'y'
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: (context:ScriptableTooltipContext<'line'>)=>ExternalTooltipHandler<'line', toolTipProp>(context, CustomTooltip),
      },
      legend:{
        display:false
      }
    }
  };

  const getInfo = async ({
    lotteryCode,
    page = 1,
    pageSize = _pageSize,
    date,
    flag
  }: {
    lotteryCode:string;
    page:number;
    pageSize:number;
    date:string,
    flag:boolean
  }) => {
    try {
      let res;
      if(flag){
        res = await apiService.get(`/lottery/history_gpc/${companyCode}/${lotteryCode}/${date}/${page}/${pageSize}`)
      }else{
        res = await apiService.get(`/lottery/history_dpc/${companyCode}/${lotteryCode}/${date}/${page}/${pageSize}`)
      }
      if(res?.data?.data){
        const data = res.data.data?.list ?? [];
        setTotal(res.data.data.recordCount)
        const temp = data.slice(0,20).map((item:{draw_code:string,issue:string})=> {
          const arr = item.draw_code.split(',').map(x=>Number(x));
          return ({
            y: templateAndGame.template === 'PKS' || templateAndGame.template === 'YDH' ? (arr[0] + arr[1]) : arr.reduce((a,b)=>a + b,0),
            x:String(item.issue),
            item
          })
        } 
        )
        setTrendData({datasets:[{ data:temp}]})    
        setHistoryData(data)     
      } 
    } catch (error) {
      // console.log(error)
    } finally {
      // console.log(error)
    }
  }

  useEffect(()=>{
    setTableSelectedIndex('0');
    const obj = {page:1,pageSize:_pageSize};
    setPaginationProps({...obj})
  },[templateAndGame,date])

  useEffect(()=>{
    if(templateAndGame?.code){   
      const lot = lotterys.find(x=>x.code === templateAndGame.code)
      setIsRndNum(lot?.isRndNum ?? 0)  
      setIsGPC(lot?.group === 1000 ? true : false )   
      setHistoryData(undefined)
      setSelectedTemplate(templateAndGame.template)
      const flag = lot?.group === 1000 ? true : false
      const obj = {
        lotteryCode:templateAndGame.code,
        date : flag ? date.format('YYYY-MM-DD') : date.format('YYYY'),
        flag,
        ...paginationProps
      }
      getInfo(obj)
    }
  },[paginationProps])  

  const onDateChange = (date:Date)=>{
    setHistoryData(undefined);
    setDate(dayjs(date))
  }

  const onSubListClick = (index:number)=>{
    setSubListStatus(index)
  }

  useEffect(()=>{
    setDate(dayjs());
    let timerIndex = 0;
    setSubListStatus(0);
    setChangLongData([]);
    setLiangMianData(undefined);
    const getChangLong = async () => {
      if(['PKS','YDH','SSC','KS'].includes(templateAndGame?.template)){
        const res = await apiService.get(`/changlong/${companyCode}/${templateAndGame?.code}/0`);
        setChangLongData(res.data?.data || [])   
      }
    }
    const getLiangMian = async () => {
      if(['PKS','YDH','SSC'].includes(templateAndGame?.template)){
        const res = await apiService.get(`/liangmian/${companyCode}/${templateAndGame?.code}/${format(new Date(),'yyyy-MM-dd')}`);
        setLiangMianData(res.data?.data || [])   
      }
    }
    getChangLong();
    getLiangMian()
    timerIndex = window.setInterval(()=>{
      getChangLong();
      getLiangMian();
    },15000)
    return ()=>{
      clearInterval(timerIndex)
    }
  },[templateAndGame])    
  
  return ( 
    <div className='MobileChannelHomeWrapper'>
      <MobileChangLongLiangMianSideMenu data = {subListStatus == 1 ? liangMianData : changLongData} mobileLotteryMenuIsVisible={subListStatus} setMobileLotteryMenuIsVisible={()=>{setSubListStatus(0)}} />
      <div className='text-[16px] font-bold mb-2'>
          开奖记录
      </div>
      <div className='w-full  flex items-center'>
        {['PKS','YDH','SSC'].includes(selectedTemplate) && <div className={`buttonWrapper ${subListStatus === 1 ? 'selected' : ''}`} onClick={()=>onSubListClick(1)}>今日双面/号码统计</div>}
        {['PKS','YDH','SSC','KS'].includes(selectedTemplate) && <div className={`buttonWrapper ${subListStatus === 2 ? 'selected' : ''}`} onClick={()=>onSubListClick(2)}>长龙提醒</div>}
      </div>
      {!templateAndGame.code.includes('_FT') && lotterys.find(x=> x.code === templateAndGame.code)?.group === 1000 &&
        <div className=' w-full relative flex justify-center h-[300px]'>
          {trendData.datasets?.length > 0 &&
            <Line options={options} data={trendData} />
          }
        </div>
      }
      {templateAndGame?.code && lotterys?.length > 0 && 
      <div className='my-[15px]'>
        { templateAndGame.code.includes('_FT') || lotterys.find(x=> x.code === templateAndGame.code)?.group === 1000 ?
          <div className=' flex items-center'>
            <div className=' flex'>
              {[
                { label: '今天', value: dayjs() },
                { label: '昨天', value: dayjs().subtract(1, 'day') },
                { label: '前天', value: dayjs().subtract(2, 'day')},
              ].map((item) => {
                return (
                  <div
                    onClick={() => setDate(item.value)}
                    className={` buttonWrapper mr-[16px] ${
                      (item.value.format('YYYY-MM-DD') === date.format('YYYY-MM-DD') && 'selected') || ''
                    }`}
                    key={item.label}
                  >
                    {item.label}
                  </div>
                )
              })}
            </div>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={date.toDate()} 
              maxDate={new Date()}
              onChange={(date)=>{
                if(date){
                  onDateChange(date)
                }
              }}
            />
          </div>
          :
          <div className=' flex items-center'>
            <div className=' flex'>
              {[
                { label: '今年', value: dayjs() },
                { label: '去年', value: dayjs().subtract(1, 'year') },
                { label: '前年', value: dayjs().subtract(2, 'year')},
              ].map((item) => {
                return (
                  <div
                    onClick={() => setDate(item.value)}
                    className={` buttonWrapper mr-[16px] ${
                      (item.value.format('YYYY') === date.format('YYYY') && 'selected') || ''
                    }`}
                    key={item.label}
                  >
                    {item.label}
                  </div>
                )
              })}
            </div>
            <DatePicker
              showYearPicker
              dateFormat="yyyy"
              selected={date.toDate()} 
              maxDate={new Date()}
              onChange={(date)=>{
                if(date){
                  onDateChange(date)
                }
              }}
            />
          </div>
        }
        <div className='flex flex-wrap'>
          {
            templates[selectedTemplate].ChannelDrawResult.mobile.header.map(item=> 
              <div 
                key={item.label} 
                className={`buttonWrapper ${tableSelectedIndex === item.value ? 'selected' : ''}`}
                onClick={()=> setTableSelectedIndex(item.value)}
              >
                {item.label}
              </div> 
            )
          }
        </div>
        <Table
          key={`${selectedTemplate}-${tableSelectedIndex}`}
          header = {[
            ()=><div className='flex items-center h-full'>期数/时间</div>,
            ()=><div className='flex justify-center items-center h-full'>
              {
                templates[selectedTemplate].ChannelDrawResult.mobile.header.find(x=> x.value === tableSelectedIndex)?.mulitLabel
                  ?
                  <>{templates[selectedTemplate].ChannelDrawResult.mobile.header.find(x=> x.value === tableSelectedIndex)?.mulitLabel?.map((item,index)=> <div key={index} className={item?.className}>{item.label}</div> )}</>

                  : templates[selectedTemplate].ChannelDrawResult.mobile.header.find(x=> x.value === tableSelectedIndex)?.label ?? ''
              }
            </div>
          ]
          }
          contentProperty = {
            [
              (item:any)=> <div className='flex flex-col justify-center w-full'>
                <div className='flex'>{(item as ILatestItem).issue}</div> 
                <div className='flex'>{
                  isGPC
                    ?
                    dayjs((item as ILatestItem)?.draw_time ?? '').format('HH:mm:ss')
                    :
                    (item as ILatestItem).draw_time
                }</div>
              </div>,
              (item:any)=> <div className='flex flex-col justify-center items-center w-full'>
                {
                  templates[selectedTemplate].ChannelDrawResult.mobile.rowProperty(item,tableSelectedIndex, isRndNum)
                }
              </div>,
            ]
          }
          contentData = {historyData ?? []}
          loading = {historyData ? false : true}
        />
        <div className='w-full flex justify-center pt-[20px]'>
          <Pagination size='small'  onChange={(page)=>setPaginationProps({page,pageSize:_pageSize})} current={paginationProps.page} total={total} pageSize={_pageSize} pageSizeOptions={[_pageSize]} />
        </div>
      </div>
      }
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileChannelHome);
