import './styles.scss'
import { Checkbox, ConfigProvider, Empty, Select, Skeleton } from 'antd'
import useChanglong from './hooks/useChanglong'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'

import enUS from 'antd/locale/en_US'
import zhCN from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'

/** 选择的期数,3 - 15 */
export const options = [...Array(13)].map((_, index) => {
  return { label: index + 3, value: index + 3 }
})

function ChangLong() {
  const {
    instalment,
    setInstalment,
    status,
    setStatus,
    checkStatus,
    setCheckStatus,
    audioChecked,
    setAudioChecked,
    tableShowData,
    categorys,
    setCategorys,
    loading,
  } = useChanglong()

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className='changLongWrapper'>
      <div className=' content'>
        <div className=' commonContent leftContent '>
          {/* <div className='title'>{t('CHANG_LONG_REMINDER')}</div> */}
          <div className=' filterButtonArea'>
            <div
              onClick={() => {
                setCheckStatus('all')
                const tmp = categorys.map((item) => {
                  return {
                    ...item,
                    checked: true,
                    lotterys: (item.lotterys || []).map((i) => {
                      return { ...i, checked: true }
                    }),
                  }
                })
                setCategorys(tmp)
              }}
              className={`button ${checkStatus == 'all' && 'active'}`}
            >
              {t('CHECK_ALL')}
            </div>
            <div
              onClick={() => {
                setCheckStatus('clear')
                const tmp = categorys.map((item) => {
                  return {
                    ...item,
                    checked: false,
                    lotterys: (item.lotterys || []).map((i) => {
                      return { ...i, checked: false }
                    }),
                  }
                })
                setCategorys(tmp)
              }}
              className={`button ${checkStatus == 'clear' && 'active'}`}
            >
              {t('CLEAR')}
            </div>
          </div>

          <div className=' checkArea'>
            {categorys.map((item, index) => {
              return (
                <div key={item.value}>
                  <Checkbox
                    checked={item.checked}
                    className=' checkTitle'
                    onChange={() => {
                      // 也要控制子菜单
                      const newCategorys: typeof categorys = [
                        ...categorys.slice(0, index),
                        {
                          ...item,
                          checked: !item.checked,
                          lotterys: (item.lotterys || []).map((i) => ({
                            ...i,
                            checked: !item.checked,
                          })),
                        },
                        ...categorys.slice(index + 1),
                      ]
                      setCategorys(newCategorys)
                    }}
                  >
                    {item.label}
                  </Checkbox>
                  <div className=' checkItemArea'>
                    {(item.lotterys || []).map((lottery, lotteryIndex) => {
                      return (
                        <div className=' checkItemDiv' key={lottery.code}>
                          <Checkbox
                            checked={lottery.checked}
                            className=' checkItem'
                            onChange={() => {
                              // 也要影响主菜单是否该选中
                              const newLotterys = [
                                ...(item.lotterys || []).slice(0, lotteryIndex),
                                { ...lottery, checked: !lottery.checked },
                                ...(item.lotterys || []).slice(lotteryIndex + 1),
                              ]
                              const newCategorys: typeof categorys = [
                                ...categorys.slice(0, index),
                                {
                                  ...item,
                                  checked: newLotterys.every((i) => i.checked),
                                  lotterys: newLotterys,
                                },
                                ...categorys.slice(index + 1),
                              ]
                              setCategorys(newCategorys)
                            }}
                          >
                            {lottery.name}
                          </Checkbox>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='commonContent rightContent'>
          {/* <div className='title'>{t('CHANG_LONG_REMINDER')}</div> */}
          <div className='actionArea'>
            <div className=' filterButtonArea'>
              <div
                onClick={() => setStatus('open')}
                className={`button ${status == 'open' && 'active'}`}
              >
                {t('CONTINUOUS_OPEN')}
              </div>
              <div
                onClick={() => setStatus('close')}
                className={`button ${status == 'close' && 'active'}`}
              >
                {t('CONTINUOUS_UNOPENED')}
              </div>
            </div>
            <div className=' selectArea'>
              <div>
                {t('ONLY_SHOW')}
                <Select
                  value={instalment}
                  onChange={(v) => setInstalment(v)}
                  className=' select'
                  options={options}
                />
                {t('INSTALMENT_ABOVE')}
              </div>
              <div className=' audioCheck'>
                <Checkbox
                  checked={audioChecked}
                  onChange={(e) => setAudioChecked(e.target.checked)}
                >
                  {t('SOUND_ALARM')}
                </Checkbox>
              </div>
            </div>
          </div>
          <div className=' tableArea'>
            <div></div>
            <table>
              <thead>
                <tr>
                  <th>{t('LOTTERY')}</th>
                  <th>{t('LOCATION')}</th>
                  <th>{t('CONTINUOUS_OPEN_INSTALMENT')}</th>
                  <th>{t('LUZHU')}</th>
                  {/* 跳转到 彩票大厅 综合路珠 */}
                </tr>
              </thead>

              {(!loading && (
                <tbody>
                  {(tableShowData || []).map((item, index) => {
                    return (
                      <>
                        <tr key={item.lotteryName + index}>
                          <td>{item.lotteryName}</td>
                          <td>{item.location}</td>
                          <td>{item.count}期</td>
                          <td
                            className='luzhuChakan'
                            onClick={() => {
                              // 还需要设置submenu
                              navigate('/lottery')
                            }}
                          >
                            {t('CHECK')}
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              )) || <></>}

              {(loading && (
                <tbody>
                  {[...new Array(4)].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Skeleton.Button block active />
                        </td>
                        <td>
                          <Skeleton.Button block active />
                        </td>
                        <td>
                          <Skeleton.Button block active />
                        </td>
                        <td>
                          <Skeleton.Button block active />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              )) || <></>}
            </table>

            {(!loading && !tableShowData.length && <Empty className=' empty' />) || <></>}
            {/* {(loading && (
              <div className=' loadingArea'>
                <Spin className=' spinClassName' />
              </div>
            )) || <></>} */}
          </div>
        </div>
      </div>
    </div>
  )
}

function ChangLongWithConfigProvider() {
  const { theme = '' } = useAppSelector((state) => state.appReducers)
  const { i18n } = useTranslation()

  const themeMap = {
    red: { colorPrimary: '#E86262' },
    blue: { colorPrimary: '#5D66D3' },
    green: { colorPrimary: '#4A9F5E' },
    orange: { colorPrimary: '#E38533' },
  }

  return (
    <ConfigProvider
      locale={i18n.language === 'zh' ? zhCN : enUS}
      componentSize='large'
      theme={{
        token: {
          colorPrimary: themeMap[theme as keyof typeof themeMap].colorPrimary || '#E86262',
        },
      }}
    >
      <ChangLong />
    </ConfigProvider>
  )
}

export default ChangLongWithConfigProvider
