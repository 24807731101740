import './styles.scss';

import { connect } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import templates from '../../../constants/templates';
import { useEffect, useState } from 'react';
import { apiService } from '../../../services';
import { InputNumber, Select } from 'antd';


import type { ScriptableTooltipContext, ScriptableContext } from 'chart.js';
import {ILatestItem} from '../../../types/index'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ExternalTooltipHandler from '../../common/ExternalTooltipHandler';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const numberRange:{[key:string]:{min:number,max:number}} = {
  "PKS":{
    min:1,
    max:10
  },
  "YDH":{
    min:1,
    max:6
  },
  "SSC":{
    min:0,
    max:9
  },
  "KLSF":{
    min:1,
    max:20
  },
  "SYXW":{
    min:1,
    max:11
  }
}

interface toolTipProp{
  x:string,
  y:number,
  items:Array<ILatestItem>
}


const colors = ['#DBD40B', '#1111F2', '#3C342C', '#F4680C', '#18EDED', '#C181EF', '#758EB7', '#E8296F', '#781F1F', '#0F9393'];

function MobileNumberMissing() {
  const { t } = useTranslation();  
  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const [selectedTemplate, setSelectedTemplate] = useState<string>(templateAndGame.template)

  const [hotNumberData, setHotNumberData] = useState<{datasets:Array<{data:Array<{x:string,y:number}>}>}>({datasets:[{data:[{x:'',y:0}]}]});

  const [selectedNumber, setSelectedNumber] = useState<number>(1);
  const [installment, setInstallment] = useState(20);
  const [inputValue, setInputValue] = useState(20);

  const CustomTooltip = (obj:toolTipProp) => {  
    const type = templates[`${selectedTemplate}`].trendSubMenu?.find(x=>x.value === selectedNumber)?.label ?? ''
    return (
      <div className="p-2 flex whitespace-nowrap">
        <div>
          {`${type} ${obj.x}球`}
        </div>
        <div className=' text-red-500 ml-2'>{`${obj.y}`}</div>
        <div>期未开</div>
      </div>
    );
  };

  const options = {
    maintainAspectRatio:false,
    layout: {
      padding:0
    },
    elements: {
      bar: {
        backgroundColor: (ctx:ScriptableContext<'bar'>) => {     
          return colors[ctx.dataIndex % 10]
        },
      }
    },
    scales:{
      x:{
        ticks:{
          callback: function(value:number | string) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newthis = this as any
            return `${newthis.getLabelForValue(value)}球`;
          },
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: (context:ScriptableTooltipContext<'bar'>)=>ExternalTooltipHandler<'bar', toolTipProp>(context, CustomTooltip),
      },
      legend:{
        display:false
      }
    }
  };

  const getHistory = async () => {
    const range = numberRange[selectedTemplate]
    const lotteryCode = templateAndGame.code;
    const tempData:Array<{x:string,y:number,items:Array<{draw_code:string,issue:string}>}> = Array.from({length:range.max - range.min + 1},(v,k)=>k + range.min).map(item=>({x:String(item),y:0,items:[]}))
    try {
      const res = await apiService.get(`/lottery/history/${companyCode}/${lotteryCode}`)
      const result = res.data;
      const data:Array<{draw_code:string,issue:string}> = result.data.list ? result.data.list.slice(0,installment) : [];

      const dataSets = data.reverse();

      for(let i = 0;i < data.length;i++){
        const num =  Number(dataSets[i].draw_code.split(',')[selectedNumber - 1]);
        const temp = tempData.find(item=>item.x === String(num));
        if(temp){   
          for(let s = 0;s < tempData.length;s++){
            if(num === Number(tempData[s].x)){
              temp.y = 0;
            }
            else{
              temp.y = temp.y + 1;
            }
          }
        }
      }
      setHotNumberData({datasets:[{ data:tempData }]})       
    } catch (error) {
      // console.log(error)
    } finally {
      // console.log(error)
    }
  }

  useEffect(()=>{
    if(templateAndGame?.template && templates[selectedTemplate]){
      setSelectedTemplate(templateAndGame.template);
      if(templates[selectedTemplate].hotAndMissingNumber){
        setSelectedNumber((templates[selectedTemplate]?.hotAndMissingNumber as Array<{value:number}>)[0]?.value)
      }
    }
  },[templateAndGame])

  useEffect(()=>{
    if(templateAndGame?.code){
      getHistory()
    }
  },[installment,selectedNumber])
  
  return <div className='mobileNumberMissingWrapper'>
    <div className='text-[16px] font-bold'>
      {t('NUMBER_MISSING')}
    </div>
    <div className='flex items-center my-5'>
      <div className='font-bold mr-4' >
        {
          t('NUMBER_SELECT')
        }
      </div>
      <div className='flex flex-wrap flex-1'>
        <Select
          className='w-[90px]'
          value={selectedNumber}
          onChange={value=>setSelectedNumber(value)}
          options={
            templates[selectedTemplate].hotAndMissingNumber ?? []
          }
        >
        </Select>
      </div>    
    </div>
    <div className='flex items-center my-5'>
      <div className='font-bold mr-4'>{t('SHOW_INSTALLMENT')}</div>
      <div className='flex items-center'>
        <InputNumber className='mr-4'  min={1} value={inputValue} onChange={value=> setInputValue(value ?? 1)}  controls={false} />
        <div className='buttonWrapper selected cursor-pointer' onClick={()=>setInstallment(inputValue)}>{t('SETTING')}</div>
      </div>
    </div>
    <div className=' w-full relative flex justify-center h-[300px]'>
      {
        hotNumberData.datasets.length > 0 &&
          <Bar options={options} data={ {...hotNumberData}} />
      }
    </div>
  </div>
    
}

export default connect(null, null)(MobileNumberMissing);