import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {QRCodeSVG} from 'qrcode.react';

import Logo from '../../../assets/image/common/companyLogo.png';

import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import appActions from '../../../redux/actions/appActions';

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  setAboutTab: (type:string) => dispatch(appActions.setAboutTab(type)),
});

interface IProps{
  setAboutTab:(type:string)=>void;
}

function FooterLinks ({setAboutTab}:IProps){   
  const { t } = useTranslation();  
  const navigate = useNavigate();


  const linkMap = {
    'ABOUT_US':[{name:'ABOUT_US',path:'about'},{name:'SERVICE_CENTER',path:''},{name:'BUSINESS_COOPERATION',path:'about'},{name:'FREE_DUTY',path:'about'}],
    'FAST_LINK':[{name:'PLAN_RECOMMENDATION',path:''},{name:'DRAW_BUMBER',path:''},{name:'SOFTWARE_DOWNLOAD',path:'download'}],
    'FREE_CALL':[{name:'SELF_WEB_NAVIGATOR',path:''},{name:'DRAW_CALL',path:''}],
  }  

  const onLinkClick = (obj:{[key:string]:string})=>{
    if(obj.path === 'about'){
      setAboutTab(obj.name);
      return navigate('/about');
    }
    if(obj.path === 'download'){
      return navigate('/download');
    }
    if(obj.name === 'SELF_WEB_NAVIGATOR' || obj.name === 'DRAW_CALL'){
      return obj.name === 'DRAW_CALL' ? navigate('/apicall') : window.open('https://166kai.com/')
    }
    if(obj.name === 'PLAN_RECOMMENDATION' || obj.name === 'DRAW_BUMBER'){
      return obj.name === 'PLAN_RECOMMENDATION' ? navigate('/plan') : navigate('/')
    }
  }

  return ( 
    <div className='webFooterLinkWrapper w-full text-white flex'>
      <div className='logoName flex justify-center items-center' onClick={()=>window.open('https://166kai.com','_blank')}>
        <img src={Logo} alt="" srcSet=""  />
      </div>
      <div className='flex-1 flex justify-center'>
        {
          Object.entries(linkMap).map(([key, value])=> 
            <div className=' px-12 ' key={key}>
              <div className=' text-[24px]'>{t(key)}</div>
              {
                value.map(item=>  <div onClick={()=>onLinkClick(item)} key={item.name} className=' footLinkItem  mt-4 text-center'>{t(item.name)}</div> )
              }
            </div> 
          )
        }
        <div className=' px-12 '>
          <div className=' text-[24px]'>{t('ONLINE_SERVICE')}</div>
          <div className=' mt-4 '>平常日：9：30-凌晨02：00</div>
          <div className='w-full flex justify-center items-center pt-[20px]'>
            <QRCodeSVG 
              value={window.location.origin + '/download'} 
              bgColor='#ea7245'
              fgColor='#ffffff'
              size={80}
            />
          </div>
        </div> 
      </div>
    </div>

  );
}
export default connect(null, mapDispatchToProps)(FooterLinks);
