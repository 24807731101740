import './styles.scss'
import { ReactNode, useState } from 'react'
import { ConfigProvider, Tabs } from 'antd'
import { useAppSelector } from '../../../redux/hooks'
import Template0 from './templates/Template0'
import Template1 from './templates/Template1'
import Template2 from './templates/Template2'
import Template3 from './templates/Template3'
import Template4 from './templates/Template4'
import Template5 from './templates/Template5'
import Template6 from './templates/Template6'
import Template7 from './templates/Template7'
import Template8 from './templates/Template8'
import Template9 from './templates/Template9'
import Template10 from './templates/Template10'
import Template11 from './templates/Template11'
import Template12 from './templates/Template12'
import Template13 from './templates/Template13'
import Template14 from './templates/Template14'
import Template15 from './templates/Template15'
import Template16 from './templates/Template16'
import Template17 from './templates/Template17'

export const subMenuList: {
  label: string
  template?: ReactNode
  subTitle?: string
  menuCode: number
}[] = [
  { label: 'PK10', template: <Template0 /> },
  { label: '时时彩', template: <Template1 /> },
  { label: '快乐十分', template: <Template2 /> },
  { label: '快3', template: <Template3 /> },
  { label: '11选5', template: <Template4 /> },
  { label: '快乐8', template: <Template5 /> },
  { label: 'pc蛋蛋', template: <Template6 /> },
  { label: '超级大乐透', subTitle: '全国体育彩票“超级大乐透”玩法介绍', template: <Template7 /> },
  { label: '福彩3D', subTitle: '中国福利彩票3D游戏规则', template: <Template8 /> },
  { label: '福彩七乐彩', template: <Template9 /> },
  { label: '福彩双色球', subTitle: '中国福利彩票双色球游戏规则', template: <Template10 /> },
  { label: '体彩排列3', subTitle: '排列3游戏规则', template: <Template11 /> },
  { label: '体彩排列5', subTitle: '排列5游戏规则', template: <Template12 /> },
  {
    label: '体彩七星彩',
    subTitle: '联网销售7位数电脑体育彩票游戏规则',
    template: <Template13 />,
  },
  { label: 'PK10翻摊', subTitle: 'PK10翻摊游戏规则', template: <Template14 /> },
  { label: '时时彩番摊', subTitle: '时时彩番摊游戏规则', template: <Template15 /> },
  { label: '台湾宾果番摊', subTitle: '台湾宾果番摊游戏规则', template: <Template16 /> },
  { label: '台湾宾果', subTitle: '台湾宾果游戏规则', template: <Template17 /> },
].map((item, index) => ({ ...item, menuCode: index }))

function HelpCenter() {
  const { platform } = useAppSelector((state) => state.appReducers)
  type IMenuCode = number | string
  const [activeMenu, setActiveMenu] = useState<IMenuCode>(subMenuList[0].menuCode)

  const subMenu = subMenuList[+activeMenu]
  return (
    <div className={`helpCenterWrapper w-full ${platform}`}>
      <div className='  bg-white bg-transparent removeMargin'>
        <Tabs
          className=' border-0 border-none'
          activeKey={activeMenu.toString()}
          items={subMenuList.map((i, index) => ({
            key: index.toString(),
            label: <div className=' mx-2'>{i.label}</div>,
          }))}
          onChange={(v) => {
            setActiveMenu(v)
          }}
        />
      </div>

      <div className='helpCenterContent'>
        <div className='font-bold title'>帮助中心</div>
        <div className='font-bold subTitle primaryColor'>
          {subMenu.subTitle || `“${subMenu.label}” 系列游戏规则`}
        </div>
        <div>{subMenu?.template}</div>
      </div>
    </div>
  )
}

function HelpCenterWithConfigProvider() {
  const { theme = '' } = useAppSelector((state) => state.appReducers)

  const themeMap = {
    red: { colorPrimary: '#E86262' },
    blue: { colorPrimary: '#5D66D3' },
    green: { colorPrimary: '#4A9F5E' },
    orange: { colorPrimary: '#E38533' },
  }

  return (
    <ConfigProvider
      componentSize='large'
      theme={{
        token: {
          colorPrimary: themeMap[theme as keyof typeof themeMap].colorPrimary || '#E86262',
        },
      }}
    >
      <HelpCenter />
    </ConfigProvider>
  )
}
export default HelpCenterWithConfigProvider
