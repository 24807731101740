import { apiService } from '../../../services';
import { SET_LOTTERY_NEWS, SET_NOTICE, SET_LOTTERY_NEWS_ID } from '../../actionTypes';
import { AppDispatch, store } from '../../store';

const setLotteryNews = (value:[]) => ({
  type: SET_LOTTERY_NEWS,
  value
});

const setLotteryNewsId = (value:string) => ({
  type: SET_LOTTERY_NEWS_ID,
  value
});

const setNotice = (value:[]) => ({
  type: SET_NOTICE,
  value
});

const GetLotteryNews = (top:number) => (dispatch:AppDispatch) => {
  apiService.get(`/notice/lotterynews/${top}`).then(res => {
    const { data } = res || {};
    dispatch(setLotteryNews(data.data));
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetNotice = () => (dispatch:AppDispatch) => {
  const {companyCode} = store.getState().appReducers
  apiService.get(`/notice/${companyCode}`).then(res => {
    const { data } = res || {};
    dispatch(setNotice(data.data));
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  GetLotteryNews,
  GetNotice,
  setLotteryNewsId
};