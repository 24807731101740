import {ILatestItem} from '../../../types/index';
import { _sum } from '../../utils';

export const GenerateNumber = (obj:ILatestItem)=>{
  const _list:number[] = (obj.draw_code).split('|')[0].split(',').map(x=>Number(x));
  const sum = _list.slice(0,3).reduce((a,b)=>a + b,0);
  return _list.map((item, index)=>({value:`${item}`,className:`PKS_FT ${index < 3 ? "underLine" : index > 6 ? 'underLineGreen' : (index > 3 && index < 7) ? 'underLineRed' : ''}  _${item}`})).concat({value:'+',className:'flex justify-center items-center font-bold'},{value:`${sum}`,className:`PKS_FT sum `})
}

export const GenerateChannelFanTanBall = (obj:ILatestItem)=>{
  return (obj.draw_code.split('|')[0].split(',') as string[]).map((item,index)=>(
    <div key={index.toString()} className={`PKS_FT _${item}`}>{item}</div>
  ))
}

export const GenerateChannelFanTanResult = (obj:ILatestItem, index:string)=>{
  const _result = obj.draw_code.split('|')[1].split(',');
  const _dx = (obj.trend?.dx as string[]) ?? [];
  const _ds = (obj.trend?.ds as string[]) ?? [];
  const _index = Number(index);
  return [
    <div key={index} className={`h-full flex-1 flex justify-center items-center`}>{_result[_index]}</div>, 
    <div key={index + 'dx'} className={`h-full flex-1 flex justify-center items-center ${_dx[_index].includes('大') || _dx[_index].includes('单') ? 'text-red-400' : ''} `}>{_dx[_index]}</div>, 
    <div key={index + 'ds'} className={`h-full flex-1 flex justify-center items-center  ${_ds[_index].includes('大') || _ds[_index].includes('单') ? 'text-red-400' : ''} `}>{_ds[_index]}</div> ]
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string, isRndNum?:number)=>{
  const _balls = obj.draw_code.split('|')[0].split(',') as string[];
  const _result = obj.draw_code.split('|')[1].split(',');
  const _dx = (obj.trend?.dx as string[]) ?? [];
  const _ds = (obj.trend?.ds as string[]) ?? [];
  if(index === '0'){
    return _balls.map((item,index)=>(
      <div key={index.toString()} className={`PKS_FT _${item}`}>{item}</div>
    )).concat(isRndNum === 1 ? <div className='flex justify-center items-center'>+{obj.rnd}</div> : [])
  }
  let sum = 0;
  if(index === '1'){
    sum = _sum(_balls,0,3)
  }
  else if(index === '2'){
    sum = _sum(_balls,4,7)
  }else if(index === '3'){
    sum = _sum(_balls,7,10)
  }
  const _index = Number(index) - 1;
  return [
    <div key={index + 'sum'} className={`h-full flex-1 flex justify-center items-center`}>{sum}</div>, 
    <div key={index + 'result'} className={`h-full flex-1 flex justify-center items-center`}>{_result[_index]}</div>, 
    <div key={index + 'ds'}  className={`h-full flex-1 flex justify-center items-center ${_ds[_index].includes('大') || _ds[_index].includes('单') ? 'text-red-400' : ''} `}>{_ds[_index]}</div>, 
    <div key={index + 'dx'} className={`h-full flex-1 flex justify-center items-center  ${_dx[_index].includes('大') || _dx[_index].includes('单') ? 'text-red-400' : ''} `}>{_dx[_index]}</div> ]
}

//PKS_FT
export const generateFTResult = (obj:ILatestItem, index:number)=>{
  return [{value:(obj.draw_code.split('|')[1].split(',') as string[])[index], className:''}]
}

export const generateFTDX = (obj:ILatestItem, index:number)=>{
  return [{value:(obj.trend?.dx as string[])[index], className:''}]
}

export const generateFTDS = (obj:ILatestItem, index:number)=>{
  return [{value:(obj.trend?.ds as string[])[index], className:''}]
}


const PKS_FT = {
  GenerateNumber,
  homeTableHeader:[{title:'前',Colspan:3, className:'blue'},{title:'中',Colspan:3, className:'red'},{title:'后',Colspan:3, className:'green'}],
  homeTableRowItem:[
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTResult(obj,0)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDX(obj,0)
    },    
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDS(obj,0)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTResult(obj,1)
    },  
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDX(obj,1)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDS(obj,1)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTResult(obj,2)
    },    
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDX(obj,2)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDS(obj,2)
    }
  ],
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
    {label:'RULES',menuCode:'RULES'},
  ],
  //chart and killPlan
  H_V_NumberTrend:[
    {label:'冠亚和',value:0,rank:'冠亚和'},
    {label:'号码1',value:1,rank:'冠军'},
    {label:'号码2',value:2,rank:'亚军'},
    {label:'号码3',value:3,rank:'第三名'},
    {label:'号码4',value:4,rank:'第四名'},
    {label:'号码5',value:5,rank:'第五名'},
    {label:'号码6',value:6,rank:'第六名'},
    {label:'号码7',value:7,rank:'第七名'},
    {label:'号码8',value:8,rank:'第八名'},
    {label:'号码9',value:9,rank:'第九名'},
    {label:'号码10',value:10,rank:'第十名'}
  ],
  ChannelDrawResult:{
    web:{
      header:[[{label:'号码',value:'gyh'}],[{label:'前',value:'0'},{label:'中',value:'1'},{label:'后',value:'2'}]],
      rowProperty:[
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelFanTanBall(obj)}</div>
        },
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex cellWrapper'>{GenerateChannelFanTanResult(obj,index)}</div>
        }
      ]
    },
    mobile:{
      header:[
        {label:'号码',value:'0'},
        {label:'前',value:'1',mulitLabel:[{label:'总和',className:'flex-1'},{label:'番号',className:'flex-1'},{label:'单双',className:'flex-1'},{label:'大小',className:'flex-1'}]},
        {label:'中',value:'2',mulitLabel:[{label:'总和',className:'flex-1'},{label:'番号',className:'flex-1'},{label:'单双',className:'flex-1'},{label:'大小',className:'flex-1'}]},
        {label:'后',value:'3',mulitLabel:[{label:'总和',className:'flex-1'},{label:'番号',className:'flex-1'},{label:'单双',className:'flex-1'},{label:'大小',className:'flex-1'}]}],
      rowProperty:
        (obj:ILatestItem,index:string, isRndNum?:number)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index,isRndNum )}</div>
        }    
    }
  }
}

export default PKS_FT;