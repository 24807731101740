export default function Template13() {
  return (
    <div className=' sm:text-[18px] text-[14px] '>
      <div>
        <div className=' text-lg font-bold mb-4'>第一章 总 则</div>
        <div className=' section'>
          <div></div>
          <div>
            第一条
            根据财政部《彩票发行与销售管理暂行规定》和国家体育总局《体育彩票发行与销售管理暂行办法》以及《计算机销售体育彩票管理暂行办法》，制定本游戏规则。
          </div>
          <div>
            第二条
            “7位数”电脑体育彩票（以下简称“7位数”）由国家体育总局体育彩票管理中心统一发行，北京、天津、河北、内蒙、辽宁、吉林、黑龙江、上海、江苏、浙江、安徽、福建、江西、山东、河南、湖北、湖南、广东、广西、海南、四川、西藏、贵州、云南、重庆、陕西、山西、甘肃、青海、宁夏、新疆等31个省份的体育彩票管理中心（以下简称“联网31省份体育彩票管理中心”）在所辖区域内采用计算机网络系统进行联合销售。
          </div>
          <div>第三条 “7位数”实行自愿购买，凡购买该彩票者即被视为同意并遵守本规则。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第二章 游戏方法</div>

        <div className=' section'>
          <div></div>
          <div>
            第四条
            “七位数”的每注彩票由0000000-9999999中的任意7位自然数排列而成。购买“7位数”时，购买者从0000000-9999999的一千万组7位排列号码中选择一组确定排列的号码进行投注为单式投注。购买者从0—9共10个自然数中选择2—10个号码排列成多组7位号码的投注为复式投注。单张彩票复式最大投注数量限10000注。购买者可对其选定的结果进行多倍投注，投注倍数范围为2-99倍。
          </div>
          <div>
            第五条
            购买者可在联网31省份体育彩票管理中心设置的投注站进行投注。投注号码可由投注机随机产生，也可通过投注单将购买者选定的号码输入投注机确定。投注号码经系统确认后打印出的兑奖凭证即为“7位数”电脑体育彩票，交购买者保存。
          </div>
          <div>第六条 “7位数”每注2元人民币。彩票不记名、不挂失，不返还本金，不流通使用。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第三章 设 奖</div>
        <div className=' section'>
          <div></div>
          <div>第七条 “7星彩”共设六个奖级，一、二等奖为浮动奖，三、四、五、六等奖为固定奖。</div>
          <div>第八条 奖金分配：</div>
          <div>
            一等奖：奖金总额为当期奖金额减去固定奖总额后的90%，以及奖池资金和调节基金转入部分；
          </div>
          <div>二等奖：奖金总额为当期奖金额减去固定奖总额后的10%；</div>
          <div>三等奖：单注奖金固定为1800元；</div>
          <div>
            四等奖：单注奖金固定为300元； 五等奖：单注奖金固定为20元； 六等奖：单注奖金固定为5元。
          </div>
          <div>第九条 单注彩票中奖奖金最高限额500万元。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第四章 奖金管理</div>
        <div className=' section'>
          <div></div>
          <div>
            第十条
            按当期销售额的50%、15%、35%分别计提返奖奖金、彩票发行费和彩票公益金。返奖奖金分为当期奖金和调节基金，其中，49%为当期奖金，1%为调节基金。
          </div>
          <div>
            第十一条
            “7位数”设置奖池，奖池由未中出的浮动奖奖金和超出特等奖单注封顶限额部分的奖金组成。奖池与当期奖金中用于特等奖的部分及调节基金转入部分合并为特等奖奖金。
          </div>
          <div>
            第十二条
            当奖池资金超过8000万元(含)时，下期一等奖与二等奖奖金分配比例倒置，即：一等奖分配奖金为当期奖金额减去固定奖总额后的10%;二等奖分配奖金为当期奖金额减去固定奖总额后的90%。
          </div>
          <div>
            第十三条
            调节基金包括按销售总额的1%提取部分、浮动奖奖金取整后的余额、弃奖收入和逾期未退票的票款。调节基金专项用于支付各种不可预见情况下的奖金支出风险、调节浮动奖奖金以及设立特别奖。动用调节基金设立特别奖，应当报财政部审核批准。
          </div>
          <div>
            第十四条
            一、二等奖按照该奖级实际中奖注数平均分配该奖级奖金。当上一奖级单注奖金低于(或等于)下一奖级单注奖金时，上一奖级单注奖金补足至下一奖级单注奖金的二倍。所需资金从调节基金中支付，若调节基金不足时，用彩票发行费垫支。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第五章 中 奖 </div>
        <div className=' section'>
          <div></div>
          <div>第十五条 所购彩票与开奖结果对照，符合以下情况即为中奖。</div>
          <div>
            一等奖：单注彩票的7位数号码与中奖号码相同且排列一致，如中奖号码为1234567，所选单注彩票的号码也为1234567，即中一等奖；
          </div>
          <div>
            二等奖：单注彩票号码中连续6位数号码与中奖号码相同位置的连续6位数相同，如中奖号码为1234567，所选单注彩票的号码为123456X或X234567，即中二等奖；
          </div>
          <div>
            三等奖：单注彩票号码中连续5位数号码与中奖号码相同位置的连续5位数相同，如中奖号码为1234567，所选单注彩票的号码为12345XX、X23456X或XX34567，即中三等奖；
          </div>
          <div>
            四等奖：单注彩票号码中连续4位数号码与中奖号码相同位置的连续4位数相同，如中奖号码为1234567，所选单注彩票的号码为1234XXX、X2345XX、XX3456X或XXX4567，即中四等奖；
          </div>
          <div>
            五等奖：单注彩票号码中连续3位数号码与中奖号码相同位置的连续3位数相同，如中奖号码为1234567，所选单注彩票的号码为123XXXX、X234XXX、XX345XX、XXX456X或XXXX567，即中五等奖；
          </div>
          <div>
            六等奖：单注彩票号码中连续2位数号码与中奖号码相同位置的连续2位数相同，如中奖号码为1234567，所选单注彩票的号码为12XXXXX、X23XXXX、XX34XXX、XXX45XX、XXXX56X或XXXXX67，即中六等奖。
          </div>
          <div>第十六条 当期每注彩票只有一次中奖机会，不得兼中兼得，另行设立的特别奖除外。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'> 第六章 开奖及公告</div>
        <div className=' section'>
          <div></div>
          <div>第十七条 “7位数”每周开奖三次，摇奖过程在公证人员监督下进行，通过电视台播出。</div>
          <div>
            第十八条
            每期开奖后，国家体育总局体育彩票管理中心需将中奖号码、当期销售总额、各奖等中奖情况以及奖池资金余额等信息，通过新闻媒体向社会公布，并将开奖结果通知各销售终端。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第七章 兑 奖</div>
        <div className=' section'>
          <div></div>

          <div>
            第十九条 “7位数”兑奖当期有效。每期自开奖次日起60天为兑奖期，逾期未兑，视为弃奖。
          </div>
          <div>第二十条 兑奖机构有权查验中奖者的中奖彩票及有效身份证件，兑奖者应予配合。</div>
          <div>
            第二十一条 单注彩票中奖金额超过1万元的中奖者须依法缴纳个人所得税，由兑奖机构代扣代缴。
          </div>

          <div>第二十二条 凡伪造、涂改中奖彩票，冒领奖金者，送交司法机关追究法律责任。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第八章 附 则</div>
        <div className=' section'>
          <div>第二十三条 本游戏规则解释权属国家体育总局体育彩票管理中心。</div>
          <div>第二十四条 本游戏规则自下发之日起执行。</div>
        </div>
      </div>
    </div>
  )
}
