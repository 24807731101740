import './styles.scss'
import { useTranslation } from 'react-i18next'
import { DetailedHTMLProps, IframeHTMLAttributes, useEffect, useState } from 'react'
import {
  Button,
  ConfigProvider,
  Divider,
  Modal,
  ModalProps,
  Popover,
  Radio,
  Select,
  Skeleton,
} from 'antd'
import { useAppSelector } from '../../../redux/hooks'
import { ILotteryList } from '../../../types'
import { ChromePicker } from 'react-color'
import copy from 'copy-to-clipboard'

function DataCall({ className = '' }: { className?: string }) {
  const { t } = useTranslation()
  const data = [
    { question: t('DATA_CALL_Q1'), answer: t('DATA_CALL_A1') },
    { question: t('DATA_CALL_Q2'), answer: t('DATA_CALL_A2') },
    { question: t('DATA_CALL_Q3'), answer: t('DATA_CALL_A3') },
    { question: t('DATA_CALL_Q4'), answer: t('DATA_CALL_A4') },
  ]
  return (
    <div className={` px-8 py-6 bg-white rounded-[12px] w-full text-[#3B3B3B]' ${className} boxShadow`}>
      <div className=' font-bold mb-4'>{t('DATA_CALL_DESC')}</div>
      {data.map((item, index) => {
        return (
          <div key={index} className='mb-4 '>
            <div className=' mb-3'>{item.question}</div>
            <div className=' pl-[22px]'>{item.answer}</div>
          </div>
        )
      })}
    </div>
  )
}

function ZiXunCall({ className = '' }: { className?: string }) {
  const { t } = useTranslation()

  return (
    <div className={`px-8 py-6 bg-white rounded-[12px] w-full text-[#3B3B3B] ${className} boxShadow`}>
      <div className=' font-bold mb-4'>{t('INFO_STYLE')}</div>
      <div>{t('INFO_CONTENT')}</div>
    </div>
  )
}

function PickColor({ color, onChange }: { color?: string; onChange?: (v: string) => void }) {
  const [_color, setColor] = useState(color || '#ffffff')
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Popover
      onOpenChange={(v) => {
        setOpen(v)
        if (!v) {
          onChange?.(_color)
        }
      }}
      open={open}
      className=' p-0'
      trigger='click'
      content={
        <ChromePicker
          color={_color}
          onChangeComplete={(v) => {
            setColor(v.hex)
          }}
        />
      }
    >
      <div className=' flex justify-between items-center gap-4 cursor-pointer'>
        <div>{t('CUSTOME_BACKGROUND_COLOR')}</div>
        <div>
          <Button className=' button min-w-[100px]'>{_color}</Button>
        </div>
        <div className=' grid grid-cols-2 cursor-pointer'>
          <div className=' w-4 h-4 rounded-tl-[5px] bg-[#EE454B]'></div>
          <div className=' w-4 h-4 rounded-tr-[5px] bg-[#B4EFA2]'></div>
          <div className=' w-4 h-4 rounded-bl-[5px] bg-[#A3D3F7]'></div>
          <div className=' w-4 h-4 rounded-br-[5px] bg-[#FFEB80]'></div>
        </div>
      </div>
    </Popover>
  )
}
function IFrame({
  src,
  className = '',
  ...props
}: DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> & {
  loading?: boolean
  src?: string
}) {
  const [_loading, setLoading] = useState(true)

  return (
    <>
      {(_loading && <Skeleton active />) || <></>}
      <iframe
        {...props}
        className={`${className} ${(_loading && ' invisible') || ''}`} // 为了防止iframe加载时闪烁
        onLoad={() => setLoading(false)}
        src={src}
      />
    </>
  )
}

type ICategory = ILotteryList['categorys'][0] & { checked?: boolean }
type ILottery = ILotteryList['lotterys'][0] & { checked?: boolean }

function ApiCall() {
  const { t } = useTranslation()

  const subMenuList = [
    { label: t('INFO_CALL'), menuCode: 'APICALL_ZIXUN' },
    { label: t('BETTING_CALL'), menuCode: 'APICALL_TOUZHU' },
    { label: t('LOTTERY_HALL_CALL'), menuCode: 'APICALL_KAIJIANGDATING' },
  ] as const
  type IMenuCode = (typeof subMenuList)[number]['menuCode']

  const [activeMenu, setActiveMenu] = useState<IMenuCode>(subMenuList[0].menuCode)
  const { lotteryList } = useAppSelector((state) => state.lotteryReducers)
  const [categorys, setCategorys] = useState<ICategory[]>([])
  const [lotterys, setLotterys] = useState<ILottery[]>([])
  const [activeCategory, setActiveCategory] = useState<string | number>(categorys?.[0]?.value)
  const [showType, setShowType] = useState<'multiple' | 'single'>('multiple')
  const [color, setColor] = useState('#ffffff')
  const [copyContent, setCopyContent] = useState('')
  const [modal, setModal] = useState<ModalProps>({})

  useEffect(() => {
    setCategorys(
      ((lotteryList as ILotteryList)?.categorys || []).map((i) => ({ ...i, checked: false })),
    )
    setLotterys(
      ((lotteryList as ILotteryList)?.lotterys || []).map((i) => ({ ...i, checked: false })),
    )
    setActiveCategory(((lotteryList as ILotteryList)?.categorys || [])?.[0].value)
  }, [lotteryList])

  const lotteryOptions = lotterys
    .filter((i) => i.checked)
    .map((item) => ({ label: item.name, value: item.code }))

  const [selectedLottery, setSelectedLottery] = useState<string | number>(
    lotteryOptions?.[0]?.value,
  )

  useEffect(() => {
    const selectedLotteryExistInLotteryOptions = lotteryOptions?.find(
      (i) => i.value === selectedLottery,
    )
    if (!selectedLotteryExistInLotteryOptions) {
      setSelectedLottery(lotteryOptions?.[0]?.value)
      return
    }
    if (!selectedLottery) {
      setSelectedLottery(lotteryOptions?.[0]?.value)
    }
  }, [lotterys, selectedLottery])

  const totalCheckedLotterys = lotterys.filter((i) => i.checked)

  const selectedLotteryExistInLotteryOptions = lotteryOptions?.find(
    (i) => i.value === selectedLottery,
  )
  const lotteryUrlParam = `lottery=${
    totalCheckedLotterys?.length && lotteryOptions?.length && selectedLotteryExistInLotteryOptions
      ? totalCheckedLotterys.map((i) => i.code).join(',')
      : 'JISUSC'
  }&set=${
    lotteryOptions?.length && selectedLotteryExistInLotteryOptions ? selectedLottery : 'JISUSC'
  }`

  return (
    <div className='ApiCallWrapper'>
      {/* submenu 样式遵循，lotteryWrapper  */}
      <div className=' flex items-center border-b-[#B2B2B2] border-b-[1.5px] subMenuListRow my-[30px]'>
        {subMenuList.map((item, index) => {
          const selected = item.menuCode === activeMenu
          return (
            <div
              onClick={() => setActiveMenu(item.menuCode)}
              className={`menuItem  ${selected ? 'selected' : ''} `}
              key={index}
            >
              {t(item.label)}
            </div>
          )
        })}
      </div>

      <ZiXunCall className='mb-10' />

      <div className='px-8 py-6 bg-white rounded-[12px] w-full text-[#3B3B3B] mb-10 boxShadow'>
        <div className=' font-bold mb-4'>{t('CUSTOME_LOTTERY_STYLE')}</div>
        <div>
          <div className=' flex gap-10 mb-4'>
            <div className=' font-bold min-w-[180px]'>{t('CHOOSE_DISPLAY_TYPE')}</div>
            <div>
              <Radio.Group value={showType} onChange={(e) => setShowType(e.target.value)}>
                <Radio value='multiple'>{t('MULTIPLE_LOTTERY')}</Radio>
                <Radio value='single'>{t('SINGLE_LOTTERY')}</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className=' flex gap-10 mb-4'>
            <div className=' font-bold min-w-[180px]'>{t('CHOOSE_DISPLAY_LOTTERY')}</div>
            <div className=''>
              <div className=' flex items-center gap-2'>
                {categorys.map((item) => {
                  const checkedLotterysLen = lotterys.filter(
                    (i) => i.checked && i.category == item.value?.toString(),
                  ).length
                  return (
                    <Button
                      type={(activeCategory == item.value && 'primary') || 'default'}
                      className={` button ${(activeCategory == item.value && 'active') || ''} `}
                      onClick={() => setActiveCategory(item.value)}
                      key={item.value}
                    >
                      {item.label}
                      {(checkedLotterysLen && <span>({checkedLotterysLen})</span>) || <></>}
                    </Button>
                  )
                })}
              </div>
              <Divider />
              <div className=' flex items-center gap-2 flex-wrap'>
                {lotterys
                  .filter((i) => i?.category?.toString() === activeCategory?.toString())
                  .map((item) => {
                    return (
                      <Button
                        type={(item.checked && 'primary') || 'default'}
                        className={` button ${(item.checked && 'active') || ''} `}
                        key={item.code}
                        onClick={() => {
                          const lotteryIndex = lotterys.findIndex((i) => i.code == item.code)
                          if (showType == 'multiple') {
                            setLotterys([
                              ...lotterys.slice(0, lotteryIndex),
                              { ...item, checked: !item.checked },
                              ...lotterys.slice(lotteryIndex + 1),
                            ])
                          } else {
                            setLotterys(
                              lotterys.map((i) => ({
                                ...i,
                                checked: i.code == item.code ? true : false,
                              })),
                            )
                          }
                        }}
                      >
                        {item.name || ''}
                      </Button>
                    )
                  })}
                {(showType == 'multiple' && (
                  <>
                    <Button
                      type='text'
                      onClick={() => {
                        setLotterys(
                          lotterys.map((i) => ({
                            ...i,
                            checked: i.category == activeCategory ? true : i.checked,
                          })),
                        )
                      }}
                    >
                      {t('CHECK_ALL')}
                    </Button>
                    <Button
                      className=' bg-[inherit]'
                      type='text'
                      onClick={() =>
                        setLotterys(
                          lotterys.map((i) => ({
                            ...i,
                            checked: i.category == activeCategory ? false : i.checked,
                          })),
                        )
                      }
                    >
                      {t('CLEAR')}
                    </Button>
                  </>
                )) || <></>}
              </div>
            </div>
          </div>

          {(activeMenu != 'APICALL_KAIJIANGDATING' && (
            <div className=' flex gap-10 mb-10'>
              <div className=' font-bold min-w-[180px]'>{t('CHOOSE_DISPLAY_LOTTERY')}</div>
              <div>
                <Select
                  className=' min-w-[120px]'
                  options={lotteryOptions}
                  value={lotteryOptions?.length ? selectedLottery : undefined}
                  onChange={(v) => setSelectedLottery(v)}
                />
              </div>
            </div>
          )) || <></>}

          {(activeMenu == 'APICALL_ZIXUN' && (
            <>
              <div className=' mb-8'>
                <div className=' font-bold min-w-max mb-6'>
                  {t('CUSTOME_STYLE_PREVIEW')}
                </div>
                <div className='py-3 px-6 border-[1px] border-solid border-[#070707]'>
                  <IFrame
                    scrolling='no'
                    className=' w-full h-10'
                    src={`https://link.kai8.us/DiaoYong/KaiJiang?${lotteryUrlParam}`}
                  />
                </div>
                <div className=' flex justify-end mt-[30px]'>
                  <Button
                    className='flex button active'
                    type='primary'
                    onClick={() => {
                      setModal({ ...modal, open: true })
                      const src = `https://link.kai8.us/DiaoYong/KaiJiang?${lotteryUrlParam}`
                      setCopyContent(
                        `<iframe scrolling='no' width="100%" height="40px" src="${src}" />`,
                      )
                    }}
                  >
                    {t('CLICK_TO_GET_CODE')}
                  </Button>
                </div>
              </div>
              <div>
                <div className=' flex justify-between items-center mb-6'>
                  <div className=' font-bold min-w-max '>
                    {t('CUSTOME_STYLE_PREVIEW_WITH_BG')}
                  </div>
                  <PickColor color={color} onChange={setColor} />
                </div>
                <div
                  style={{ backgroundColor: color }}
                  className={` py-3 px-6 border-[1px] border-solid border-[#070707]`}
                >
                  <IFrame
                    scrolling='no'
                    className=' w-full h-10'
                    src={`https://link.kai8.us/DiaoYong/KaiJiang?${lotteryUrlParam}&bgColor=${color.slice(
                      1,
                    )}`} // 去掉#号
                  />
                </div>
                <div className=' flex justify-end mt-[30px]'>
                  <Button
                    className='flex button active'
                    type='primary'
                    onClick={() => {
                      setModal({ ...modal, open: true })
                      const src = `https://link.kai8.us/DiaoYong/KaiJiang?${lotteryUrlParam}&bgColor=${color.slice(
                        1,
                      )}`
                      setCopyContent(
                        `<iframe scrolling='no' width="100%" height="40px" src="${src}" />`,
                      )
                    }}
                  >
                    {t('CLICK_TO_GET_CODE')}
                  </Button>
                </div>
              </div>
            </>
          )) || <></>}

          {(activeMenu == 'APICALL_TOUZHU' && (
            <div>
              <div className=' font-bold min-w-max mb-6'>{t('CUSTOME_STYLE_PREVIEW')}</div>
              <div className='h-[360px] max-h-[360px]  py-3 px-6 border-[1px] border-solid border-[#070707]'>
                <IFrame
                  scrolling='auto'
                  className=' w-full h-full'
                  src={`https://link.kai8.us/DiaoYong/TouZhuFrame?${lotteryUrlParam}`}
                />
              </div>
              <div className=' flex justify-end mt-[30px]'>
                <Button
                  className='flex button active'
                  type='primary'
                  onClick={() => {
                    setModal({ ...modal, open: true })
                    const src = `https://link.kai8.us/DiaoYong/TouZhuFrame?${lotteryUrlParam}`
                    setCopyContent(
                      `<iframe scrolling='auto' width="100%" height="100%" src="${src}" />`,
                    )
                  }}
                >
                  {t('CLICK_TO_GET_CODE')}
                </Button>
              </div>
            </div>
          )) || <></>}

          {(activeMenu == 'APICALL_KAIJIANGDATING' && (
            <div>
              <div className=' font-bold min-w-max mb-6'>{t('CUSTOME_STYLE_PREVIEW')}</div>
              <div className='h-[360px] max-h-[360px]  py-3 px-6 border-[1px] border-solid border-[#070707]'>
                <IFrame
                  scrolling='auto'
                  className=' w-full h-full'
                  src={`https://link.kai8.us/DiaoYong/HallFrame?${lotteryUrlParam}`}
                />
              </div>
              <div className=' flex justify-end mt-[30px]'>
                <Button
                  className='flex button active'
                  type='primary'
                  onClick={() => {
                    setModal({ ...modal, open: true })
                    const src = `https://link.kai8.us/DiaoYong/HallFrame?${lotteryUrlParam}`
                    setCopyContent(
                      `<iframe scrolling='auto' width="100%" height="100%" src="${src}" />`,
                    )
                  }}
                >
                  {t('CLICK_TO_GET_CODE')}
                </Button>
              </div>
            </div>
          )) || <></>}
        </div>
      </div>

      <DataCall />

      <Modal
        {...modal}
        onCancel={() => setModal({ ...modal, open: false })}
        getContainer={false}
        centered
        footer={false}
        className=' '
      >
        <>
          <div className=' mb-10 font-bold text-center'>
            <div>{t('GET_CODE_SUCCESS')}，</div>
            <div>{t('PLEASE_COPY')}</div>
          </div>
          <div className='mb-10 p-4 border-[1px] border-solid border-[#722E2E] rounded-[8px]'>
            <span>{copyContent}</span>
          </div>
          <div className=' flex items-center justify-center'>
            <Button
              className=' button'
              type='primary'
              onClick={() => {
                copy(copyContent)
                // Modal.success({ content: 'Copy Success!', centered: true, footer: false })
                setModal({ ...modal, open: false })
              }}
            >
              {t('COPY')}
            </Button>
          </div>
        </>
      </Modal>
    </div>
  )
}

function ApiCallWithConfigProvider() {
  const { theme = '' } = useAppSelector((state) => state.appReducers)

  const themeMap = {
    red: { colorPrimary: '#E86262' },
    blue: { colorPrimary: '#5D66D3' },
    green: { colorPrimary: '#4A9F5E' },
    orange: { colorPrimary: '#E38533' },
  }

  return (
    <ConfigProvider
      componentSize='large'
      theme={{
        token: {
          colorPrimary: themeMap[theme as keyof typeof themeMap].colorPrimary || '#E86262',
        },
      }}
    >
      <ApiCall />
    </ConfigProvider>
  )
}
export default ApiCallWithConfigProvider
