import { SET_ABOUT_TAB, SET_THEME, SET_PLATFORM, SET_SUB_SELECTION, SET_TEMPLATE_AND_CODE, SET_BANNER_SHOW } from '../../actionTypes';

const setTheme = (value:string) => ({
  type: SET_THEME,
  value
});

const setPlatForm = (value:string) => ({
  type: SET_PLATFORM,
  value
});

const setSubSelection = (value:string) => ({
  type: SET_SUB_SELECTION,
  value
});

const setAboutTab = (value:string) => ({
  type: SET_ABOUT_TAB,
  value
});

const setTemplateAndCode = (value:{template:string,code:string}) => ({
  type: SET_TEMPLATE_AND_CODE,
  value
});

const setBannerShow = (value:boolean) => ({
  type: SET_BANNER_SHOW,
  value
});

export default {
  setTheme,
  setPlatForm,
  setSubSelection,
  setTemplateAndCode,
  setAboutTab,
  setBannerShow
};