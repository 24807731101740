import demoActions from '../../../redux/actions/demoActions'
import { AppDispatch, RootState } from '../../../redux/store'
import { connect } from 'react-redux'

import './styles.scss'
import { useAppSelector } from '../../../redux/hooks'
import { useEffect, useMemo, useState } from 'react'
import noticeActions from '../../../redux/actions/noticeActions'
import { apiService } from '../../../services'
import { ConfigProvider, Pagination, PaginationProps, Skeleton } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import enUS from 'antd/locale/en_US'
import zhCN from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'

const mapStateToProps = (state: RootState) => ({
  apiData: state.demoReducers.apiData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
  setLotteryNewsId: (id: string) => dispatch(noticeActions.setLotteryNewsId(id)),
})

interface IProps {
  GetList: () => void
  setLotteryNewsId: (id: string) => void
}

interface INew {
  id: string
  title: string
  createTime: string
  content: string
  click: number
  articleContentCut: string
  isTop?: boolean
}
function News({ setLotteryNewsId }: IProps) {
  const { lotteryNewsId } = useAppSelector((state) => state.noticeReducers)

  const [selectedItem, setSelectedItem] = useState<Partial<INew>>({}) // 资讯详情

  const [data, setData] = useState<Partial<INew>[]>([]) // 资讯列表

  const [loading, setLoading] = useState(false)

  // const [top, setTop] = useState(10)

  const [pagination, setPagination] = useState<PaginationProps>({
    pageSize: 10,
    current: 1,
    showSizeChanger: false,
  })

  const getNoticeDetail = async (id: string) => {
    try {
      setLoading(true)
      const res = await apiService.get(`/notice/newdetail/${id}`)
      setSelectedItem(res.data?.data || {})
    } catch (error) {
      // console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }

  const getNews = async () => {
    const top = 9999
    try {
      setLoading(true)
      const res = await apiService.get(`/notice/lotterynews/${top}`)
      setData(res?.data?.data || [])

      setPagination({ ...pagination, total: (res?.data?.data || []).length })
    } catch (error) {
      // console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }

  useEffect(() => {
    if (lotteryNewsId) {
      getNoticeDetail(lotteryNewsId)
    } else {
      getNews()
    }
  }, [lotteryNewsId])

  useEffect(() => {
    return () => setLotteryNewsId('')
  }, [])

  const { current = 1, pageSize = 10 } = pagination
  const showData = useMemo(
    () => data.slice((current - 1) * pageSize, current * pageSize),
    [current, pageSize, data],
  )
  const { t } = useTranslation()

  return (
    <div className='NewsWrapper '>
      {(lotteryNewsId && (
        <>
          <div>
            <div
              className=' hidden sm:block  py-14 cursor-pointer '
              onClick={() => setLotteryNewsId('')}
            >
              <div className=' flex items-center gap-2'>
                <div className=' flex items-center gap-1 primaryColor'>
                  <CaretLeftOutlined />
                  <div>{t('BACK')}</div>
                </div>
                <div>{t('NEWS_INFO')}</div>
              </div>
            </div>

            <div className={` px-[24px] py-[36px] bg-white rounded-[12px] w-full text-[#3B3B3B] boxShadow`}>
              <>
                {(loading && <Skeleton active />) || (
                  <>
                    <div className='text-[24px] font-bold mb-4'>{selectedItem?.title}</div>

                    {/* 概括信息 */}
                    <div className=' flex items-center gap-8 mb-4 sm:mb-8'>
                      <div>{selectedItem?.createTime}</div>
                      <div>
                        {t('READ')}：<span className=' primaryColor'>{selectedItem?.click}</span>
                      </div>
                    </div>

                    {/* 内容 */}
                    <div className=' leading-[2] indent-[40px]'>{selectedItem?.content}</div>
                  </>
                )}
              </>
            </div>
          </div>
        </>
      )) || <></>}

      {/* 新闻资讯列表 */}
      {(!lotteryNewsId && (
        <div className={` px-[24px] py-[36px] bg-white rounded-[12px] w-full text-[#3B3B3B] boxShadow`}>
          <div className=' text-[24px] font-bold mb-8'>{t('NEWS_INFO')}</div>

          {(loading && (
            <>
              <Skeleton className=' mb-10' active />
              <Skeleton active />
            </>
          )) || (
            <div>
              {showData?.map((item) => {
                return (
                  <>
                    <div className=' flex items-center gap-4 sm:gap-10 mb-4 '>
                      <div className=' opacity-50 min-w-max'>
                        {/* {item?.createTime?.split(' ')?.[0] || ''} */}
                        {dayjs(item.createTime).format('YYYY年 M月 D号')}
                        {/* 转换成中文的 */}
                      </div>
                      <div className=' flex items-center gap-1 flex-1'>
                        <div
                          className=' cursor-pointer hover:underline '
                          onClick={() => setLotteryNewsId(item.id || '')}
                        >
                          {item.title}
                        </div>
                        <CaretRightOutlined />
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          )}

          {(!loading && (
            <div className=' flex items-center justify-between flex-wrap'>
              <div className='min-w-max'>
                {t('TOTAL')}：
                <span className=' primaryColor'>
                  {data.length} {t('RESULTS')}
                </span>
              </div>
              <Pagination
                showQuickJumper
                className=' min-w-max'
                {...pagination}
                onChange={(page) => setPagination({ ...pagination, current: page })}
              />
            </div>
          )) || <></>}
        </div>
      )) || <></>}
    </div>
  )
}

function NewsWithConfigProvider({ ...props }: IProps) {
  const { theme = '' } = useAppSelector((state) => state.appReducers)
  const { i18n } = useTranslation()

  const themeMap = {
    red: { colorPrimary: '#E86262' },
    blue: { colorPrimary: '#5D66D3' },
    green: { colorPrimary: '#4A9F5E' },
    orange: { colorPrimary: '#E38533' },
  }

  return (
    <ConfigProvider
      locale={i18n.language === 'zh' ? zhCN : enUS}
      componentSize='large'
      theme={{
        token: {
          colorPrimary: themeMap[theme as keyof typeof themeMap].colorPrimary || '#E86262',
        },
      }}
    >
      <News {...props} />
    </ConfigProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsWithConfigProvider)
