import './styles.scss';

import { connect } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import templates from '../../../constants/templates';
import { useEffect, useRef, useState } from 'react';
import { apiService } from '../../../services';

import dayjs from 'dayjs';
import Table from '../../common/Table';
import { IShuBanItem } from '../../../types';
import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';


function VerticalNumberTrend() {

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const { t } = useTranslation();  
  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const [selectedTemplate, setSelectedTemplate] = useState<string>(templateAndGame.template)

  const [trendData, setTrendData] = useState<IShuBanItem[]>();
  const [ball , setSelectedBall] = useState<number>(1);
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    if (!ref.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      setHeight(ref.current?.clientHeight ?? 0);
      setWidth(ref.current?.clientWidth ?? 0)      
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  const getShuBanTrend = async () => {
    const lotteryCode = templateAndGame.code;
    try {
      const res = await apiService.get(`/trend/ShuBanHaoMaZS/${companyCode}/${lotteryCode}/${date.format('YYYY-MM-DD')}/${ball}/60`)      
      setTrendData(res.data.data.list)          
    } catch (error) {
      setTrendData(undefined)
      // console.log(error)
    }
  }

  useEffect(()=>{
    if(templateAndGame?.template && templates[selectedTemplate]){
      setSelectedTemplate(templateAndGame.template);
      if(templates[selectedTemplate].H_V_NumberTrend){
        setSelectedBall((templates[selectedTemplate]?.H_V_NumberTrend as Array<{value:number}>)[0]?.value)
      }
    }
  },[templateAndGame])

  useEffect(()=>{
    setTrendData(undefined)
    if(templateAndGame?.code){
      getShuBanTrend()
    }
  },[ball,date])  

  const draw = (canvas:HTMLCanvasElement,temp:HTMLCollectionOf<HTMLDivElement>) =>{
    const context = canvas.getContext("2d");
    if(context){      
      context?.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
      context.lineWidth = 2;
      context.strokeStyle = "#FFC400";

      for (let index = 0; index < temp.length - 1; index++) {
        const element = temp.item(index);
        const elementNext = temp.item(index + 1);      
        const start = {
          x: (element!).offsetLeft + (element?.offsetParent as HTMLTableCellElement)?.offsetLeft,
          y:(element!).offsetTop + (element?.offsetParent as HTMLTableCellElement)?.offsetTop
        };
        const moveTo = {
          x: (elementNext!).offsetLeft + (elementNext?.offsetParent as HTMLTableCellElement)?.offsetLeft,
          y:(elementNext!).offsetTop + (elementNext?.offsetParent as HTMLTableCellElement)?.offsetTop
        }      
        context.moveTo(start.x + 12.5,start.y + 25);
        context.lineTo(moveTo.x + 12.5,moveTo.y);
        context.stroke();      
      }
    }
  }

  useEffect(()=>{
    const temp  = document.getElementsByClassName('ball');
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    draw(canvas,temp as HTMLCollectionOf<HTMLDivElement>)
  },[width, height,trendData])  

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setTrendData(undefined);
    setDate(dayjs(dateString))
  };
  
  return <div className='VerticalNumberTrendWrapper'>
    <div className='flex justify-between items-center'>
      <div className=' flex items-center'>
        <div className=' flex'>
          {[
            { label: '今天', value: dayjs() },
            { label: '昨天', value: dayjs().subtract(1, 'day') },
            { label: '前天', value: dayjs().subtract(2, 'day')},
          ].map((item) => {
            return (
              <div
                onClick={() => setDate(item.value)}
                className={` buttonWrapper mr-[16px] ${
                  (item.value.format('YYYY-MM-DD') === date.format('YYYY-MM-DD') && 'selected') || ''
                }`}
                key={item.label}
              >
                {item.label}
              </div>
            )
          })}
        </div>
        <DatePicker
          allowClear={false}
          disabledDate={disabledDate}
          className='h-[39px]'
          defaultValue={dayjs()}
          value={date}
          showToday={false}
          onChange={onChange}
        />
      </div>
    </div>
    <div className='flex items-center my-5'>
      <div className='font-bold mr-4' >
        {
          t('NUMBER_SELECT')
        }
      </div>
      <div className='flex flex-wrap flex-1'>
        {
          templates[selectedTemplate] && templates[selectedTemplate].H_V_NumberTrend?.map(item=> 
            <div 
              onClick={()=>setSelectedBall(item.value)}
              className={`buttonWrapper ${ball == item.value ? 'selected' : ''}`}
              key={item.label}>
              {item.label}
            </div> )
        }
      </div>    
    </div>
    <div className='relative' ref={ref}>
      <Table
        key={`${selectedTemplate}-${ball}`}
        header = {(templates[selectedTemplate].ShuBanHaoMaTrend!).web.header(ball)}
        contentProperty = {(templates[selectedTemplate].ShuBanHaoMaTrend!).web.rowProperty(ball).map(Func=> item=> Func((item as IShuBanItem)) )}
        contentData = {trendData ? trendData.reverse() : []}
        loading = {trendData ? false : true}
      />
      <canvas id="canvas" width={width} height={height} className='absolute top-0'></canvas>
    </div>

  </div>
    
}

export default connect(null, null)(VerticalNumberTrend);