import './styles.scss';

import { renderToStaticMarkup } from 'react-dom/server';

import type { ChartTypeRegistry, ScriptableTooltipContext } from 'chart.js';

function  ExternalTooltipHandler<Type extends keyof ChartTypeRegistry, TooltipType>(context: ScriptableTooltipContext<Type>, CustomTooltip:(data:TooltipType)=>JSX.Element){
  const {chart, tooltip} = context;
    
  if(chart.canvas.parentNode){
    let tooltipEl:HTMLElement | null = chart.canvas.parentNode.querySelector('#trendTooltipContainer');
    // Hide if no tooltip
    if (tooltip.opacity === 0 && tooltipEl) {
      tooltipEl.style.opacity = '0';
      return;
    }

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'trendTooltipContainer'
    }
    const table = document.createElement('div');
    table.innerHTML = (tooltip?.dataPoints && tooltip?.dataPoints[0]) ? renderToStaticMarkup(CustomTooltip((tooltip.dataPoints[0].raw as TooltipType))) : '';

    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }   
    tooltipEl.appendChild(table); 
    const {offsetLeft: positionX, offsetTop: positionY,offsetWidth} = chart.canvas;
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = (positionX + tooltip.caretX) + 'px';
    tooltipEl.style.transform = `translate(-${Math.floor((tooltip.caretX / offsetWidth) * 100)}%, -100%)`;
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.padding = tooltip.options.padding + 'px ';
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
}

export default ExternalTooltipHandler;