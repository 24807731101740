import './styles.scss'
import BannerIcon from '../../../assets/banner_mobile_icon.png'
import DownloadIcon from '../../../assets/mobile/image/download.png'
import '../../../fonts/YouSheBiaoTiHei/YouSheBiaoTiHei.ttf'

const MobileBanner = () => {
  return (
    <div className='mobileDownloadBannerWrapper w-full flex items-center  text-white'>
      <div className=''>
        <img src={BannerIcon} alt='banner icon' className='h-10' />
      </div>
      <div className='flex flex-1 flex-col ml-2 mb-1'>
        <div className='text-lg bannerTitle'>166开奖网</div>
        <div className='text-xs bannerContent'>最热门的高频彩开奖一键投注</div>
      </div>
      <a className='flex rounded-full bg-gradient-to-r from-[#fa5559] to-[#e42c32] px-2 py-1 cursor-pointer' href='/download' >
        <img src={DownloadIcon} alt='download icon' className='mr-1' />
        APP下载
      </a>
    </div>
  )
}

export default MobileBanner