import demoActions from '../../../redux/actions/demoActions'
import { AppDispatch, RootState } from '../../../redux/store'
import { connect } from 'react-redux'

import './styles.scss'
import MobileHomeDrawItem from '../../../components/Mobile/MobileHomeDrawItem'
import { useState, useEffect } from 'react'
import { ILatestItem, ILotteryList } from '../../../types'
import { apiService } from '../../../services'
import { useAppSelector } from '../../../redux/hooks'
import templates from '../../../constants/templates'
import { useTranslation } from 'react-i18next'
import { LuZhuSubSelectionsList, mapLuzhuProps } from '../../../components/Web/LuZhu'
import MobileLuZhu from '../../../components/Mobile/LuZhu'
import { AnimatePresence, motion } from 'framer-motion';
import MobileHotNumber from '../../../components/Mobile/MobileHotNumber'
import MobileNumberMissing from '../../../components/Mobile/MobileNumberMissing'
import MobileHorizontalNumberTrend from '../../../components/Mobile/MobileHorizontalNumberTrend'
import Rules from '../../../components/Web/Rules'
import MobileChannelHome from '../../../components/Mobile/MobileChannelHome'
import MobileVerticalNumberTrend from '../../../components/Mobile/MobileVerticalNumberTrend'

const mapStateToProps = (state: RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
})

interface IProps {
  lotteryListData: ILotteryList
}

function MobileLottery({ lotteryListData }: IProps) {
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const { t } = useTranslation()

  const [item, setItem] = useState<ILatestItem>()

  const [clockTimer, setClockTimer] = useState<string>(
    new Date().toString()
  )

  const { templateAndGame } = useAppSelector((state) => state.appReducers)

  const [subSelection, setSubSelection] = useState('');
  
  const getLatestResult = async ({ lotteryCode }: { lotteryCode: string }) => {
    try {
      const res = await apiService.get(`/lottery/issue/${companyCode}/${lotteryCode}`)
      const data = res?.data?.data
      const temp = lotteryListData.lotterys.find((x) => x.code === lotteryCode)
      if (temp && data) {
        setItem({
          ...data,
          category: temp.category,
          name: temp.name,
          openCount: temp.openCount ?? 0,
          template: temp.template,
          group: temp.group
        })
      }
    } catch (error) {
      setItem(undefined)
    }
  }

  useEffect(() => {
    getLatestResult({ lotteryCode: templateAndGame?.code })
    const clockId = setInterval((): void => {
      setClockTimer(
        new Date().toString()
      )
    }, 1000)
    const _timerId = setInterval((): void => {
      getLatestResult({ lotteryCode: templateAndGame?.code })
    }, 5000);
    if (
      templateAndGame?.template &&
      templates[templateAndGame.template] &&
      templates[templateAndGame.template]['subMenuList']
    ) {
      const arr = (templates[templateAndGame.template]['subMenuList'] as { menuCode: string }[])      
      if(subSelection === '' || (subSelection && !arr.find(x=>x.menuCode === subSelection))){
        setSubSelection(arr[0].menuCode)
      }
    }
    return () => {
      clearInterval(clockId);
      clearInterval(_timerId)
    }
  }, [templateAndGame])  

  return (
    <div className='mobileLotteryWrapper'>
      <div className=' my-[10px]'>
        {item && 
        <MobileHomeDrawItem clickable={false} item={item as ILatestItem} current={clockTimer} target={item?.next_time ?? ''}  />
        }

      </div>
      {templates[`${templateAndGame.template}`]?.subMenuList && (
        <div className='mobileLotteryCategoryRow flex h-[40px] bg-white'>
          {(
            templates[`${templateAndGame.template}`].subMenuList as { [key: string]: string }[]
          ).map((item, index) => (
            <div
              onClick={() => setSubSelection(item.menuCode)}
              className={` mx-2 ${item.menuCode === subSelection ? 'selected' : ''}`}
              key={index}
            >
              {t(item.label)}
            </div>
          ))}
        </div>
      )}
      <AnimatePresence mode='wait'>
        <motion.div
          key={subSelection}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 0.5,
          }}
        >
          {
            LuZhuSubSelectionsList.includes(subSelection) ? (
              <div className=' py-4'>
                <MobileLuZhu
                  {...mapLuzhuProps(templateAndGame.template)?.find(
                    (i) => i.subSelection == subSelection,
                  )}
                  title={t(subSelection)}
                  lotteryCode={templateAndGame.code}
                />
              </div>
            ) 
              :
              <> 
                {
                  subSelection === 'CHANNEL_HOME' && <MobileChannelHome />
                }  
                {
                  subSelection === 'H_NUMBER_TREND' && <MobileHorizontalNumberTrend />
                }  
                {
                  subSelection === 'V_NUMBER_TREND' && <MobileVerticalNumberTrend />
                }  
                {
                  subSelection === 'HOT_NUMBER' && <MobileHotNumber />
                }  
                {
                  subSelection === 'NUMBER_MISSING' && <MobileNumberMissing />
                }   
                {
                  subSelection === 'RULES' && <Rules />
                } 
              </>
          }
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileLottery)
