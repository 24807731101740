export default function Template9() {
  return (
    <div className=' sm:text-[18px] text-[14px] '>
      <div>
        <div className=' text-lg font-bold mb-4'>第一章 总 则</div>
        <div className=' section'>
          <div></div>
          <div>本规则依据《彩票发行与销售管理暂行规定》（财综[2002]13号）等制度制定。</div>
          <div>
            全国联合销售30选7电脑福利彩票（以下称“七乐彩”）由中国福利彩票发行管理中心（以下称“中福彩中心”）统一组织发行，由各省、自治区、直辖市福利彩票发行中心（以下称“省中心”）在本行政区域内联合销售。
          </div>
          <div>
            七乐彩采用计算机网络系统销售，采取参加联合销售的各省中心合并奖池，统一计奖，统一开奖。
          </div>
          <div>七乐彩实行自愿购买，凡购买者均被视为同意并遵守本规则。</div>
          <div>不得向未成年人销售彩票或兑付奖金。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第二章 投注</div>

        <div className=' section'>
          <div></div>
          <div>
            七乐彩采用组合式玩法，从01—30共30个号码中选择7个号码组合为一注投注号码。每注金额人民币2元。
          </div>
          <div>七乐彩每周销售三期，期号以开奖日界定，按日历年度编排。</div>
          <div>
            七乐彩投注方法分为自选号码投注和机选号码投注，投注方式分为单式投注、复式投注、胆拖投注和多倍投注。
          </div>
          <div>
            自选号码投注是指由投注者自行选定投注号码的投注；机选号码投注是指由投注机为投注者随机产生投注号码的投注。
          </div>
          <div>
            单式投注是从30个号码中选择7个号码，组合为一注投注号码的投注；复式投注是从30个号码中选择8－16个号码，将每7个号码的组合方式都作为一注投注号码的多注投注；胆拖投注是在30个号码中选择1至6个号码作为每注都有的胆码，再补充其它不同的号码，进行每7个号码为一组的多注投注；多倍投注是指同样的投注号码进行多注投注。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第三章 设奖</div>
        <div className=' section'>
          <div></div>
          <div>
            七乐彩设奖奖金为销售总额的50%，其中当期奖金为销售总额的49%，调节基金为销售总额的1%。
          </div>
          <div>
            七乐彩当期奖金设七个奖等，一至三等奖为高奖等，四至七等奖为低奖等。高奖等采用浮动设奖，低奖等采用固定设奖。当期奖金减去当期低奖等奖金为当期高奖等奖金，具体设奖如下：
          </div>
          <div>一等奖：奖金总额为当期高奖等奖金的70%；</div>
          <div>二等奖：奖金总额为当期高奖等奖金的10%；</div>
          <div>三等奖：奖金总额为当期高奖等奖金的20%；</div>
          <div>四等奖：单注奖金额固定为200元；</div>
          <div>五等奖：单注奖金额固定为50元；</div>
          <div>六等奖：单注奖金额固定为10元；</div>
          <div>七等奖：单注奖金额固定为5元。</div>
          <div>七乐彩单注奖金的最高限额为500万元。</div>
          <div>
            七乐彩设立奖池，奖池由未中出的高奖等奖金和超出单注奖金封顶限额部分的奖金组成。奖池与当期奖金中用于一等奖的部分合并颁发一等奖奖金。
          </div>
          <div>
            调节基金包括按销售总额1%提取部分、弃奖奖金、浮动奖奖金按元取整后的余额。调节基金用于设置特别奖、调节浮动奖奖金、支付各种不可预见情况下的奖金支出风险。
          </div>
          <div>
            若当期高奖等单注奖额低于其次奖等单注奖额的二倍，应保证高奖等的单注奖额为其次奖等单注奖额的二倍，资金来源由调节基金补足，调节基金不足时由发行经费垫支。
          </div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第四章 开奖</div>
        <div className=' section'>
          <div></div>
          <div>
            七乐彩由中福彩中心统一开奖，每周一、三、五开奖。摇奖过程在公证人员监督下进行，通过公众媒体发布开奖公告。
          </div>
          <div>
            各省中心将当期投注的全部数据刻入不可改写的光盘，中福彩中心对各省中心的数据进行汇总。开奖后，以光盘记录的当期数据作为开奖检索的依据。
          </div>
          <div>
            七乐彩通过摇奖器确定开奖号码。摇奖时先摇出7个号码作为基本号码，再摇出1个号码作为特别号码。
          </div>
          <div>开奖公告在各地主要媒体公布，并在各投注站点张贴。</div>
          <div>七乐彩的开奖结果以中福彩中心公布的开奖公告为准。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第五章 中奖</div>
        <div className=' section'>
          <div></div>
          <div>
            七乐彩以投注者所选投注号码与当期开奖号码相同个数的多少确定中奖等级，具体中奖设定如下：
          </div>
          <div>一等奖：投注号码与当期开奖号码中7个基本号码完全相同（顺序不限，下同）；</div>
          <div>二等奖：投注号码与当期开奖号码中任意6个基本号码及特别号码相同；</div>
          <div>三等奖：投注号码与当期开奖号码中任意6个基本号码相同；</div>
          <div>四等奖：投注号码与当期开奖号码中任意5个基本号码及特别号码相同；</div>
          <div>五等奖：投注号码与当期开奖号码中任意5个基本号码相同；</div>
          <div>六等奖：投注号码与当期开奖号码中任意4个基本号码及特别号码相同；</div>
          <div>七等奖：投注号码与开奖号码中任意4个基本号码相同。</div>
          <div>
            高奖等中奖者按各奖等的中奖注数均分该奖等奖金，并以元为单位取整计算；低奖等中奖者按各奖等的单注固定奖额获得奖金。
          </div>
          <div>当期每注投注号码只有一次中奖机会，不能兼中兼得（另行设奖按设奖规定执行）。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第六章 兑奖</div>
        <div className=' section'>
          <div></div>
          <div>
            七乐彩兑奖当期有效。每期开奖次日起，兑奖期限为60天，逾期未兑奖者视为弃奖，弃奖奖金进入调节基金。
          </div>
          <div>中奖人兑奖时须提交完整的兑奖彩票。中奖彩票因受损导致无法辨认真伪的，不予兑奖。</div>
          <div>各省具体兑奖规定按各省中心的兑奖规定执行。</div>
          <div>单注彩票中奖金额超过一万元者，须缴纳个人所得税，所得税由兑奖机构代扣代缴。</div>
        </div>
      </div>
      <div>
        <div className=' text-lg font-bold mb-4'>第七章 附 则</div>
        <div className=' section'>
          <div></div>
          <div> 本规则自批准之日起施行。</div>
        </div>
      </div>
    </div>
  )
}
