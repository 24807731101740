import {ITemplates} from '../../types/index';

import BINGO from './BINGO'
import BINGO_FT from './BINGO_FT';
import PKS_FT from './PKS_FT';
import PKS from "./PKS/index";
import SSC_FT from './SSC_FT';
import SSC from './SSC';
import KLSF from './KLSF/index';
import SYXW from './SYXW/index';
import KLC from './KLC/index';
import FJ36X7 from './FJ36X7/index';
import FJ31X7 from './FJ31X7/index';
import QG from './QG/index';
import HK6 from './HK6/index';
import KS from './KS/index';
import YDH from './YDH';


const templates:{[key:string]:ITemplates} = {
  BINGO,
  BINGO_FT,
  PKS_FT,
  PKS,
  SSC_FT,
  SSC,
  KLSF,
  SYXW,
  KLC,
  FJ36X7,
  FJ31X7,
  QG,
  HK6,
  KS,
  YDH
}

export default templates;