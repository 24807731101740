import { connect } from 'react-redux';
import MobileContent from '../MobileContent';
import MobileFooter from '../MobileFooter';
import MobileHeader from '../MobileHeader';
import MobileBanner from '../MobileBanner';
import './styles.scss';
import { RootState } from '../../../redux/store';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';


const mapStateToProps = (state: RootState) => ({
  bannerShow: state.appReducers.bannerShow,
})

function MobileLayout ({bannerShow}: { bannerShow: boolean }){   
  const { pathname } = useLocation();

  if (pathname !== '/') { 
    return (
      <div className='mobileLayoutWrapper h-full flex flex-col'>
        <MobileHeader />
        <MobileContent />
        <MobileFooter />
      </div>
    )
  }

  return ( 
    <div className='mobileLayoutWrapper h-full flex flex-col'>
      <motion.div 
        animate={{ height: bannerShow ? '64px' : '0px', opacity: bannerShow ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
        <MobileBanner />
      </motion.div>
      <MobileHeader />
      <MobileContent />
      <MobileFooter />
    </div>
  );
}
export default connect(mapStateToProps, null)(MobileLayout);