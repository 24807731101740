import {ILatestItem} from '../../../types/index';

const GenerateNumber = (obj:ILatestItem)=>{
  return (obj.draw_code.split(',') as string[]).map(item=>({value:item,className:`${Number(item) > 40 ? 'darK_yellow' : 'yellow'} KLC`}))
}

const generateZH = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zh as string, className:''}]
}
const generateDX = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zdx as string, className:''}]
}

const generateDS = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zds as string, className:''}]
}

const generateDSH = (obj:ILatestItem)=>{
  return [{value:obj.trend?.dsh as string, className:''}]
}

const generateQHH = (obj:ILatestItem)=>{
  return [{value:obj.trend?.qhh as string, className:''}]
}

const generateWX = (obj:ILatestItem)=>{
  return [{value:obj.trend?.wx as string, className:''}]
}


export const GenerateChannelResult = (obj:ILatestItem)=>{
  const result = obj.draw_code ? obj.draw_code.split(',') : []
  return <div className='w-full h-full flex flex-col justify-center items-center'>
    <div className='flex'>{
      result.slice(0,result.length / 2).map((item,index)=>(
        <div key={index.toString()} className={`${Number(item) > 40 ? 'darK_yellow' : 'yellow'} KLC`}>{item}</div>
      ))
    }</div>
    <div className='flex'>{
      result.slice(result.length / 2).map((item,index)=>(
        <div key={index.toString()} className={`${Number(item) > 40 ? 'darK_yellow' : 'yellow'} KLC`}>{item}</div>
      ))
    }</div>
  </div>
}

export const GenerateChannelZH = (obj:ILatestItem)=>{
  return [obj.trend?.zh,obj.trend?.zdx,obj.trend?.zds].map((item,index)=>(
    <div key={index.toString()} className={`h-full flex-1 flex justify-center items-center ${(item === '大' || item === '单') ? 'text-red-400' : ''}`}>{item}</div>
  ))
}

export const generateChannleDS = (obj:ILatestItem)=>{
  return  <div  className={`h-full flex-1 flex justify-center items-center`}>{obj.trend?.dsh}</div> 
}

export const generateChannleQH = (obj:ILatestItem)=>{
  return  <div  className={`h-full flex-1 flex justify-center items-center `}>{obj.trend?.qhh}</div> 
}

export const generateChannleWX = (obj:ILatestItem)=>{
  return  <div  className={`h-full flex-1 flex justify-center items-center `}>{obj.trend?.wx}</div> 
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string)=>{
  const result = obj.draw_code ? obj.draw_code.split(',') : []
  if(index === '0'){
    return <div className='w-full h-full flex flex-col justify-center items-center'>
      <div className='flex'>{
        result.slice(0,result.length / 2).map((item,index)=>(
          <div key={index.toString()} className={`${Number(item) > 40 ? 'darK_yellow' : 'yellow'} KLC`}>{item}</div>
        ))
      }</div>
      <div className='flex'>{
        result.slice(result.length / 2).map((item,index)=>(
          <div key={index.toString()} className={`${Number(item) > 40 ? 'darK_yellow' : 'yellow'} KLC`}>{item}</div>
        ))
      }</div>
    </div>
  }
  else if(index === 'zh/ds'){
    return <div className='flex w-full h-full'>
      <div className='flex flex-1'>{GenerateChannelZH(obj)}</div>
      <div className='flex flex-1'>{generateChannleDS(obj)}</div>
    </div>
  }
  else if(index === 'qh/wx'){
    return <div className='flex w-full h-full'>
      <div className='flex flex-1'>{generateChannleQH(obj)}</div>
      <div className='flex flex-1'>{generateChannleWX(obj)}</div>
    </div>
  }
}

const KLC = {
  GenerateNumber,
  templateWrapper:'KLCWrapper',
  homeTableHeader:[{title:'总和',Colspan:3},{title:'单双和',Colspan:1},{title:'前后和',Colspan:1},{title:'五行',Colspan:1}],
  homeTableRowItem:[
    {
      wrapperStyle:'',
      generateData:generateZH
    },
    {
      wrapperStyle:'',
      generateData:generateDX
    },
    {
      wrapperStyle:'',
      generateData:generateDS
    },  
    {
      wrapperStyle:'',
      generateData:generateDSH
    },    
    {
      wrapperStyle:'',
      generateData:generateQHH
    },
    {
      wrapperStyle:'',
      generateData:generateWX
    },
  ],
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
    {label:'ZONG_HE_LUZHU',menuCode:'ZONG_HE_LUZHU'},
    {label:'ZH_TREND',menuCode:'H_NUMBER_TREND'},
    {label:'RULES',menuCode:'RULES'},
  ],
  H_V_NumberTrend:[
    {label:'总和',value:0,rank:'总和'},
  ],
  ChannelDrawResult:{
    web:{
      header:[
        [{label:'开奖结果',value:'result'}],
        [{label:'总和',value:'zh'}],
        [{label:'单双',value:'ds'}],
        [{label:'前后',value:'qh'}],
        [{label:'五行',value:'wx'}],
      ],
      rowProperty:[
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{GenerateChannelZH(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{generateChannleDS(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{generateChannleQH(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{generateChannleWX(obj)}</div>
        }
      ]
    },
    mobile:{
      header:[
        {label:'开奖结果',value:'0'},
        {label:'总和/单双',value:'zh/ds',mulitLabel:[{label:'总和',className:'flex-1'},{label:'单双和',className:'flex-1'}]},
        {label:'前后/五行',value:'qh/wx',mulitLabel:[{label:'前后',className:'flex-1'},{label:'五行',className:'flex-1'}]},
      ],
      rowProperty:
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index)}</div>
        },
    }
  }
}

export default KLC;