export default function Template0() {
  return (
    <div>
      <div className=' section'>
        <div>1～10 两面：指 单、双；大、小。</div>
        <div>单、双：号码为双数叫双，如4、8；号码为单数叫单，如5、9。</div>
        <div>大、小：开出之号码大于或等于6为大，小于或等于5为小。 第一名～第十名</div>
        <div>
          车号指定：每一个车号为一投注组合，开奖结果“投注车号”对应所投名次视为中奖，其余情形视为不中奖。
        </div>
      </div>
      <div className=' section'>
        <div>1～5龙虎</div>
        <div>
          冠 军
          龙/虎：“第一名”车号大于“第十名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。
        </div>
        <div>
          亚 军
          龙/虎：“第二名”车号大于“第九名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。
        </div>
        <div>
          第三名
          龙/虎：“第三名”车号大于“第八名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。
        </div>
        <div>
          第四名
          龙/虎：“第四名”车号大于“第七名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。
        </div>
        <div>
          第五名
          龙/虎：“第五名”车号大于“第六名”车号视为【龙】中奖、反之小于视为【虎】中奖，其余情形视为不中奖。
        </div>
      </div>
      <div className=' section'>
        <div>冠军车号＋亚军车号＝冠亚和值（为3~19)</div>
        <div>
          冠亚和单双：“冠亚和值”为单视为投注“单”的注单视为中奖，为双视为投注“双”的注单视为中奖，其余视为不中奖。
        </div>
        <div>
          冠亚和大小：“冠亚和值”大于11时投注“大”的注单视为中奖，小于或等于11时投注“小”的注单视为中奖，其余视为不中奖。
        </div>
        <div>
          冠亚和指定：“冠亚和值”可能出现的结果为3～19，投中对应“冠亚和值”数字的视为中奖，其余视为不中奖。
        </div>
      </div>
    </div>
  )
}
