import { Button, ConfigProvider, Divider, InputNumber, Select, Skeleton } from 'antd'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { IShowData } from '../../Web/LuZhu'
import useZongHeLuZhu from '../../Web/LuZhu/useLuZhu'
import './styles.scss'
import { ILuZhuProps } from '../../Web/LuZhu'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import useOnScreen from '../../Web/LuZhu/useOnScreen'

import { useAppSelector } from '../../../redux/hooks'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function Table({ data }: { data: IShowData }) {
  const { count = [], lzList = [], lzOption, rankOption } = data || {}
  const [cnt, setCnt] = useState(4)
  const [confirmed, setConfirmed] = useState(false)
  const { t } = useTranslation()

  /** 下拉框选项，根据 返回的count 当中解析*/
  const selectOptions = useMemo(
    () =>
      count.map((i) => {
        const label = i.split(':')?.[0]
        return { label, value: label }
      }),
    [count],
  )

  const [selected, setSelected] = useState(selectOptions?.[0]?.label || '') //

  // 统计 结果出现多少次以上的
  const totalCnt =
    (confirmed && lzList.filter((i) => i.filter((lz) => lz == selected).length >= cnt).length) || 0

  const contentScrollRef = useRef<HTMLDivElement>(null)

  // 表格数据变化 滚动到最右边
  useEffect(() => {
    const scrollWidth = contentScrollRef.current?.scrollWidth
    contentScrollRef.current?.scrollTo({ left: scrollWidth })
  }, [lzList])

  return (
    <div className='tableWrapper'>
      <div className=' selectArea'>
        {t('CONTINUOUS_OCCUR')}
        <InputNumber min={1} value={cnt} onChange={(v) => setCnt(v || 0)} className=' select' />
        {t('LUZHU_ABOVE')}
        <Select
          options={selectOptions}
          value={selected}
          onChange={(v) => setSelected(v)}
          className=' select'
        />
        <span
          className={`button confirm ${confirmed && 'active'}`}
          onClick={() => setConfirmed(!confirmed)}
        >
          {t('CONFIRM')}
        </span>
      </div>
      <div className=' selectArea'>
        <span>
          {t('OCCURRENCES')}： {totalCnt}
        </span>
        <div className=' rightHeader'>
          {t('LUZHU_TOTAL')}:
          {count.map((item) => (
            <span key={item}>{item}</span>
          ))}
        </div>
      </div>
      <div className=' tableArea'>
        <div className='rightGradient'></div>
        <div className=' header'>
          <div className=' headerWithoutNewest'>
            <div className=' left'>
              <span className=' button active text '> {rankOption?.label || ''} </span>
              <span className=' button active text'> {lzOption?.label || ''} </span>
            </div>
          </div>

          <div className=' newest'>向右滑动→</div>
        </div>

        <div className=' content' ref={contentScrollRef}>
          {lzList.map((item, index) => {
            const ifHighLight = confirmed && item.every((i) => i == selected) && item.length >= cnt
            return (
              <div className={` col ${ifHighLight && 'highLight'}`} key={index}>
                {item.map((r, rIndex) => {
                  return (
                    <div className={` text ${index % 2 && 'active'}`} key={rIndex}>
                      {r}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function MobileLuZhu({ title, lotteryCode, rankingOptions, luZhuOptions }: ILuZhuProps) {
  const { date, setDate, showData, luzhu, setLuZhu, rankings, setRankings, loading } =
    useZongHeLuZhu({
      rankingOptions,
      luZhuOptions,
      lotteryCode,
    })
  const { t } = useTranslation()

  const ref = useRef<HTMLDivElement>(null)
  const showLoadingMore = useOnScreen<HTMLDivElement>(ref)

  const [endIndex, setEndIndex] = useState(3)

  useEffect(() => {
    setEndIndex(3)
  }, [showData.length]) // 只要hook的数据出来 有变化，render数量，永远只是在3个

  useEffect(() => {
    if (showLoadingMore) {
      setEndIndex(endIndex + 3)
    }
  }, [showLoadingMore])

  const showDataSplit = showData.slice(0, endIndex)
  const hideLoadingMore = showDataSplit.length >= showData.length

  return (
    <div className=' LuZhuH5Wrapper'>
      <div className=' titleArea'>
        <div className=' title'>{title}</div>
        <div className=' dateFilterArea'>
          <div className=' dateQucikChoose'>
            {[
              { label: '今天', value: dayjs().format('YYYY-MM-DD') },
              { label: '昨天', value: dayjs().subtract(1, 'day').format('YYYY-MM-DD') },
              { label: '前天', value: dayjs().subtract(2, 'day').format('YYYY-MM-DD') },
            ].map((item) => {
              return (
                <Button
                  onClick={() => setDate(item.value)}
                  className={` button date ${
                    (item.value === date.format('YYYY-MM-DD') && 'active') || ''
                  }`}
                  key={item.label}
                >
                  {item.label}
                </Button>
              )
            })}
          </div>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={date.toDate()} 
            maxDate={new Date()}
            onChange={(date)=>{
              if(date){
                setDate(dayjs(date).format('YYYY-MM-DD'))
              }
            }}
          />
        </div>
      </div>
      {/* 如果只有一个选项，删除这个显示区域。适用于，总和/冠亚和路珠 */}
      {(rankings?.length > 1 && (
        <div className=' filterArea'>
          <div className=' filterTitle'>筛选名次</div>
          <div className=' filterItemsWithCheckAll'>
            <div className=' filterItems'>
              {rankings.map((i, index) => {
                return (
                  <div
                    onClick={() =>
                      setRankings([
                        ...rankings.slice(0, index),
                        { ...i, checked: !i.checked },
                        ...rankings.slice(index + 1),
                      ])
                    }
                    className={` button ${i.checked && 'active'}`}
                    key={i.value}
                  >
                    {i.label}
                  </div>
                )
              })}
              {(rankings?.length > 1 && (
                <>
                  <div
                    className='button text '
                    onClick={() => setRankings(rankings.map((i) => ({ ...i, checked: true })))}
                  >
                    {t('CHECK_ALL')}
                  </div>
                  <div
                    className='button text '
                    onClick={() => setRankings(rankings.map((i) => ({ ...i, checked: false })))}
                  >
                    {t('CLEAR')}
                  </div>
                </>
              )) || <></>}
            </div>
          </div>
        </div>
      )) || <></>}

      {/* 如果只有一个选项，删除这个显示区域。适用于，大小/单双/龙虎路珠 */}
      {(luzhu.length > 1 && (
        <div className=' filterArea'>
          <div className=' filterTitle'>选择路珠</div>
          <div className=' filterItems'>
            {luzhu.map((i, index) => {
              return (
                <div
                  onClick={() =>
                    setLuZhu([
                      ...luzhu.slice(0, index),
                      { ...i, checked: !i.checked },
                      ...luzhu.slice(index + 1),
                    ])
                  }
                  className={` button ${i.checked && 'active'}`}
                  key={i.value}
                >
                  {i.label}
                </div>
              )
            })}
          </div>
        </div>
      )) || <></>}

      {(loading && (
        <>
          <Skeleton.Button className='pb-[10px]' block active />
          <Skeleton.Button className='pb-[10px]' block active />
          <Skeleton.Button className='pb-[10px]' block active />
        </>
      )) || <></>}

      {(!loading && (
        <div>
          {showDataSplit.map((item) => {
            return (
              <Fragment key={item.label}>
                <Table data={item} />
                <Divider />
              </Fragment>
            )
          })}
        </div>
      )) || <></>}

      {/* 加载更多 */}

      <div ref={ref} className={` flex justify-center p-[10px] ${hideLoadingMore && ' hidden'}`}>
        Loading...
      </div>
    </div>
  )
}

function MobileLuZhuWithConfigProvider(props: ILuZhuProps) {
  const { theme = '' } = useAppSelector((state) => state.appReducers)

  const themeMap = {
    red: { colorPrimary: '#E86262' },
    blue: { colorPrimary: '#5D66D3' },
    green: { colorPrimary: '#4A9F5E' },
    orange: { colorPrimary: '#E38533' },
  }

  return (
    <ConfigProvider
      // componentSize='large'
      theme={{
        token: {
          colorPrimary: themeMap[theme as keyof typeof themeMap].colorPrimary || '#E86262',
        },
      }}
    >
      <MobileLuZhu {...props} />
    </ConfigProvider>
  )
}
export default MobileLuZhuWithConfigProvider
