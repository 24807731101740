import {ILatestItem} from '../../../types/index';

const REDBALL:Array<string | number> = ['1', '2', '7', '8', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
const BLUEBALL:Array<string | number>  = ['3', '4', '9', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
const GREENBALL:Array<string | number>  = ['5', '6', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];


const GenerateNumber = (obj:ILatestItem)=>{  
  const temp:Array<string | number> = [...obj.draw_code.split(',').slice(0,6),'+'].concat(obj.draw_code.split(',').slice(6),'总分').concat(...(obj.trend?.sx as []).slice(0,6),'').concat(...(obj.trend?.sx as []).slice(6)).concat((obj.trend?.zh as string));
 
  return temp.map((item,index)=>
  {
    if(index < 6 || index === 7){
      item = `${Number(item)}`;
      return ({value:item,className:`${REDBALL.includes(item) ? 'red' : BLUEBALL.includes(item) ? 'blue' : GREENBALL.includes(item) ? 'green' : ''} HK6`})
    }
    return ({value:item,className:`textBox HK6`})
  })
}


export const GenerateChannelZH = (obj:ILatestItem)=>{
  return [obj.trend?.zh,obj.trend?.zdx,obj.trend?.zds,obj.trend?.zwdx,].map((item,index)=>(
    <div key={index.toString()} className={`h-full flex-1 flex justify-center items-center ${(item === '尾大' || item === '大' || item === '单') ? 'text-red-400' : ''}`}>{item}</div>
  ))
}

export const GenerateChannelResult = (obj:ILatestItem,index:string)=>{
  if(index === '0'){
    return (obj.draw_code.split(',') as string[]).map((item,index)=>(
      <div key={index.toString()} className={`PKS _${item}`}>{item}</div>
    ))
  }
  else if(index === '1'){
    return (obj.trend?.dx as string[]).map(item=>(
      <div key={index.toString()} className={`PKS ${item === '大' ? 'yellow' : 'grey'}`}>{item}</div>
    ))   
  }
  else if(index === '2') {
    return (obj.trend?.ds as string[]).map(item=>(
      <div key={index.toString()} className={`PKS ${item === '单' ? 'yellow' : 'grey'}`}>{item}</div>
    ))  
  }
}

export const GenerateChannelGYH = (obj:ILatestItem)=>{
  return [obj.trend?.gyh,obj.trend?.gydx,obj.trend?.gyds].map((item,index)=>(
    <div key={index.toString()} className={`h-full flex-1 flex justify-center items-center ${(item === '大' || item === '单') ? 'text-red-400' : ''}`}>{item}</div>
  ))
}

export const generateChannleLH = (obj:ILatestItem)=>{
  return  (obj.trend?.lh as []).map((item,index)=> (
    <div key={index.toString()} className={`h-full flex-1 flex justify-center items-center ${item === '龙' ? 'text-red-400' : ''}`}>{item}</div>
  ))
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string, isRndNum?:number)=>{
  if(index === '0'){
    return (obj.draw_code.split(',') as string[]).map((item,index)=>(
      <div key={index.toString()} className={`PKS _${item}`}>{item}</div>
    )).concat(isRndNum === 1 ? <div className='flex justify-center items-center'>+{obj.rnd}</div> : [])
  }
  else if(index === '1'){
    return (obj.trend?.dx as string[]).map(item=>(
      <div key={index.toString()} className={`PKS ${item === '大' ? 'yellow' : 'grey'}`}>{item}</div>
    ))   
  }
  else if(index === '2') {
    return (obj.trend?.ds as string[]).map(item=>(
      <div key={index.toString()} className={`PKS ${item === '单' ? 'yellow' : 'grey'}`}>{item}</div>
    ))  
  }
  else if(index === '3') {
    return <div className='flex h-full w-full'>
      <div className='flex'>
        {GenerateChannelGYH(obj)}
      </div>
      <div className='mx-2'>/</div>
      <div className='flex'>{generateChannleLH(obj)}</div>
    </div> 
  }
}


const HK6 = {
  GenerateNumber,
  templateWrapper:'HK6Wrapper',
  // subMenuList:[
  //   {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
  // {label:'DRAW_RESULT',menuCode:'DRAW_RESULT'},
  // {label:'HK6_TEMA_SUM',menuCode:'HK6_TEMA_SUM'},
  // {label:'HK6_TEMA_DAN_SHUANG',menuCode:'HK6_TEMA_DAN_SHUANG'},
  // {label:'HK6_TEMA_WEI',menuCode:'HK6_TEMA_WEI'},
  // {label:'HK6_TEMA_BO_SE',menuCode:'HK6_TEMA_BO_SE'},
  // {label:'HK6_TEMA_DA_XIAO',menuCode:'HK6_TEMA_DA_XIAO'},
  // {label:'HK6_TEMA_TOU_SHU',menuCode:'HK6_TEMA_TOU_SHU'},
  // {label:'HK6_TEMA_HE_SHU',menuCode:'HK6_TEMA_HE_SHU'},
  // {label:'HK6_TEMA_MISSING',menuCode:'HK6_TEMA_MISSING'},
  //   {label:'RULES',menuCode:'RULES'},
  // ],
  ChannelDrawResult:{
    web:{
      header:[[{label:'生肖',value:'0'},{label:'五行',value:'1'},{label:'大小',value:'2'},{label:'单双',value:'3'}],[{label:'冠亚和',value:'gyh'}],[{label:'1-5龙虎',value:'lh'}]],
      rowProperty:[
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,index)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{GenerateChannelGYH(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{generateChannleLH(obj)}</div>
        }
      ]
    },
    mobile:{
      header:[{label:'号码',value:'0'},{label:'大小',value:'1'},{label:'单双',value:'2'},{label:'冠亚和/龙虎',value:'3'}],
      rowProperty:
        (obj:ILatestItem,index:string, isRndNum?:number)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index,isRndNum )}</div>
        }    
    }
  },
}

export default HK6;