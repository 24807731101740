import './styles.scss';

import { connect } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import templates from '../../../constants/templates';
import { useEffect, useState } from 'react';
import { apiService } from '../../../services';
import { InputNumber } from 'antd';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line  } from 'react-chartjs-2';

import type { ScriptableTooltipContext } from 'chart.js';
import { ILatestItem, ILotteryList } from '../../../types';
import { RootState } from '../../../redux/store';
import ExternalTooltipHandler from '../../common/ExternalTooltipHandler';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const mapStateToProps = (state:RootState) => ({
  lotteryList: state.lotteryReducers.lotteryList,
});
interface toolTipProp{
  x:number,
  y:number,
  item:ILatestItem
}

interface IProps{
  lotteryList:ILotteryList,
}


function HorizontalNumberTrend({lotteryList}:IProps) {
  const { t } = useTranslation();  
  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const [selectedTemplate, setSelectedTemplate] = useState<string>(templateAndGame.template)

  const [trendData, setTrendData] = useState<{datasets:Array<{data:Array<{x:number | string,y:number}>}>}>({datasets:[]});
  const [selectedNumber, setSelectedNumber] = useState<number>(1);
  const [installment, setInstallment] = useState(60);
  const [inputValue, setInputValue] = useState(60);
  const [isRndNum, setIsRndNum] = useState(0);

  const CustomTooltip = (obj:toolTipProp) => {  
    let type = ''
    if(templates[selectedTemplate] && templates[selectedTemplate].H_V_NumberTrend){
      const item = templates[selectedTemplate].H_V_NumberTrend?.find(x=>x.value === selectedNumber)
      type = item ? (item?.rank || item.label) : ''
    }
    return (
      <div className="p-2">
        <div className='flex mb-2 justify-center whitespace-nowrap'>
          {`${type} ${obj.x}期 和值为： ${obj.y}`}
        </div>
        <div className={`flex ${templates[`${selectedTemplate}`].templateWrapper}`}>
          {templates[`${selectedTemplate}`].GenerateNumber(obj.item).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
            return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
          }
          ) }
          {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>+{obj.item.rnd}</div>}
        </div>
      </div>
    );  
  };

  const options = {
    maintainAspectRatio:false,
    layout: {
      padding:0
    },
    elements:{
      line:{
        backgroundColor: '#FFC400',
        borderColor: '#FFC400',
      },
      point:{
        pointStyle: 'circle',
        pointRadius: 4,
        backgroundColor:'#FFC400',
      }
    },
    scales: {
      x: {
        ticks: {
          display: false
        }
      }
    },
    parsing:{
      xAxisKey: 'x',
      yAxisKey: 'y'
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: (context:ScriptableTooltipContext<'line'>)=>ExternalTooltipHandler<'line', toolTipProp>(context, CustomTooltip),
      },
      legend:{
        display:false
      }
    }
  };

  const getHistory = async () => {
    const lotteryCode = templateAndGame.code;    
    try {
      const res = await apiService.get(`/lottery/history_top/${companyCode}/${lotteryCode}/${installment}`)
      const data:Array<{draw_code:string,issue:string}> = res.data?.data ??  [];
      const temp = data.map((item:{draw_code:string,issue:number | string})=> {
        return ({
          y:(templateAndGame?.template === 'KS' || templateAndGame?.template === 'KLC') ? item.draw_code.split(',').reduce((a,b)=>Number(a) + Number(b),0) : selectedNumber === 0 ? Number(item.draw_code.split(',')[0]) + Number(item.draw_code.split(',')[1]) : Number(item.draw_code.split(',')[Number(selectedNumber) - 1]),
          x:item.issue,
          item
        })
      } 
      )
      setTrendData({datasets:[{ data:temp}]})    
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(()=>{
    if(templateAndGame?.template && templates[selectedTemplate]){
      setIsRndNum(lotteryList.lotterys.find(x=>x.code === templateAndGame.code)?.isRndNum ?? 0)   
      setSelectedTemplate(templateAndGame.template);
      if(templates[selectedTemplate].H_V_NumberTrend){
        setSelectedNumber((templates[selectedTemplate]?.H_V_NumberTrend as Array<{value:number}>)[0]?.value)
      }
    }
  },[templateAndGame])

  useEffect(()=>{
    if(templateAndGame?.code){
      getHistory()
    }
  },[installment,selectedNumber])
  
  return <div className='HorizontalNumberTrendWrapper'>
    <div className='flex items-center'>
      <div className='font-bold mr-4 text-[24px]' >
        {
          t('NUMBER_SELECT')
        }
      </div>
      <div className='flex flex-wrap flex-1'>
        {
          templates[selectedTemplate] && templates[selectedTemplate].H_V_NumberTrend?.map(item=> 
            <div 
              onClick={()=>setSelectedNumber(item.value)}
              className={`buttonWrapper ${selectedNumber == item.value ? 'selected' : ''}`}
              key={item.label}>
              {item.label}
            </div> )
        }
      </div>    
    </div>
    <div className='flex items-center my-5'>
      <div className='font-bold mr-4'>{t('SHOW_INSTALLMENT')}</div>
      <div className='flex items-center'>
        <InputNumber max={100} className='mr-4' size="large" min={1} value={inputValue} onChange={value=> setInputValue(value ?? 1)}  controls={false} />
        <div className='buttonWrapper selected cursor-pointer' onClick={()=>setInstallment(inputValue)}>{t('SETTING')}</div>
      </div>
    </div>
    <div className=' w-full relative flex justify-center h-[400px]'>
      {trendData.datasets.length > 0 &&
          <Line options={options} data={{...trendData,labels:trendData.datasets[0].data.map(item=>item.x)}} />
      }
    </div>
  </div>
    
}

export default connect(mapStateToProps, null)(HorizontalNumberTrend);