import {ILatestItem} from '../../../types/index';

const GenerateNumber = (obj:ILatestItem)=>{
  return (obj.draw_code.split(',') as string[]).map((item,index, arr)=>({value:item,className:`${index < arr.length - 1 ? 'red' : 'yellow'} FJ36X7`}))
}

export const GenerateChannelResult = (obj:ILatestItem)=>{
  const result = obj.draw_code ? obj.draw_code.split(',') : []
  return result.map((item,index)=>(
    <div key={index.toString()} className={`${index < result.length - 1 ? 'red' : 'yellow'} FJ36X7`}>{item}</div>
  ))
}

export const GenerateChannelZH = (obj:ILatestItem)=>{
  const result = (obj.draw_code ? obj.draw_code.split(',') : []).reduce((a,b)=>Number(a) + Number(b),0)
  return [result,(result % 2 === 0 ? '双' : '单')].map((item,index)=>(
    <div key={index.toString()} className={`h-full flex-1 flex justify-center items-center ${ item === '单' ? 'text-red-400' : ''}`}>{item}</div>
  ))
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string,isRndNum?:number)=>{
  const result = obj.draw_code ? obj.draw_code.split(',') : []
  if(index === '0'){
    return result.map((item,index)=>(
      <div key={index.toString()} className={`${index < result.length - 1 ? 'red' : 'yellow'} FJ36X7`}>{item}</div>
    )).concat(isRndNum === 1 ? <div className='flex justify-center items-center'>+{obj.rnd}</div> : [])
  }
  else if(index === 'zh'){
    return GenerateChannelZH(obj)
  }
}

const FJ36X7 = {
  GenerateNumber,
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
  ],
  ChannelDrawResult:{
    web:{
      header:[
        [{label:'开奖结果',value:'result'}],
        [{label:'总和',value:'zh'}],
      ],
      rowProperty:[
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{GenerateChannelZH(obj)}</div>
        },
      ]
    },
    mobile:{
      header:[
        {label:'开奖结果',value:'0'},
        {label:'总和',value:'zh'},
      ],
      rowProperty:
        (obj:ILatestItem,index:string, isRndNum?:number)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index, isRndNum)}</div>
        },
    }
  }
}

export default FJ36X7;