import { connect } from 'react-redux';

import { ReactComponent as EmptyIcon } from '../../../assets/image/data_empty.svg';
import { Skeleton } from 'antd';

import './styles.scss';
import { ILatestItem, IPlanItem, IShuBanItem } from '../../../types';

interface IProps{
  header?:Array<(()=>JSX.Element) | {label:string,className:string} > | undefined,
  contentProperty:Array<(item:ILatestItem | IPlanItem | IShuBanItem)=>JSX.Element>,
  contentData:ILatestItem[] | Array<IPlanItem> | IShuBanItem[]
  loading:boolean,
  className?:string
}

function Table ({header = [],className, contentProperty = [], contentData = [], loading = true}:IProps){   

  return ( 
    <div className='tableWrapper'>
      <table className={className}>
        {
          header && 
        <thead>
          <tr>
            {header.map((FnItem,index)=> 
              <th key={index.toString()}>
                <div className='h-full'>
                  {
                    typeof FnItem === 'function' 
                      ? 
                      FnItem() 
                      : 
                      <div className={`${FnItem.className}`}>
                        {FnItem.label}
                      </div>
                  }
                </div>
              </th>
            )}
          </tr>
        </thead>
        }
        <tbody>
          {
            loading && Array(15).fill('').map((item,index)=>{
              return <tr key={index.toString()}>
                {
                  Array(header?.length ?? 5).fill('').map((item,index)=>{
                    return <td key={index.toString()}>
                      <div className='flex justify-center items-center h-full w-full px-[10%]'>
                        <Skeleton.Button active block />
                      </div>
                    </td>
                  })
                }
              </tr>
            })
          }
          {
            !loading && contentData.length === 0 && 
            <tr>
              <td colSpan={header?.length ?? 5}>
                <div className='tableEmptyWrapper'>
                  <EmptyIcon />
                  <div className="emptyTextWrapper">
                    <span>暂无记录</span>
                  </div>
                </div>
              </td>
            </tr>
          }
          {
            !loading && contentData.length > 0 && contentData.map((item:(ILatestItem | IPlanItem | IShuBanItem),index:number)=>{
              return <tr key={index.toString()}>
                {
                  contentProperty.map((Fn,index)=>{
                    return <td key={index.toString()}>{Fn(item)}</td>
                  })
                }
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  );
}
export default connect(null, null)(Table);
