import { useState, useEffect, RefObject } from 'react'

function useOnScreen<T extends Element>(ref: RefObject<T>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)
  useEffect(() => {
    if (!ref.current) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      { rootMargin: '0px' },
    )
    ref.current && observer.observe(ref.current)
    return () => {
      // ref.current && observer.unobserve(ref.current)
      observer.disconnect()
    }
  }, [ref.current]) // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting
}

export default useOnScreen
