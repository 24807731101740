import { ILatestItem, IShuBanItem } from '../../../types/index'

export const GenerateNumber = (obj: ILatestItem) => {
  return (obj.draw_code.split(',') as string[]).map((item) => ({
    value: item,
    className: `PKS _${item}`,
  }))
}

export const generateGYH = (obj: ILatestItem) => {
  return [{ value: obj.trend?.gyh as string, className: '' }]
}

export const generateGYDX = (obj: ILatestItem) => {
  return [{ value: obj.trend?.gydx as string, className: '' }]
}

export const generateGYDS = (obj: ILatestItem) => {
  return [{ value: obj.trend?.gyds as string, className: '' }]
}

export const generateDX = (obj: ILatestItem) => {
  return [{ value: obj.trend?.dx as string, className: '' }]
}

export const generateDS = (obj: ILatestItem) => {
  return [{ value: obj.trend?.ds as string, className: '' }]
}

export const generateLH = (obj: ILatestItem, index: number) => {
  return [{ value: (obj.trend?.lh as [])[index] as string, className: '' }]
}

export const killPlanNumber = (data: Array<string>) => {
  return data.map((item) => ({ value: item, className: `PKS _${item}` }))
}

export const GenerateChannelResult = (obj: ILatestItem, index: string) => {
  if (index === '0') {
    return (obj.draw_code.split(',') as string[]).map((item, index) => (
      <div key={index.toString()} className={`PKS _${item}`}>
        {item}
      </div>
    ))
  } else if (index === '1') {
    return (obj.trend?.dx as string[]).map((item) => (
      <div key={index.toString()} className={`PKS ${item === '大' ? 'yellow' : 'grey'}`}>
        {item}
      </div>
    ))
  } else if (index === '2') {
    return (obj.trend?.ds as string[]).map((item) => (
      <div key={index.toString()} className={`PKS ${item === '单' ? 'yellow' : 'grey'}`}>
        {item}
      </div>
    ))
  }
}

export const GenerateChannelGYH = (obj: ILatestItem) => {
  return [obj.trend?.gyh, obj.trend?.gydx, obj.trend?.gyds].map((item, index) => (
    <div
      key={index.toString()}
      className={`h-full flex-1 flex justify-center items-center ${
        item === '大' || item === '单' ? 'text-red-400' : ''
      }`}
    >
      {item}
    </div>
  ))
}

export const generateChannleLH = (obj: ILatestItem) => {
  if (!obj.trend?.lh) return
  return (obj.trend?.lh as []).map((item, index) => (
    <div
      key={index.toString()}
      className={`h-full flex-1 flex justify-center items-center ${
        item === '龙' ? 'text-red-400' : ''
      }`}
    >
      {item}
    </div>
  ))
}

export const GenerateMobileChannelResult = (obj: ILatestItem, index: string, isRndNum?: number) => {
  if (index === '0') {
    return (obj.draw_code.split(',') as string[])
      .map((item, index) => (
        <div key={index.toString()} className={`PKS _${item}`}>
          {item}
        </div>
      ))
      .concat(
        isRndNum === 1 ? <div className='flex justify-center items-center'>+{obj.rnd}</div> : [],
      )
  } else if (index === '1') {
    return (obj.trend?.dx as string[]).map((item, index) => (
      <div key={index.toString()} className={`PKS ${item === '大' ? 'yellow' : 'grey'}`}>
        {item}
      </div>
    ))
  } else if (index === '2') {
    return (obj.trend?.ds as string[]).map((item, index) => (
      <div key={index.toString()} className={`PKS ${item === '单' ? 'yellow' : 'grey'}`}>
        {item}
      </div>
    ))
  } else if (index === '3') {
    return (
      <div className='flex h-full justify-center'>
        <div className='flex'>{generateChannleLH(obj)}</div>
      </div>
    )
  }
}

const generateWebShuBanHeader = (ball: number) => {
  let header = []
  if (ball === 0) {
    header = [
      () => <div className='h-full w-full flex justify-center items-center'>期号</div>,
      () => (
        <div className='headerCellWrapper'>
          <div>{YDH.H_V_NumberTrend.find((x) => x.value === ball)?.rank}走势</div>
          <div>
            {Array.from({ length: 17 }, (v, k) => k + 3).map((item, index) => (
              <div key={index.toString()}>{item}</div>
            ))}
          </div>
        </div>
      ),
      () => (
        <div className='headerCellWrapper'>
          <div>{YDH.H_V_NumberTrend.find((x) => x.value === ball)?.rank}</div>
          <div>
            {['和', '大', '小', '单', '双'].map((item, index) => (
              <div key={index.toString()}>{item}</div>
            ))}
          </div>
        </div>
      ),
    ]
  } else {
    header = [
      () => <div className='h-full w-full flex justify-center items-center'>期号</div>,
      () => <div className='h-full w-full flex justify-center items-center'>开奖号码</div>,
      () => (
        <div className='headerCellWrapper'>
          <div>{YDH.H_V_NumberTrend.find((x) => x.value === ball)?.rank}走势</div>
          <div>
            {Array.from({ length: 6 }, (v, k) => k + 1).map((item, index) => (
              <div key={index.toString()}>{item}</div>
            ))}
          </div>
        </div>
      ),
      () => (
        <div className='headerCellWrapper'>
          <div>大小</div>
          <div>
            {['大', '小'].map((item, index) => (
              <div key={index.toString()}>{item}</div>
            ))}
          </div>
        </div>
      ),
      () => (
        <div className='headerCellWrapper'>
          <div>单双</div>
          <div>
            {['单', '双'].map((item, index) => (
              <div key={index.toString()}>{item}</div>
            ))}
          </div>
        </div>
      ),
    ]
  }
  return header
}

const generateWebShuBanRow = (ball: number) => {
  let row = []
  if (ball === 0) {
    row = [
      (item: IShuBanItem) => (
        <div className='h-full w-full flex justify-center items-center'>{item?.term}</div>
      ),
      (item: IShuBanItem) => (
        <div className='rowCellWrapper'>
          {item.zs.slice(0, 6).map((data, index) => {
            const cls = 1 * data > 0 ? 'ball' : 'yilou'
            return (
              <div key={index.toString()}>
                <div className={cls}>{Math.abs(data)}</div>
              </div>
            )
          })}
        </div>
      ),
      (item: IShuBanItem) => (
        <div className='rowCellWrapper'>
          {[<div key='zh'>{item?.zh}</div>].concat(
            item.zs.slice(5, 10).map((itm, j) => {
              const b = 1 * itm > 0
              const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou'
              const txt = b ? (j == 0 ? '大' : j == 1 ? '小' : j == 2 ? '单' : '双') : Math.abs(itm)
              return (
                <div key={j.toString()} className={cls}>
                  {txt}
                </div>
              )
            }),
          )}
        </div>
      ),
    ]
  } else {
    row = [
      (item: IShuBanItem) => (
        <div className='h-full w-full flex justify-center items-center'>{item?.term}</div>
      ),
      (item: IShuBanItem) => (
        <div className='h-full w-full flex justify-center items-center'>
          {GenerateChannelResult({ draw_code: item?.balls ?? '' } as ILatestItem, '0')}
        </div>
      ),
      (item: IShuBanItem) => (
        <div className='rowCellWrapper'>
          {item.zs.slice(0, 6).map((data, index) => {
            const cls = 1 * data > 0 ? 'ball' : 'yilou'
            return (
              <div key={index.toString()}>
                {' '}
                <div className={cls}>{Math.abs(data)}</div>
              </div>
            )
          })}
        </div>
      ),
      (item: IShuBanItem) => (
        <div className='rowCellWrapper'>
          {item.zs.slice(6, 8).map((itm, j) => {
            const b = 1 * itm > 0
            const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou'
            const txt = b ? (j == 0 ? '大' : '小') : Math.abs(itm)
            return (
              <div className={cls} key={j.toString()}>
                {txt}
              </div>
            )
          })}
        </div>
      ),
      (item: IShuBanItem) => (
        <div className='rowCellWrapper'>
          {item.zs.slice(8, 10).map((itm, j) => {
            const b = 1 * itm > 0
            const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou'
            const txt = b ? (j == 0 ? '单' : '双') : Math.abs(itm)
            return (
              <div className={cls} key={j.toString()}>
                {txt}
              </div>
            )
          })}
        </div>
      ),
    ]
  }
  return row
}

const generateMobileShuBanHeader = (ball: number, type: string) => {
  let header
  if (ball === 0) {
    if (type === 'zs') {
      header = [
        () => (
          <div className='h-full flex items-center w-[540px]'>
            {Array.from({ length: 17 }, (v, k) => k + 3).map((item, index) => (
              <div className='flex-1 flex justify-center items-center' key={index.toString()}>
                {item}
              </div>
            ))}
          </div>
        ),
      ]
    } else {
      header = [
        () => (
          <div className='h-full flex items-center w-full'>
            {['和', '大', '小', '单', '双'].map((item, index) => (
              <div className='flex-1 flex justify-center items-center' key={index.toString()}>
                {item}
              </div>
            ))}
          </div>
        ),
      ]
    }
  } else {
    if (type === 'zs') {
      header = [
        () => <div className='h-full w-full flex justify-center items-center'>开奖号码</div>,
        () => (
          <div className='h-full flex items-center w-[540px]'>
            {Array.from({ length: 6 }, (v, k) => k + 1).map((item, index) => (
              <div className='flex-1 flex justify-center items-center' key={index.toString()}>
                {item}
              </div>
            ))}
          </div>
        ),
      ]
    } else {
      header = [
        () => (
          <div className='h-full flex items-center w-full'>
            {['大', '小', '单', '双'].map((item, index) => (
              <div className='flex-1 flex justify-center items-center' key={index.toString()}>
                {item}
              </div>
            ))}
          </div>
        ),
      ]
    }
  }
  return header ?? []
}

const generateMobileShuBanRow = (ball: number, type: string) => {
  let row
  if (ball === 0) {
    if (type === 'zs') {
      row = [
        (item: IShuBanItem) => (
          <div className='h-full flex items-center w-[540px]'>
            {item.zs.slice(0, 17).map((data, index) => {
              const cls = 1 * data > 0 ? 'ball' : 'yilou'
              return (
                <div className='flex-1 flex justify-center items-center' key={index.toString()}>
                  {' '}
                  <div className={cls}>{Math.abs(data)}</div>
                </div>
              )
            })}
          </div>
        ),
      ]
    } else {
      row = [
        (item: IShuBanItem) => (
          <div className='h-full flex items-center w-full'>
            {[
              <div className='flex-1 flex justify-center items-center' key='zh'>
                {item?.zh}
              </div>,
            ].concat(
              item.zs.slice(17, 21).map((itm, j) => {
                const b = 1 * itm > 0
                const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou'
                const txt = b
                  ? j == 0
                    ? '大'
                    : j == 1
                      ? '小'
                      : j == 2
                        ? '单'
                        : '双'
                  : Math.abs(itm)
                return (
                  <div
                    key={j.toString()}
                    className={`${cls} flex-1 flex justify-center items-center`}
                  >
                    {txt}
                  </div>
                )
              }),
            )}
          </div>
        ),
      ]
    }
  } else {
    if (type === 'zs') {
      row = [
        (item: IShuBanItem) => (
          <div className='h-full w-full flex justify-center items-center'>
            {GenerateChannelResult({ draw_code: item?.balls ?? '' } as ILatestItem, '0')}
          </div>
        ),
        (item: IShuBanItem) => (
          <div className='h-full flex items-center w-[540px]'>
            {item.zs.slice(0, 6).map((data, index) => {
              const cls = 1 * data > 0 ? 'ball' : 'yilou'
              return (
                <div className='flex-1 flex justify-center items-center' key={index.toString()}>
                  {' '}
                  <div className={cls}>{Math.abs(data)}</div>
                </div>
              )
            })}
          </div>
        ),
      ]
    } else {
      row = [
        (item: IShuBanItem) => (
          <div className='h-full w-full flex items-center'>
            {item.zs.slice(6, 10).map((itm, j) => {
              const b = 1 * itm > 0
              const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou'
              const txt = b ? (j == 0 ? '大' : j == 1 ? '小' : j == 2 ? '单' : '双') : Math.abs(itm)
              return (
                <div
                  className={`${cls} flex-1 flex justify-center items-center`}
                  key={j.toString()}
                >
                  {txt}
                </div>
              )
            })}
          </div>
        ),
      ]
    }
  }

  return row ?? []
}

const YDH = {
  GenerateNumber,
  killPlanNumber,
  homeTableHeader: [
    { title: '1-3龙虎', Colspan: 3 },
  ],
  homeTableRowItem: [
    {
      wrapperStyle: '',
      generateData: (obj: ILatestItem) => generateLH(obj, 0),
    },
    {
      wrapperStyle: '',
      generateData: (obj: ILatestItem) => generateLH(obj, 1),
    },
    {
      wrapperStyle: '',
      generateData: (obj: ILatestItem) => generateLH(obj, 2),
    }
  ],
  subMenuList: [
    { label: 'CHANNEL_HOME', menuCode: 'CHANNEL_HOME' },
    { label: 'H_NUMBER_TREND', menuCode: 'H_NUMBER_TREND' },
    { label: 'V_NUMBER_TREND', menuCode: 'V_NUMBER_TREND' },
    { label: 'HOT_NUMBER', menuCode: 'HOT_NUMBER' },
    { label: 'NUMBER_MISSING', menuCode: 'NUMBER_MISSING' },
    { label: 'ZH_LUZHU', menuCode: 'ZH_LUZHU' },
    { label: 'DS_LUZHU', menuCode: 'DS_LUZHU' },
    { label: 'DX_LUZHU', menuCode: 'DX_LUZHU' },
    { label: 'LH_LUZHU', menuCode: 'LH_LUZHU' },
    // { label: 'RULES', menuCode: 'RULES' },
  ],
  //chart and killPlan
  trendSubMenu: [
    { label: '冠军', value: 1 },
    { label: '亚军', value: 2 },
    { label: '第三名', value: 3 },
    { label: '第四名', value: 4 },
    { label: '第五名', value: 5 },
    { label: '第六名', value: 6 },
  ],
  hotAndMissingNumber: [
    { label: '号码1', value: 1 },
    { label: '号码2', value: 2 },
    { label: '号码3', value: 3 },
    { label: '号码4', value: 4 },
    { label: '号码5', value: 5 },
    { label: '号码6', value: 6 },
  ],
  H_V_NumberTrend: [
    { label: '号码1', value: 1, rank: '冠军' },
    { label: '号码2', value: 2, rank: '亚军' },
    { label: '号码3', value: 3, rank: '第三名' },
    { label: '号码4', value: 4, rank: '第四名' },
    { label: '号码5', value: 5, rank: '第五名' },
    { label: '号码6', value: 6, rank: '第六名' },
  ],
  ChannelDrawResult: {
    web: {
      header: [
        [
          { label: '显示号码', value: '0' },
          { label: '显示大小', value: '1' },
          { label: '显示单双', value: '2' },
        ],
        [{ label: '1-3龙虎', value: 'lh' }],
      ],
      rowProperty: [
        (obj: ILatestItem, index: string) => {
          return (
            <div className=' h-full w-full flex justify-center items-center'>
              {GenerateChannelResult(obj, index)}
            </div>
          )
        },
        (obj: ILatestItem) => {
          return <div className=' h-full w-full flex cellWrapper'>{generateChannleLH(obj)}</div>
        },
      ],
    },
    mobile: {
      header: [
        { label: '号码', value: '0' },
        { label: '大小', value: '1' },
        { label: '单双', value: '2' },
        { label: '龙虎', value: '3' },
      ],
      rowProperty: (obj: ILatestItem, index: string, isRndNum?: number) => {
        return (
          <div className=' h-full w-full flex justify-center items-center'>
            {GenerateMobileChannelResult(obj, index, isRndNum)}
          </div>
        )
      },
    },
  },
  ShuBanHaoMaTrend: {
    web: {
      header: generateWebShuBanHeader,
      rowProperty: generateWebShuBanRow,
    },
    mobile: {
      header: generateMobileShuBanHeader,
      rowProperty: generateMobileShuBanRow,
    },
  },
}

export default YDH
