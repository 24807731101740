/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect } from 'react-redux';
import { TouchEvent, useState } from 'react';

import iosDownload from '../../../assets/download/img-i-os@2x.png';
import iosTutorial1 from '../../../assets/download/img-jiaocheng-01@2x.png';
import iosTutorial2 from '../../../assets/download/img-jiaocheng-02@2x.png';

import androidDownload from '../../../assets/download/img-android@2x.png';
import androidTutorial1 from '../../../assets/download/img-jiaocheng-03@2x.png';
import androidTutorial2 from '../../../assets/download/img-jiaocheng-04@2x.png';

import { motion } from 'framer-motion';

import Up from '../../../assets/download/166-Double-up.png';

import Mask2 from '../../../assets/download/img/safair_banner_new.png';
import Mask3 from '../../../assets/download/img/safari-tip_banner_en.png';
import Mask4 from '../../../assets/download/img/andriod_banner_new.png';

import { useNavigate } from "react-router-dom";
import './styles.scss';

let platform = ""
const is_x_port = false;

const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 700 },
}

function DownLoad (){
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let browser = "";  

  const getCookie = (name:string) => {
    const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    const arr = document.cookie.match(reg);
    if (arr)
      return decodeURI(arr[2]);
    else
      return null;
  }

  const GetQueryString = (name:string) => {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const r = window.location.search.slice(1).match(reg);//search,查询？后面的参数，并匹配正则
    if (r != null) return decodeURI(r[2]);
    return null;
  }

  // 获取UDID
  const getUdid = () => {
    let udid = GetQueryString("UDID");

    if (udid == null || udid == "null") {
      udid = getCookie("UDID");
    }
    return udid;
  }

  const queryPlatform = ()=> {
    const ua = navigator.userAgent.toLowerCase();
    const udid = getUdid();
    let udidLen = 0;
    if (udid) { udidLen = udid.length; }
    if (/(android)/.test(ua) || /xiaomi/.test(ua) || /vivo/.test(ua) || /huawei/.test(ua)) {  //判断Android
      platform = 'android';
      if (ua.indexOf('micromessenger') == -1) {
        browser = "system";
      } else {
        browser = "other";
        setShowMask('mask4')
      }
      return 0;
    }
    if (/(iphone|ipad|ipod|ios|macintosh)/.test(ua) || udidLen == 36) { //判断iPhone|iPad|iPod|iOS
      platform = 'ios';
      if ((/safari/.test(ua) && !/crios/.test(ua) && !/chrome/.test(ua) && !/mqqbrowser/.test(ua)) || udidLen == 36) {
        browser = "safari";
      }  else if (/qqbrowser/.test(ua)) {
        browser = "qqbrowser";
        setShowMask('mask3')
      } else if (ua.indexOf('micromessenger') > -1
          || ua.indexOf('weibo') > -1
          || ua.indexOf('alipay') > -1
          || ua.indexOf('dingtalk') > -1) {
        browser = "wechat";
        if (is_x_port) {
          setShowMask('mask3')
        } else {
          setShowMask('mask2')
        }
      } else {
        browser = "chrome";
        setShowMask('mask3')
      }
      return 1;
    } else {
    // browser = "system";
      if (screen && (screen.width > screen.height)) {
      // $('.ua-debug').remove();
      }
      platform = 'pc'
    }
    return 2;
  }

  queryPlatform();

  const Nav = useNavigate();

  const [showMask, setShowMask] = useState('');

  // for download tutorial
  const [isVisible, setisVisible] = useState(false);
  const [startPoint, setStartPoint] = useState(0);

  const OnTouchOutside = (event:TouchEvent<HTMLDivElement>)=> {
    const endPoint = event.changedTouches[0].pageY;
    if(!isVisible && endPoint - startPoint < 0){
      setisVisible(true)
    }
    if(isVisible){
      setisVisible(false)
    }
    return setStartPoint(0);
  }

  const OnTouchInside = (endY:number)=> {
    if(isVisible && endY - startPoint > 60){
      setisVisible(false)
    }
    return setStartPoint(0);
  }

  const onClickMask3Copy = ()=>{
    const data = window.location.href
    navigator.clipboard.writeText(data);
    // Alert the copied text
    alert("复制成功,请用Safari浏览器打开链接");
  }
  
  return ( 
    <div className='downloadWrapper relative'>
      <div className='download_page_top relative' onTouchStart={e=>setStartPoint(e.changedTouches[0].pageY)}  onTouchEnd={e=>OnTouchOutside(e)} >
        <div className=' absolute bottom-[10px] left-1/2 -translate-x-1/2'>
          <a className='downloadAppBtn mx-auto' href={platform == "ios" ? "https://yzg1z.chuntianyy.com/nguxodaxmt" : 'https://mgyyy.glftpontr.cc/nguxodaxmt'} target='_blank' rel="noreferrer" >
           APP下载
          </a>
          <div className='downloadAppBtn mx-auto mt-[20px]' onClick={()=> Nav('/') }>
          进入网页版
          </div>
          <div className='mx-auto  text-[#00a4ff] mt-[30px] flex flex-col justify-center items-center'>
            <img src={Up} className='w-[20px]' alt="" srcSet="" />
            <div className='mt-[5px]'>上滑查看安装教学</div>
          </div>
        </div>
      </div>

      <motion.div
        initial={{ bottom:0, y:550, opacity:0 }}
        variants={variants}
        animate={isVisible ? "open" : "closed"}
        transition={{ duration:0.5}}
        dragSnapToOrigin
        drag="y"
        onDragStart={
          (event, info) => setStartPoint(info.point.y)
        }
        onDragEnd={
          (event, info) => OnTouchInside(info.point.y)
        }
        className='download_page_bottom px-[20px]  rounded-t-[10px] pt-[8px]' >
        <div className=' mx-auto mb-[8px] w-[38px] h-[5px] rounded-[3px] bg-[#d7d7d7]' ></div>
        {platform == "ios" ?
          <div>
            <img src={iosDownload} alt="" srcSet="" />
            <div className='installDesc'>
          需在同一网络环境下下载安装和注册，勿切换网络若无法正常下载，请使用手机自带浏览器打开（Chrome谷歌浏览器、Safari浏览器）
            </div>
            <div className='installStep'>
              <div>1. APP 下载完成后，请打开「设置」&gt;点选「通用」选项</div>
              <div>2. 点选「设备管理」</div>
              <div>3. 点击「信任」APP 即完成设置</div>
            </div>
            <div className='installDescImage'>
              <img src={iosTutorial1} alt="" srcSet="" />
              <img src={iosTutorial2} alt="" srcSet="" />
            </div>
          </div> : ''
        }
        {platform == "android" ?
          <div>
            <img src={androidDownload} alt="" srcSet="" />
            <div className='installDesc'>
          需在同一网络环境下下载安装和注册，勿切换网络若无法正常下载，请使用手机自带浏览器打开（Chrome谷歌浏览器、Safari浏览器）
            </div>
            <div className='installStep'>
              <div>1. 点击本页的「APP下载」选择「本地下载」</div>
              <div>2. APP下载完成后，选择允许安装应用，即可完成安装</div>
            </div>
            <div className='installDescImage'>
              <img src={androidTutorial1} className='h-[150px]' alt="" srcSet="" />
              <img src={androidTutorial2} className='h-[150px]' alt="" srcSet="" />
            </div>
          </div> : ''
        }
      </motion.div>

      { showMask === 'mask2' &&
      <div className={`mask2`}>
        <img src={Mask2} />
      </div>
      }

      { showMask === 'mask3' &&
      <div className={`mask3`}>
        <div ><img src={Mask3} /></div>
        <div className=' mx-auto text-center flex flex-col justify-center items-center' >
          <div className='my-[20px] font-bold  text-[20px] text-white '>{window.location.href}</div>
          <div className=" rounded-[5px] px-[20px] py-[5px] text-white bg-[#00a4ff] " onClick={()=> onClickMask3Copy() } >复制</div>
        </div>
      </div>
      }

      { showMask === 'mask4' &&
      <div className={`mask4`}>
        <img src={Mask4} />
      </div>
      }

    </div>
  );
}

export default connect(null, null)(DownLoad);
