import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';

import './styles.scss';
import {ILatestItem, ILotteryList} from '../../../types/index';

import templates from '../../../constants/templates';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import appActions from '../../../redux/actions/appActions';
import dayjs from 'dayjs'

import defaultLogo from '../../../assets/image/defaultLogo.png';

const mapStateToProps = (state:RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
});

interface IProps{
  lotteryListData:ILotteryList,
  current:string, 
  target:string, 
  item:ILatestItem,
  showLink?:boolean
}


function HomeDrawItem ({lotteryListData, current, target, item, showLink = true}:IProps){   
  const { t } = useTranslation();  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isRndNum, setIsRndNum] = useState(0);
  const [timeResult, setTimeResult] = useState<string | number>()  

  const [gameImage, setGameImage] = useState();
  useEffect(()=>{
    if(item?.code){
      setIsRndNum(lotteryListData.lotterys.find(x=>x.code === item.code)?.isRndNum ?? 0)
      loadImage(item.code);
    }
  },[item.code])

  const loadImage = (imageName:string) => {
    import(`../../../assets/image/${imageName}.png`).then(image => {        
      setGameImage(image.default)
    }).catch(()=>{
      setGameImage(undefined)
    });
  };

  function getDifferenceTime(current:string, target:string){
    const targetTime = dayjs(target).valueOf();//获取当前时间对应的毫秒数
    const currentTime = Date.parse(current);//获取八点对应的毫秒数    
    const differenceTime = Math.floor((targetTime - currentTime) / 1000);
    
    if(differenceTime <= 0){
      return differenceTime
    }else{
      const hours = Math.floor(differenceTime / 3600);
      const minutes = Math.floor((differenceTime - (hours * 3600)) / 60);
      const seconds = differenceTime - hours * 3600 - minutes * 60
      return `${hours.toString().padStart(2,"0")}:${minutes.toString().padStart(2,"0")}:${seconds.toString().padStart(2,"0")}`
    }   
  }
  
  const onItemClick = (subMenu?:string)=>{
    const le6Url = process.env.REACT_APP_LE6_URL ?? 'https://le66.me/lottery/';
    if(item?.template === 'HK6' && le6Url){
      return window.open(`${le6Url}${item.code.toLocaleLowerCase()}`,'_blank');
    }
    if(showLink === true && templates[`${item.template}`] && templates[`${item.template}`].subMenuList){
      if(subMenu){
        dispatch(appActions.setSubSelection(subMenu));
      }
      else{        
        dispatch(appActions.setSubSelection((templates[(item.template as keyof typeof templates )].subMenuList!)[0].menuCode));
      }
      dispatch(appActions.setTemplateAndCode({template:item.template ?? '',code:item.code}));
      return navigate('/lottery');
    }
  }

  useEffect(()=>{
    setTimeResult(getDifferenceTime(current,target))
  },[current,target])  
  
  return ( 
    <div className='homeDrawItem flex items-center' onClick={()=>onItemClick()}>
      <img className='homeGameImage' src={gameImage || defaultLogo} alt="图片缺失" srcSet="" />
      <div className='flex-1 pl-4 h-full'>
        <div className='flex  w-full'>
          { showLink 
            ? 
            <>
              <div className=' text-[24px] font-bold flex items-center min-w-[160px]'>{item.name}</div>
              <div className='pl-4 flex-1'>
                <div className='text-[18px]'>
                  <span className='installmentWrapper'>{`${item.issue}期`}</span>
                  {
                    item?.group === 1000 &&
              <>
                <span className='pl-4 text-[#3B3B3B]'>{`当前${item.index}期,`}</span>
                <span className='pl-4 text-[#3B3B3B]'>{`剩${item.openCount - item.index}期`}</span>
              </>
                  }
                </div>
                {item?.draw_code && templates[`${item.template}`] &&
                <div className={`flex my-2 ${templates[`${item.template}`].templateWrapper}`}>
                  {templates[`${item.template}`].GenerateNumber(item).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
                    return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
                  }
                  ) }
                  {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>挂码：{item?.rnd}</div>}
                </div>
                }
              </div>
            </>
            :
            <div>
              <div className='flex items-center'>
                <div className=' text-[24px] font-bold flex items-center min-w-[160px]'>{item.name}</div>
                <div className='text-[18px] pl-4'>
                  <span className='installmentWrapper'>{`${item.issue}期`}</span>
                  {
                    item?.group === 1000 &&
              <>
                <span className='pl-4 text-[#3B3B3B]'>{`当前${item.index}期,`}</span>
                <span className='pl-4 text-[#3B3B3B]'>{`剩${item.openCount - item.index}期`}</span>
              </>
                  }
                </div>
              </div>
              {item?.draw_code && templates[`${item.template}`] &&
                <div className={`flex my-2 ${templates[`${item.template}`].templateWrapper}`}>
                  {templates[`${item.template}`].GenerateNumber(item).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
                    return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
                  }
                  ) }
                  {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>挂码：{item?.rnd}</div>}
                </div>
              }
            </div>
          }

          <div className=' ml-auto'>
            <>
              <div className=' text-center'>开奖到计</div>
              <div className=' text-[24px]  font-bold text-center'>       
                {
                  typeof timeResult === 'number' ?
                    <div className='flex'>开奖中 <div className="dots-1"></div></div>
                    :
                    timeResult
                }
              </div>
            </>
          </div>
        </div>
        { templates[`${item.template}`] && templates[`${item.template}`].homeTableHeader &&
          <div className='w-full mt-3'>
            <table className='homeTable'>
              <thead>
                <tr>{(templates[`${item.template}`].homeTableHeader || []).map((item)=> <th className={item?.className} key={`${item.title}`} colSpan={item.Colspan}>{item.title}</th> )}</tr>
              </thead>
              <tbody>
                <tr>
                  {
                    (templates[`${item.template}`].homeTableRowItem || []).map( (data,index:number) => 
                      <td key={index}>
                        <div className={`${index}`}>
                          {
                            data.generateData(item).map((item:{value:string | number,className:string}, index:number)=> <div key={index} className={item.className}>{item.value}</div> )
                          }
                        </div>
                      </td> )
                  }
                </tr>
              </tbody>
            </table>          
          </div>
        }
        { showLink && templates[`${item.template}`] && templates[`${item.template}`].subMenuList &&
          <div className='w-full flex flex-wrap mt-3'>
            {
              (templates[`${item.template}`].subMenuList as {[key:string]:string}[]).map((item,index, arr)=>
                <div onClick={(e)=> {e.stopPropagation();onItemClick(item.menuCode)}} className='menuItem mx-1' key={index}>{`${t(item.label)} ${index === arr.length - 1 ? '' : '/'}`}&nbsp;</div>
              )
            }       
          </div>
        }
      </div>
    </div>
  );
}
export default connect(mapStateToProps, null)(HomeDrawItem);
