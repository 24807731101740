import { SET_LOTTERY_NEWS, SET_NOTICE,SET_LOTTERY_NEWS_ID } from '../../actionTypes';
import { AnyAction } from 'redux'


interface AppState {
  lotteryNews: [],
  notice:{title:string,content:string}[],
  lotteryNewsId:string
}

const initialState:AppState = {
  lotteryNews: [],
  notice:[],
  lotteryNewsId:''

};

const noticeReducers = (state = initialState, action:AnyAction) => {
  switch (action.type) {
    case SET_LOTTERY_NEWS:
      return {
        ...state,
        lotteryNews: action.value
      };
    case SET_LOTTERY_NEWS_ID:
      return {
        ...state,
        lotteryNewsId: action.value
      };
    case SET_NOTICE:
      return {
        ...state,
        notice: action.value
      };

    default:
      return state;
  }
};

export default noticeReducers;