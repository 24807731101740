
import { ReactComponent as LOTTERY_HOME } from '../../../assets/mobile/image/linkIcons/LOTTERY_HOME.svg';
import { ReactComponent as PLAN_RECOMMENDATION } from '../../../assets/mobile/image/linkIcons/PLAN_RECOMMENDATION.svg';
import { ReactComponent as CHANG_LONG_REMINDER } from '../../../assets/mobile/image/linkIcons/CHANG_LONG_REMINDER.svg';
import { ReactComponent as TREND_CHART } from '../../../assets/mobile/image/linkIcons/TREND_CHART.svg';

import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useState, useEffect } from 'react';
import noticeActions from '../../../redux/actions/noticeActions';
import lotteryActions from '../../../redux/actions/lotteryActions';
import MobileHomeDrawItem from '../../../components/Mobile/MobileHomeDrawItem';
import Marquee from 'react-fast-marquee';
import { useNavigate } from 'react-router-dom';

import { ILatestItem, ILotteryList, ILotteryItem } from '../../../types';
import { Carousel } from 'antd';

const mapStateToProps = (state:RootState) => ({
  lotteryNews: state.noticeReducers.lotteryNews,
  allLatestResult: state.lotteryReducers.allLatestResult,
  lotteryListData: state.lotteryReducers.lotteryList,
  notice: state.noticeReducers.notice,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  GetLotteryNews: (top = 10) => dispatch(noticeActions.GetLotteryNews(top)),
  GetAllLatestResult: () => dispatch(lotteryActions.GetAllLatestResult()),
});
interface ICategoryItem {
  label:string,
  value:number
}

type IconTypes = { [name: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
}> };
interface IProps{
  lotteryListData:ILotteryList, 
  allLatestResult:ILatestItem[],
  GetAllLatestResult:()=>void,
  notice:{
    title:string,
    content:string
  }[]
}

const categoryList = [5,6]

function MobileHome ({lotteryListData,allLatestResult,GetAllLatestResult, notice}:IProps){
  const iconTypes: IconTypes = { LOTTERY_HOME: LOTTERY_HOME, PLAN_RECOMMENDATION: PLAN_RECOMMENDATION,CHANG_LONG_REMINDER:CHANG_LONG_REMINDER,TREND_CHART:TREND_CHART };
  const { t } = useTranslation();  
  const [clockTimer, setClockTimer] = useState<string>(new Date().toString())
  const [lotteryData, setLotteryData] = useState(new Map<string,ILotteryItem[]>());
  const [currentSelectedList, setCurrentSelectedList] = useState<ILatestItem[]>();
  const [categoryIndex, setCategoryIndex] = useState<number | undefined>();
  const [categoryArray, setCategoryArray] = useState<ICategoryItem[]>([]);
  const navigate = useNavigate();

  useEffect(()=>{
    GetAllLatestResult();
    const clockId = setInterval(
      (): void => {
        setClockTimer(new Date().toString())
      },
      1000
    );
    return () => {
      clearInterval(clockId);
    };
  },[])

  useEffect(()=>{
    const _timerId = setInterval(
      (): void => {
        GetAllLatestResult()
      },
      5000
    );
    return () => {
      clearInterval(_timerId);
    };
  },[])

  useEffect(()=>{
    if(lotteryListData.lotterys.length > 0 && lotteryListData.categorys.length > 0){
      const temp = (lotteryListData.lotterys as ILotteryItem[]).reduce((a:{[key:string]:ILotteryItem[]},b:ILotteryItem)=>{
        const categoryArr = b.category.split(',');
        for (let index = 0; index < categoryArr.length; index++) {
          const categoryCode = categoryArr[index];
          if(a[categoryCode]){
            a = {...a,[categoryCode]:a[categoryCode].concat(b)}
          }
          else{
            a =  {...a,[categoryCode]:[b].concat([])}
          }  
        }
        return a;
      },{})
      
      const dataInOrder = new Map<string,ILotteryItem[]>()
      categoryList.forEach(item=> {
        const tempItem = temp[`${item}`];     
        if(tempItem){
          dataInOrder.set(`${item}`,tempItem);
          delete temp[`${item}`];          
        }
      })
      Object.entries(temp).forEach(([key,value])=> dataInOrder.set(key, value) )    
      if(dataInOrder.size > 0){
        const tempCategory:Array<ICategoryItem> = [];
        dataInOrder.forEach((value,key)=>{
          tempCategory.push({value:Number(key),label:lotteryListData.categorys.find(x=>x.value === Number(key))?.label ?? ''})
        })
        setCategoryArray(tempCategory)
        setLotteryData(dataInOrder);
        setCategoryIndex(Number(dataInOrder.keys().next().value))
      }
    }
  },[lotteryListData])

  useEffect(()=>{
    if(allLatestResult && lotteryListData && categoryIndex){
      const tempArr:ILatestItem[] = [];
      const tempData = lotteryData.get(`${categoryIndex}`)  
      if(tempData){    
        tempData.forEach(item=>{
          const temp:ILatestItem | undefined = allLatestResult.find(x=>x.code === item.code);
          if(temp){
            tempArr.push({...temp, category:item.category, name:item.name,openCount:item.openCount ?? 0,template:item.template, group:item.group})
          }
        })
      }
      setCurrentSelectedList(tempArr)
    }
  },[allLatestResult,lotteryListData, categoryIndex])  

  const onClickLink = (path:string)=>{
    navigate(path)
  }

  return ( 
    <div className='mobileHomeWrapper'>
      {
        notice?.length > 0 && <div className='noticeWrapper'>
          <Marquee gradient={false} className='MarqueeWrapperStyle' >
            {`${notice[0].title} ${notice[0].content}`}
          </Marquee>
        </div>
      }
      <div className='mobileBannerWrapper'>
        <Carousel autoplay>
          {
            [1,2,3].map((item)=>{
              let lotteryImage;
              try {
                lotteryImage = require(`../../../assets/banner_mobile_${item}.png`)
              } catch (error) {
                lotteryImage = ''
              }
              return <img key={item} src={lotteryImage} className='w-full rounded-[12px]' alt="" srcSet="" />
            })
          }
        </Carousel>
      </div>
      <div className='menuWrapper'>
        {
          [{label:'LOTTERY_HOME',path:'/lottery'},{label:'PLAN_RECOMMENDATION',path:'/plan'},{label:'CHANG_LONG_REMINDER',path:'/changlong'},{label:'TREND_CHART',path:'/chart'}].map(item=> { 
            const Icon = iconTypes[item.label];
            return <div  key={item.label} className=' flex flex-col justify-center items-center h-full' onClick={()=>onClickLink(item.path)} >
              <Icon />
              <div className=' text-[#6E6E6E] mt-2'>{t(item.label)}</div>
            </div>
          } )
        }
      </div>
      <div className='flex mobileHomeCategoryRow'>
        {
          lotteryData.size > 0 && categoryArray.map((obj:ICategoryItem,index)=> 
            <div 
              onClick={()=>setCategoryIndex(obj.value)} 
              className={`mx-2 text-[#3B3B3B] ${obj.value === categoryIndex ? 'selected' : ''}`} 
              key={index}>
              {obj.label}
            </div> )
        }
      </div>
      <div className='flex-1 overflow-y-auto'>
        {
          currentSelectedList && currentSelectedList.map((item,index)=> 
            <div  key={`${item}_${index}`} className=' my-[15px] mx-[5px] relative'>
              <MobileHomeDrawItem item={item} current={clockTimer} target={item.next_time} />
            </div>
          )
        }
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHome);
