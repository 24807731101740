import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appActions from '../../../redux/actions/appActions';
import { useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';

import {ILotteryList, ILotteryItem} from '../../../types/index';

import './styles.scss';

const mapStateToProps = (state:RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
});
interface ICategoryItem {
  label?:string,
  value?:number
}

interface IProps{
  lotteryListData:ILotteryList
}

function LotteryList ({lotteryListData}:IProps){   
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [lotteryData, setLotteryData] = useState<{[key:string]:ILotteryItem[]}>({});
  const [category, setCategory] = useState(new Map());

  useEffect(()=>{
    if(lotteryListData?.lotterys){
      const temp = (lotteryListData?.lotterys as ILotteryItem[]).filter(x=>x.template !== 'HK6').reduce((a:{[key:string]:ILotteryItem[]},b:ILotteryItem)=>{
        
        const categoryArr = b.category.split(',');
        for (let index = 0; index < categoryArr.length; index++) {
          const categoryCode = categoryArr[index];
          if(a[categoryCode]){
            a = {...a,[categoryCode]:a[categoryCode].concat(b)}
          }
          else{
            a =  {...a,[categoryCode]:[b].concat([])}
          }  
        }
        return a;
      },{})
      setLotteryData(temp);
      const tempMap = new Map(lotteryListData.categorys.map((obj:ICategoryItem) => [ obj.value,obj.label]));
      setCategory(tempMap)
    }
  },[lotteryListData])

  const onClickToNavigate = (x:ILotteryItem)=>{
    dispatch(appActions.setTemplateAndCode({template:x.template,code:x.code}));
    navigate('/lottery');
  }
   
  return ( 
    <div className='webLotteryList w-screen'>
      {
        Object.entries(lotteryData).map(
          ([key,value])=> 
            <div key={`${key}`} className='p-[15px] flex border-b border-[#dcdcdc]'>
              <div className='categoryName'>{category.get(Number(key))}</div>
              <div className='flex flex-1 flex-wrap items-center'>
                {
                  value.map(x=>  <div onClick={()=>onClickToNavigate(x)} className={` mx-4 lotteryItem`} key={`${x.name}`}>{`${x.name}`}</div> )
                }
              </div>
            </div> 
        )
      }
    </div>
  );
}
export default connect(mapStateToProps, null)(LotteryList);
