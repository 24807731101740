import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import './styles.scss';
import appActions from '../../../redux/actions/appActions';
import { AppDispatch, RootState } from '../../../redux/store';
import { CheckOutlined } from '@ant-design/icons';
import { CaretDownOutlined } from '@ant-design/icons/lib/icons';
import LotteryList from '../../../components/Web/LotteryList';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Marquee from 'react-fast-marquee';

import Logo from '../../../assets/image/common/companyLogo.png';
import NoticeIcon from '../../../assets/noticeIcon.png';
import PlanLotteryList from '../../../components/Web/PlanLotteryList';

const mapStateToProps = (state:RootState) => ({
  theme: state.appReducers.theme,
  notice: state.noticeReducers.notice,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  setTheme: (value:string) => dispatch(appActions.setTheme(value)),
});
interface IProps{
  theme:string,
  setTheme:(value:string)=>void,
  notice:{
    title:string,
    content:string
  }[]
}

function WebHeader ({theme,setTheme, notice}:IProps){   
  const { t } = useTranslation();  
  const navigate = useNavigate();
  const toolHeaderList:string[] = ['SET_HOMEPAGE','BOOK_MARK','HELP_CENTER'];
  const [menuOpen, setMenuOpen] = useState(false)
  const [planMenuOpen, setPlanMenuOpen] = useState(false)
  const location = useLocation();

  const onClickLink = (path:string)=>{
    navigate(path)   
  }

  const onToolHeaderClick = (type:string)=>{
    if(type === 'HELP_CENTER'){
      navigate('/help')
    }
  }
  
  return ( 
    <div className='webHeaderWrapper w-full'>
      <div className='toolHeader w-full flex items-center justify-between'>
        <div className='messageWrapper flex-1'>
          <img src={NoticeIcon} alt="" srcSet="" />
          {
            notice?.length > 0 && 
            <Marquee speed={40} gradient={false} className='MarqueeWrapperStyle' >
              {`${notice[0]?.title} ${notice[0]?.content}`}
            </Marquee>
          }
        </div>
        <div  className=' px-2 relative colorWrapper'>{`${t('CHANGE_SKIN')} /`}
          <div className='colorPopover absolute hidden gap-[10px]  hover:grid  '>
            {['orange','blue','red','green'].map(item=> <div key={item} onClick={()=>{ localStorage.setItem('themeColor', item);setTheme(item)}} className={`colorBlock ${item}`}>{theme === item ? <CheckOutlined /> : ''}</div> )
            }
          </div>
        </div> 
        {
          toolHeaderList.map((item, index, arr)=> <div 
            onClick={()=>onToolHeaderClick(item)}
            key={`${item}`} 
            className='px-2 cursor-pointer'>
            {`${t(item)} ${index !== arr.length - 1 ? '/' : ''}`}
          </div>  )
        }
      </div>
      <div className='menuHeaderNavWrapper'>
        <div className='menuNavHeader w-full flex items-center text-white'>
          <div className='logoName flex justify-center items-center' onClick={()=>window.open('https://166kai.com','_blank')}>
            <img src={Logo} alt="" srcSet="" className=' w-[140px]' />
          </div>
          <div className='flex-1 flex justify-end menuList'>
            <div className={` text-white px-2 mx-3 ${location.pathname === '/' && 'currentSelected'}`} onClick={()=>onClickLink('/')} >
              <div className='flex justify-center items-center'>
                {t('HOME_PAGE')}
              </div>
            </div>
            <div className={` text-white px-2 mx-3 ${location.pathname === '/lottery' && 'currentSelected'}`} 
              onClick={()=>setMenuOpen(false)} 
              onMouseOver={()=>setMenuOpen(true)} 
              onMouseOut={()=>setMenuOpen(false)} >
              <div className='flex justify-center items-center'>
                {t('LOTTERY_HOME')} 
                <CaretDownOutlined />
                <div className={`absolute ${menuOpen ? 'lotteryListWrapper' : 'hidden'}`}>
                  <LotteryList />
                </div>
              </div>
            </div> 
            <div className={` text-white px-2 mx-3 ${location.pathname === '/plan' && 'currentSelected'}`}
              onClick={()=>setPlanMenuOpen(false)} 
              onMouseOver={()=>setPlanMenuOpen(true)} 
              onMouseOut={()=>setPlanMenuOpen(false)} 
            >
              <div className='flex justify-center items-center'>
                {t('PLAN_RECOMMENDATION')} 
                <CaretDownOutlined />
                <div className={`absolute ${planMenuOpen ? 'lotteryListWrapper' : 'hidden'}`}>
                  <PlanLotteryList />
                </div>
              </div>
            </div> 
            <div className={` text-white px-2 mx-3 ${location.pathname === '/changlong' && 'currentSelected'}`} onClick={()=>onClickLink('/changlong')} >
              <div className='flex justify-center items-center'>
                {t('CHANG_LONG_REMINDER')}
              </div>
            </div>
            <div className={` text-white px-2 mx-3 ${location.pathname === '/chart' && 'currentSelected'}`} onClick={()=>onClickLink('/chart')} >
              <div className='flex justify-center items-center'>
                {t('TREND_CHART')}
              </div>
            </div>
            <div className={` text-white px-2 mx-3 ${location.pathname === '/apicall' && 'currentSelected'}`} onClick={()=>onClickLink('/apicall')} >
              <div className='flex justify-center items-center'>
                {t('DRAW_CALL')}
              </div>
            </div>
            {/* <div className={` text-white px-2 mx-3`}>
            <div className='flex justify-center items-center'>
              {t('TRIAL_BET')}
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(WebHeader);
