import { RootState } from '../../../redux/store';
import { connect } from 'react-redux';

import './styles.scss';
import { useState, useEffect } from 'react';
import { IKillPlanData, ILotteryItem, IPlanItem } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import dayjs from 'dayjs';
import { Select } from 'antd';
import { apiService } from '../../../services';
import templates from '../../../constants/templates';
import Table from '../../../components/common/Table';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import {header, introduce} from '../../../constants/utils/index';
import appActions from '../../../redux/actions/appActions';

const mapStateToProps = (state:RootState) => ({
  apiData: state.demoReducers.apiData,
  killPlanLotterys: state.lotteryReducers.killPlanLotterys,
});
interface IProps{
  killPlanLotterys:ILotteryItem[], 
}

function MobilePlan ({killPlanLotterys}:IProps){
  const { templateAndGame } = useAppSelector((state) => state.appReducers)
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const dispatch = useAppDispatch();
  const [date, setDate] = useState(dayjs());
  const [count, setCount] = useState(10);
  const [killPlanData, setKillPlanData] = useState<IKillPlanData>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedChildMenu, setSelectedChildMenu] = useState<number>(1);
  const [gameImage, setGameImage] = useState();
  const [clockTimer, setClockTimer] = useState<string>(new Date().toString())
  const [killR,setKillR] = useState<Array<number>>([0, 0, 0, 0, 0]);
  const [total, setTotal] = useState(count);
  const [tableDataIndex, setTableDataIndex] = useState(0);

  const [currentKillPlanLottery, setCurrentKillPlanLottery] = useState<{template:string, code:string}>();

  const getDifferenceTime = (current:string, target = killPlanData?.ntime)=>{
    const targetTime = dayjs(target).valueOf();//获取当前时间对应的毫秒数
    const currentTime = Date.parse(current);//获取八点对应的毫秒数    
    const differenceTime = Math.floor((targetTime - currentTime) / 1000); 
    if(differenceTime <= 0){
      return differenceTime
    }else{
      const hours = Math.floor(differenceTime / 3600);
      const minutes = Math.floor((differenceTime - (hours * 3600)) / 60);
      const seconds = differenceTime - hours * 3600 - minutes * 60
      return `${hours.toString().padStart(2,"0")}:${minutes.toString().padStart(2,"0")}:${seconds.toString().padStart(2,"0")}`
    }   
  }  
  
  const killCalulate = (item:IPlanItem)=>{
    if(item){
      if(tableDataIndex === 0){
        return item.openresult?.length !== 1 ?
          (templates[selectedTemplate].killPlanNumber!)((item as IPlanItem).openresult).map((child, index)=> <div key={`${index}`} className={`${child.className} mx-[3px]`}>{child.value}</div> )
          :
          <div className='flex opacity-50 px-2'>等待开奖</div>    
      }
      return item.planlist[selectedChildMenu - 1].map(function (t) {
        let cls = 'killColortrue',  ktxt = '对';
        if(t === item.openresult[selectedChildMenu - 1]){
          (cls = 'killColorfalse', ktxt = '错')
        }
        if(item.openresult.length === 1){
          (cls = 'opacity-50', ktxt = '-')
        }
        return <>
          <div className={`flex justify-center items-center`}>{`杀:${t}`}</div>
          <div className='mx-3'>/</div>
          <div className={`flex justify-center items-center  ${cls}`}>{`${item.openresult.length === 1 ? '-' : '杀' + ktxt}`}</div>
        </>
      })[tableDataIndex - 1]  
    }
  }

  const loadImage = (imageName:string) => {
    import(`../../../assets/image/${imageName}.png`).then(image => {        
      setGameImage(image.default)
    }).catch(()=>{
      setGameImage(undefined)
    });
  };

  const resultSummaryFunc = ()=>{
    return header[`${templateAndGame.template}`] ? header[`${templateAndGame.template}`].map((item,index)=> 
      <div className='resultSummaryCell' key={index.toString()}>
        <div>{item}</div>
        <div>
          {
            total + '期对' + killR[index] + '期'
          }
        </div>
        <div>
          {
            Math.round( killR[index] / total * 10000) / 100 + '%'
          }
        </div>
      </div> 
    ) : ''
  }

  const onDateChange = (date:Date)=>{
    setKillPlanData(undefined);
    setDate(dayjs(date))
  }

  const getKillPlan = async () => {
    try {
      const res = await apiService.get(`/plan/KillPlan/${companyCode}/${currentKillPlanLottery?.code}/${date.format('YYYY-MM-DD')}/${count}`);
      setKillPlanData(res.data.data); 
    } catch (error) {
      setKillPlanData(undefined)
    }
  }

  useEffect(()=>{
    if(killPlanLotterys?.length > 0 && templateAndGame){
      const temp = killPlanLotterys?.find(x=>x.code === templateAndGame?.code);
      if(temp){        
        setCurrentKillPlanLottery({...templateAndGame})
      }
      else{
        const _item = killPlanLotterys[0];
        setCurrentKillPlanLottery({template:_item.template,code:_item.code});
        dispatch(appActions.setTemplateAndCode({template:_item.template,code:_item.code}));
      }
      return ()=>setCurrentKillPlanLottery(undefined)
    }

  },[templateAndGame, killPlanLotterys])  

  useEffect(()=>{
    if(currentKillPlanLottery){
      getKillPlan();
      const _timerId = setInterval(
        (): void => {
          getKillPlan()
        },
        10000
      );
      const clockId = setInterval(
        (): void => {
          setClockTimer(new Date().toString()
          )
        },
        1000
      );
      return () => {
        clearInterval(clockId);
        clearInterval(_timerId);
      };
    }
  },[date,count, currentKillPlanLottery])

  useEffect(()=>{
    setKillPlanData(undefined);
    if(currentKillPlanLottery){
      loadImage(currentKillPlanLottery.code);
    }
  },[currentKillPlanLottery])

  useEffect(()=>{
    if(currentKillPlanLottery && templates[currentKillPlanLottery.template]?.trendSubMenu){
      setSelectedTemplate(currentKillPlanLottery.template)
      setSelectedChildMenu((templates[currentKillPlanLottery.template]?.trendSubMenu as Array<{label:string;value:number}>)[0]?.value)
    }
  },[currentKillPlanLottery])

  useEffect(()=>{
    const _killR = [0,0,0,0,0];
    if(killPlanData && Object.keys(killPlanData).length > 0){
      const _total = killPlanData.list.length
      killPlanData.list.forEach((item,index)=>{
        if(index === 0 && item.openresult.length === 1){
          setTotal(_total - 1)
        }
        else{
          item.planlist[selectedChildMenu - 1].forEach(function (t, j) {
            if(t !== item.openresult[selectedChildMenu - 1]){
              _killR[j] += 1;
            }
          })
        }
      })
    }
    setKillR(_killR)
  },[killPlanData?.kcount,selectedChildMenu])


  return ( 
    <div className='mobilePlanWrapper'>
      <div className='latestKillPlan flex items-center w-full'>
        {gameImage && <img className='homeGameImage' src={gameImage} alt="" srcSet="" /> }
        <div className='flex-1 ml-2'>
          <div className='flex items-center'>
            <div className=' font-bold'>
              {killPlanLotterys.find(x=>x.code === currentKillPlanLottery?.code)?.name ?? ''}
            </div>
            <div className='ml-2'>
              {`${killPlanData?.list ? killPlanData.list[0].openterm : '--'}期`}
            </div>
            <div className=' font-bold text-center ml-auto'>       
              {
                typeof getDifferenceTime(clockTimer) === 'number' ?
                  <div className='flex'>生成杀号计划中 <div className="dots-1"></div></div>
                  :
                  getDifferenceTime(clockTimer)
              }
            </div>
          </div>
          <div className='flex items-center my-1'>
            <div>{`${killPlanData?.kcount ?? '--'}期`}</div>
            <div className='ml-2'>
              {`总成功概率${Math.round(killR.reduce((a,b)=>a + b) / 5 / total * 10000) / 100 + '%'}`}
            </div>
          </div>

          <div className='flex items-center'>
            <div className='flex'>
              {
                templates[selectedTemplate] && templates[selectedTemplate].killPlanNumber && killPlanData?.list
                  ? 
                  (templates[selectedTemplate].killPlanNumber!)(killPlanData?.list[0].planlist[selectedChildMenu - 1] ?? ['']).map((x,index)=> 
                    <div key={index.toString()} className={`${x.className} mr-2`}>{x.value}</div> 
                  )
                  : 
                  '--'
              }
            </div>
            <div className='childMenu  ml-auto'>
              {
                templates[selectedTemplate] && templates[selectedTemplate].trendSubMenu 
                  ? templates[selectedTemplate].trendSubMenu?.find(x=>x.value === selectedChildMenu )?.label 
                  : ''
              }
            </div>
          </div>
        </div>
      </div>

      <div className='dateAndCount flex items-center text-white'>
        <div className='mr-auto'>稳定杀号</div>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={date.toDate()} 
          maxDate={new Date()}
          onChange={(date)=>{
            if(date){
              onDateChange(date)
            }
          }}
        />
        <Select
          className='ml-2'
          value={count}
          style={{ width: 100 }}
          onChange={(value:number)=>{setKillPlanData(undefined);setCount(value)}}
          options={[
            { value: 10, label: '近10期' },
            { value: 20, label: '近20期' },
            { value: 30, label: '近30期' },
            { value: 50, label: '近50期' },
            { value: 100, label: '近100期' },
          ]}
        />

      </div>

      <div className='killPlanListWrapper'>
        <div className='flex flex-wrap'>
          {
            templates[selectedTemplate] && templates[selectedTemplate].trendSubMenu?.map(item=> 
              <div 
                onClick={()=>setSelectedChildMenu(item.value)}
                className={`buttonWrapper ${selectedChildMenu === item.value ? 'selected' : ''}`}
                key={item.label}>
                {item.label}
              </div> )
          }
        </div>

        <div className='w-full bg-white my-[10px] flex'>
          <div className='resultSummaryCell'>
            <div>成绩统计</div>
            <div>杀对次数</div>
            <div>成功概率</div>
          </div>
          <div className='resultSummary flex'>
            {
              resultSummaryFunc()
            }
          </div>
        </div>

        <div className='flex flex-wrap'>
          {
            templates[selectedTemplate] && ['开奖结果'].concat(header[`${selectedTemplate}`]).map((item, index)=> 
              <div 
                onClick={()=>setTableDataIndex(index)}
                className={`buttonWrapper ${tableDataIndex === index ? 'selected' : ''}`}
                key={index.toString()}>
                {`${index === 0 ? item : item + '/成绩'}`}
              </div> )
          }
        </div>
        <div className='my-[20px]'>
          <Table
            key={`${selectedTemplate}`}
            header = {[
              {label:'开奖期号',className:'flex  items-center h-full'},
              {label:['开奖结果'].concat(header[`${selectedTemplate}`])[tableDataIndex] + (tableDataIndex === 0 ? '' : '/成绩'),className:'flex items-center h-full'},
            ]}
            contentProperty = {[
              item=> <div className='flex'>{(item as IPlanItem).openterm}</div>,
              item=> <div className='flex items-center w-full'>
                {
                  killCalulate(item as IPlanItem)
                }
              </div>,
            ]}
            contentData = {killPlanData?.list || []}
            loading = {killPlanData ? false : true}
          />
        </div>


        {
          introduce[`${selectedTemplate}`] && introduce[`${selectedTemplate}`].map((item, index)=> 
            <div className='w-full mb-[10px] introduceCell' key={index}>
              <div>{item.title}</div>
              <div>{item.content}</div>
            </div> 
          )
        }

      </div>

    </div>
  );
}


export default connect(mapStateToProps, null)(MobilePlan);
