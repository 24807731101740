import {ILatestItem} from '../../../types/index';

const GenerateNumber = (obj:ILatestItem)=>{
  return (obj.draw_code.split(',') as string[]).map(item=>{
    return ({value:'',imageURL: require(`../../../assets/image/KS/${item}.png`),className:`KS _${item}`})}
  )
}

const generateZH = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zh as string, className:''}]

}
const generateZDX = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zdx as string, className:''}]
}

const generateZDS = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zds as string, className:''}]
}

export const killPlanNumber = (data:Array<string>)=>{
  return data.map(item=>({value:item,className:`KS`}))
}

const generateLabel = (item:string) => {
  switch (item) {
    case '1':
      return '鱼';
    case '2':
      return '虾';
    case '3':
      return '葫芦';
    case '4':
      return '金钱';
    case '5':
      return '螃蟹';
    case '6':
      return '鸡';
    default:
      return '';
  }
};

const generateYXX = (obj:ILatestItem,index:number)=>{
  return [{value:generateLabel(obj.draw_code.split(',')[index]), className:''}]
}

export const GenerateChannelResult = (obj:ILatestItem,index:string)=>{
  const arr:Array<string> = obj.draw_code ? obj.draw_code.split(',') : []
  if(index === '0'){
    return arr.map((item,index)=>(
      <div key={index.toString()} className='h-[48px]'><img className='KS' src={require(`../../../assets/image/KS/${item}.png`)} alt="" /></div>
    ))
  }
  else if(index === '1'){
    return arr.map(item=>(
      <div key={index.toString()} className={`KS ${Number(item) > 3 ? 'dx_ds_yellow' : 'dx_ds_grey'}`}>{Number(item) > 3 ? '大' : '小'}</div>
    ))   
  }
  else if(index === '2') {
    return arr.map(item=>(
      <div key={index.toString()} className={`KS ${Number(item) % 2 === 1 ? 'dx_ds_yellow' : 'dx_ds_grey'}`}>{Number(item) % 2 === 1 ? '单' : '双'}</div>
    ))  
  }
}

export const GenerateChannelZH = (obj:ILatestItem)=>{
  return [obj.trend?.zh,obj.trend?.zdx,obj.trend?.zds].map((item,index)=>(
    <div key={index.toString()} className={`h-full flex-1 flex justify-center items-center ${( item === '大' || item === '单') ? 'text-red-400' : ''}`}>{item}</div>
  ))
}

export const generateChannelYXX = (obj:ILatestItem)=>{
  return (obj.trend?.yxx as string[]).map((item,index)=>(
    <div className='`h-full flex-1 flex justify-center items-center' key={index.toString()}>{item}</div>
  )) 
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string)=>{
  const arr:Array<string> = obj.draw_code ? obj.draw_code.split(',') : []
  if(index === '0'){
    return arr.map((item,index)=>(
      <div key={index.toString()} className='h-[48px] flex justify-center items-center'><img className='KS' src={require(`../../../assets/image/KS/${item}.png`)} alt="" /></div>
    ))
  }
  else if(index === '1'){
    return arr.map((item,index)=>(
      <div key={index.toString()} className={`KS ${Number(item) > 3 ? 'text-red-400' : ''}`}>{Number(item) > 3 ? '大' : '小'}</div>
    ))   
  }
  else if(index === '2') {
    return arr.map((item,index)=>(
      <div key={index.toString()} className={`KS ${Number(item) % 2 === 1 ? 'text-red-400' : ''}`}>{Number(item) % 2 === 1 ? '单' : '双'}</div>
    ))  
  }
  else if(index === 'zh'){
    return GenerateChannelZH(obj);
  }
  else if(index === 'yxx'){
    return generateChannelYXX(obj);
  }
}

const KS = {
  GenerateNumber,
  killPlanNumber,
  homeTableHeader:[{title:'总和',Colspan:3},{title:'鱼虾蟹',Colspan:3}],
  homeTableRowItem:[
    {
      wrapperStyle:'',
      generateData:generateZH
    },
    {
      wrapperStyle:'',
      generateData:generateZDX
    },
    {
      wrapperStyle:'',
      generateData:generateZDS
    },    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateYXX(obj,0)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateYXX(obj,1)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateYXX(obj,2)
    },    
  ],
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
    {label:'ZH_TREND',menuCode:'H_NUMBER_TREND'},
    {label:'ZONG_HE_LUZHU',menuCode:'ZONG_HE_LUZHU'},
    {label:'RULES',menuCode:'RULES'},
  ],
  trendSubMenu:[
    {label:'第一球',value:1},
    {label:'第二球',value:2},
    {label:'第三球',value:3},
  ],
  H_V_NumberTrend:[
    {label:'总和',value:0,rank:'总和'},
  ],
  ChannelDrawResult:{
    web:{
      header:[
        [{label:'显示号码',value:'0'},{label:'显示大小',value:'1'},{label:'显示单双',value:'2'}],
        [{label:'总和',value:'zh'}],
        [{label:'鱼虾蟹',value:'lh'}]
      ],
      rowProperty:[
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,index)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{GenerateChannelZH(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{generateChannelYXX(obj)}</div>
        }
      ]
    },
    mobile:{
      header:[
        {label:'号码',value:'0'},{label:'大小',value:'1'},{label:'单双',value:'2'},
        {label:'总和',value:'zh'},
        {label:'鱼虾蟹',value:'yxx'}
      ],
      rowProperty:
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index)}</div>
        }    
    }
  }

}

export default KS;