import { combineReducers } from 'redux';

import appReducers from './appReducers';
import demoReducers from './demoReducers';
import noticeReducers from './noticeReducers';
import lotteryReducers from './lotteryReducers';

const rootReducer = combineReducers({
  appReducers,
  demoReducers,
  noticeReducers,
  lotteryReducers
});

export default rootReducer;