import {ILatestItem} from '../../../types/index';
import { _sum } from '../../utils';

const GenerateNumber = (obj:ILatestItem)=>{
  const _list:number[] = (obj.draw_code).split('|')[0].split(',').map(x=>Number(x));
  const sum = _list.slice(0,4).reduce((a,b)=>a + b,0);
  return _list.map((item,index)=>({value:`${item}`,className:`SSC_FT ${index < 4 ? "underLine" : ''}`})).concat({value:'+',className:'flex justify-center items-center font-bold'},{value:`${sum}`,className:`SSC_FT sum _${sum}`})
}

export const GenerateChannelFanTanBall = (obj:ILatestItem)=>{
  return (obj.draw_code.split('|')[0].split(',') as string[]).map((item,index)=>(
    <div key={index.toString()} className={`SSC_FT _${item}`}>{item}</div>
  ))
}

export const GenerateChannelFanTanResult = (obj:ILatestItem,index:string)=>{
  if(index === 'fan'){
    return (obj.draw_code.split('|')[1].split(',') as string[]).map(item=>(
      <div key={index.toString()} className={`SSC_FT `}>{item}</div>
    ))   
  }
  else if(index === 'dx') {
    return (obj.trend?.dx as string[]).map(item=>(
      <div key={index.toString()} className={`${item === '大' ? 'text-red-400' : ''}`}>{item}</div>
    ))   
  }
  else if(index === 'ds') {
    return (obj.trend?.ds as string[]).map(item=>(
      <div key={index.toString()} className={`${item === '单' ? 'text-red-400' : ''}`}>{item}</div>
    ))  
  }
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string,isRndNum?:number)=>{
  const _balls = obj.draw_code.split('|')[0].split(',') as string[];
  const _result = obj.draw_code.split('|')[1].split(',');
  const _dx = (obj.trend?.dx as string[]) ?? [];
  const _ds = (obj.trend?.ds as string[]) ?? [];
  if(index === '0'){
    return (obj.draw_code.split('|')[0].split(',') as string[]).map((item,index)=>(
      <div key={index.toString()} className={`SSC_FT _${item}`}>{item}</div>
    )).concat(isRndNum === 1 ? <div className='flex justify-center items-center'>+{obj.rnd}</div> : [])
  }
  else if(index === '1'){
    const _index = Number(index) - 1;
    const sum = _sum(_balls,0,4);
    return [
      <div key={index + 'sum'}  className={`h-full flex-1 flex justify-center items-center`}>{sum}</div>, 
      <div key={index}  className={`h-full flex-1 flex justify-center items-center`}>{_result[_index]}</div>, 
      <div key={index + 'dx'} className={`h-full flex-1 flex justify-center items-center ${_dx[_index].includes('大') || _dx[_index].includes('单') ? 'text-red-400' : ''} `}>{_dx[_index]}</div>, 
      <div key={index + 'ds'} className={`h-full flex-1 flex justify-center items-center  ${_ds[_index].includes('大') || _ds[_index].includes('单') ? 'text-red-400' : ''} `}>{_ds[_index]}</div> ] 
  }
}

// For SSC_FT

export const generateFTResult = (obj:ILatestItem)=>{
  return [{value:(obj.draw_code.split('|')[1] as string), className:''}]
}

export const generateFTDX = (obj:ILatestItem, index:number)=>{
  return [{value:(obj.trend?.dx as string[])[index], className:''}]
}

export const generateFTDS = (obj:ILatestItem, index:number)=>{
  return [{value:(obj.trend?.ds as string[])[index], className:''}]
}

const SSC_FT = {
  GenerateNumber,
  homeTableHeader:[{title:'番数',Colspan:1},{title:'大小',Colspan:1},{title:'单双',Colspan:1}],
  homeTableRowItem:[
    {
      wrapperStyle:'',
      generateData:generateFTResult
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDX(obj,0)
    },
    {
      wrapperStyle:'',
      generateData:(obj:ILatestItem)=>generateFTDS(obj,0)
    }
  ],
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
    {label:'RULES',menuCode:'RULES'},
  ],

  H_V_NumberTrend:[
    {label:'第一球',value:1},
    {label:'第二球',value:2},
    {label:'第三球',value:3},
    {label:'第四球',value:4},
    {label:'第五球',value:5},
  ],
  ChannelDrawResult:{
    web:{
      header:[
        [{label:'号码',value:'result'}],
        [{label:'番',value:'fan'}],
        [{label:'单双',value:'ds'}],
        [{label:'大小',value:'dx'}],
      ],
      rowProperty:[
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelFanTanBall(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelFanTanResult(obj,'fan')}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelFanTanResult(obj,'ds')}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelFanTanResult(obj,'dx')}</div>
        }
      ]
    },
    mobile:{
      header:[
        {label:'号码',value:'0'},{label:'番/大小/单双',value:'1',mulitLabel:[{label:'总和',className:'flex-1'},{label:'番号',className:'flex-1'},{label:'单双',className:'flex-1'},{label:'大小',className:'flex-1'}]}
      ],
      rowProperty:
        (obj:ILatestItem,index:string, isRndNum?:number)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index,isRndNum)}</div>
        }
    }
  }
}

export default SSC_FT;