export default function Template4() {
  return (
    <div className=' sm:text-[18px] text-[14px] '>
      <div className=' section'>
        <div>1.【单码】- 指第一球、第二球、第三球、第四球、第五球出现的顺序与号码为派彩依据。</div>
        <div>
          单号：如现场滚球第一个开奖号码为10号，投注第一球为10号则视为中奖，其它号码视为不中奖
        </div>
        <div>大小：开出的号码大于或等于6为大，小于或等于5为小，开出11为和 (不计算输赢)</div>
      </div>
      <div className=' section'>
        <div>2.【总和】-以全部开出的5个号码，加起来的总和来判定。</div>
        <div>
          总和大小: 所有开奖号码数字加总值大于30为和大；总和值小于30为和小；若总和值等于30为和
          (不计算输赢)。
        </div>
        <div>
          总和单双: 所有开奖号码数字加总值为单数叫和单，如11、31；加总值为双数叫和双，如42、30。
        </div>
        <div>
          总和尾数大小: 所有开奖号码数字加总值的尾数，大于或等于5为尾大，小于或等于4为尾小。
        </div>
      </div>
      <div className=' section'>
        <div>3.【龙虎】</div>
        <div>龙：第一球开奖号码大于第五球开奖号码，如第一球开出10，第五球开出7。</div>
        <div>虎：第一球开奖号码小于第五球开奖号码，如第一球开出3，第五球开出7。</div>
      </div>
      <div className=' section'>
        <div>4.选号- 选号玩法是由1~11号中，选出1~5个号码为一投注组合来进行投注。</div>
        <div>一中一: 投注1个号码与当期开奖的5个号码中任1个号码相同，视为中奖。</div>
        <div>任选二: 投注2个号码与当期开奖的5个号码中任2个号码相同(顺序不限)，视为中奖。</div>
        <div>任选三: 投注3个号码与当期开奖的5个号码中任3个号码相同(顺序不限)，视为中奖。</div>

        <div>任选四: 投注4个号码与当期开奖的5个号码中任4个号码相同(顺序不限)，视为中奖。</div>

        <div>任选五: 投注5个号码与当期开奖的5个号码中5个号码相同(顺序不限)，视为中奖。</div>
        <div>任选六: 投注6个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。</div>
        <div>任选七: 投注7个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。</div>
        <div>任选八: 投注8个号码中任5个号码与当期开奖的5个号码相同(顺序不限)，视为中奖。</div>
        <div>组选前二: 投注的2个号码与当期顺序开出的5个号码中的前2个号码相同，视为中奖。</div>
        <div>组选前三: 投注的3个号码与当期顺序开出的5个号码中的前3个号码相同，视为中奖。</div>
        <div>
          直选前二: 投注的2个号码与当期顺序开出的5个号码中的前2个号码相同且顺序一致，视为中奖。
        </div>
        <div>
          直选前三: 投注的3个号码与当期顺序开出的5个号码中的前3个号码相同且顺序一致，视为中奖。
        </div>
      </div>
    </div>
  )
}
