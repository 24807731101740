import { useAppSelector } from '../../../redux/hooks'

import Template0 from '../../../views/Web/HelpCenter/templates/Template0'
import Template1 from '../../../views/Web/HelpCenter/templates/Template1'
import Template14 from '../../../views/Web/HelpCenter/templates/Template14'
import Template15 from '../../../views/Web/HelpCenter/templates/Template15'
import Template16 from '../../../views/Web/HelpCenter/templates/Template16'
import Template17 from '../../../views/Web/HelpCenter/templates/Template17'
import Template2 from '../../../views/Web/HelpCenter/templates/Template2'
import Template3 from '../../../views/Web/HelpCenter/templates/Template3'
import Template4 from '../../../views/Web/HelpCenter/templates/Template4'

import './styles.scss'

export default function Rules() {
  const { templateAndGame, platform } = useAppSelector((state) => state.appReducers)
  const { lotteryList } = useAppSelector((state) => state.lotteryReducers)
  const { template, code } = templateAndGame || {}
  const { lotterys = [] } = lotteryList || {}

  const lottery = lotterys.find((i: { code: string }) => i.code == code)

  return (
    <div className={`ruleCenterWrapper ${platform}`}>
      <div className=' title font-bold mb-4'>玩法规则</div>
      <div className='font-bold sbuTitle mb-4 primaryColor'>
        {lottery ? `${lottery?.name} 规则说明` : ''}
      </div>
      <div>
        {(template == 'PKS' && <Template0 />) || <></>}
        {(template == 'SSC' && <Template1 />) || <></>}
        {(template == 'KLSF' && <Template2 />) || <></>}
        {(template == 'KS' && <Template3 />) || <></>}
        {(template == 'SYXW' && <Template4 />) || <></>}
        {(template == 'PKS_FT' && <Template14 />) || <></>}
        {(template == 'SSC_FT' && <Template15 />) || <></>}
        {(template == 'BINGO_FT' && <Template16 />) || <></>}
        {(template == 'BINGO' && <Template17 />) || <></>}
      </div>
    </div>
  )
}
