import { SET_ABOUT_TAB, SET_THEME, SET_PLATFORM, SET_SUB_SELECTION, SET_TEMPLATE_AND_CODE, SET_BANNER_SHOW } from '../../actionTypes';
import { AnyAction } from 'redux'


interface AppState {
  theme: string,
  platform:string,
  templateAndGame:{template:string,code:string}
  subSelection:string,
  companyCode:string,
  aboutTab:string,
  bannerShow: boolean,
}

const initialState:AppState = {
  theme: 'red',
  platform:'Web',
  templateAndGame:{template:'',code:''},
  subSelection:'',
  companyCode:'KAI166',
  aboutTab:'ABOUT_US',
  bannerShow:true
};

const appReducers = (state = initialState, action:AnyAction) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.value
      };
    case SET_ABOUT_TAB:
      return {
        ...state,
        aboutTab: action.value
      };
    case SET_PLATFORM:
      return {
        ...state,
        platform: action.value
      };
    case SET_SUB_SELECTION:
      return {
        ...state,
        subSelection: action.value
      };
    case SET_TEMPLATE_AND_CODE:
      return {
        ...state,
        templateAndGame: action.value
      };
    case SET_BANNER_SHOW:
      return {
        ...state,
        bannerShow: action.value
      };
    default:
      return state;
  }
};

export default appReducers;