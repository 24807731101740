import { useEffect, useMemo, useState } from 'react'
import lotteryActions from '../../../../../redux/actions/lotteryActions'
import { useAppSelector, useAppDispatch } from '../../../../../redux/hooks'
import { apiService } from '../../../../../services'
import useInterval from '../useInterval'

export interface ITableData {
  count: number
  location: string
  lotteryCode: string
  lotteryName: string
  lotteryTemplate: string
}

export interface ICategory {
  label: string
  value: string | number
  checked: boolean
  lotterys: {
    category: string | number
    name: string
    code: string
    checked: boolean,
    template:string
  }[]
}

const audio = document.createElement('audio')
audio.src = require('../../../../../assets/media/Bell_For_ChangLong.mp3')

export default function useChanglong() {
  const { lotteryList } = useAppSelector((state) => state.lotteryReducers)
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const dispatch = useAppDispatch()

  const [instalment, setInstalment] = useState(5) // 期数
  const [status, setStatus] = useState<'open' | 'close'>('open') // 连续开始 连续未开
  const [checkStatus, setCheckStatus] = useState<'all' | 'clear'>('all') // 全选或者清除
  const [audioChecked, setAudioChecked] = useState(true) // 声音报警
  const [tableData, setTableData] = useState<ITableData[]>([]) // 表格数据
  const [categorys, setCategorys] = useState<ICategory[]>([]) // 彩种分类
  const [loading, setLoading] = useState(false) // 获取表格数据时的加载状态

  useEffect(() => {
    const tmp: ICategory[] = (lotteryList.categorys || []).map((item: ICategory) => {
      const lotterys = ((lotteryList.lotterys || []) as ICategory['lotterys']).filter(
        (i) => i.category == item.value,
      )
      return { ...item, checked: true, lotterys: lotterys.map((i) => ({ ...i, checked: true })) }
    })
    setCategorys(tmp.filter(x=>x.lotterys[0].template !== 'HK6'))
  }, [lotteryList])  

  useInterval(() => {
    getInfo({ enableLoading: false }).then(() => {
      /** 获取成功，播放声音提醒 */
      if (audioChecked) {
        audio.play()
      }
    })
  }, 5 * 1000)

  /** 有一个5s的定时任务，重新获取 */
  const getInfo = async ({
    enableLoading = true,
  }: {
    /** 只有切换状态 连续开出/连续未开 需要loading */
    enableLoading?: boolean
  }) => {
    const isUn = status == 'open' ? 0 : 1
    try {
      enableLoading && setLoading(true)
      const res = await apiService.get(`/changlong/daily/${companyCode}/${isUn}`)
      setTableData((res.data?.data as ITableData[]) || [])
      setLoading(false)
    } catch (error) {
      // console.log(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  // 获取彩种
  useEffect(() => {
    if (!lotteryList.categorys) {
      dispatch(lotteryActions.GetLotteryList)
    }
  }, [])

  //  获取table 表格数据,
  useEffect(() => {
    getInfo({ enableLoading: true })
  }, [status])

  /** 选中的 彩种code 集合 */
  const checkedLotteryCodeSet = useMemo(() => {
    return categorys.reduce((acc, item) => {
      const checked = (item.lotterys || []).filter((i) => i.checked).map((i) => i.code)
      for (const i of checked) {
        acc.add(i)
      }
      return acc
    }, new Set())
  }, [categorys])

  /** 需要显示的桌面数据 */
  const tableShowData = useMemo(() => {
    return tableData.filter(
      (i) => checkedLotteryCodeSet.has(i.lotteryCode) && i.count >= instalment,
    )
  }, [tableData, instalment, checkedLotteryCodeSet])

  return {
    /** 期数 */
    instalment,
    setInstalment,

    /** 连续开始 连续未开 */
    status,
    setStatus,

    /** 全选或者清除 */
    checkStatus,
    setCheckStatus,

    /** 声音报警 */
    audioChecked,
    setAudioChecked,

    /** 表格展示的数据 */
    tableShowData,

    /** 彩种分类 */
    categorys,
    setCategorys,

    /** 当前选中的彩种code 集合  */
    checkedLotteryCodeSet,

    /** 获取表格数据时的加载状态 */
    loading,
  }
}
