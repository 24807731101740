export default function Template2() {
  return (
    <div className=' sm:text-[18px] text-[14px] '>
      <div className=' section'>
        <div>1.两面：指单、双；大、小、尾大、尾小。</div>
        <div>单、双：号码为双数叫双，如8、16；号码为单数叫单，如19、5。</div>
        <div>大、小：开出之号码大于或等于11为大，小于或等于10为小。</div>
        <div>尾大、尾小：开出之尾数大于或等于5为尾大，小于或等于4为尾小。</div>
        <div>
          每一个号码为一投注组合，假如投注号码为开奖号码并在所投的球位置，视为中奖，其余情形视为不中奖。
        </div>
      </div>
      <div className=' section'>
        <div>2.中发白</div>
        <div>中：开出之号码为01、02、03、04、05、06、07</div>
        <div>发：开出之号码为08、09、10、11、12、13、14</div>
        <div>白：开出之号码为15、16、17、18、19、20</div>
      </div>
      <div className=' section'>
        <div>3.方位</div>
        <div>东：开出之号码为01、05、09、13、17</div>
        <div>南：开出之号码为02、06、10、14、18</div>
        <div>西：开出之号码为03、07、11、15、19</div>
        <div>北：开出之号码为04、08、12、16、20</div>
      </div>
      <div className=' section'>
        <div>4.总和单双</div>
        <div>
          所有8个开奖号码的数字总和值是单数为总和单，如数字总和值是31、51；所有8个开奖号码的数字总和值是双数为总和双，如数字总和是42、80；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。
        </div>
      </div>
      <div className=' section'>
        <div>5.总和大小</div>
        <div>
          所有8个开奖号码的数字总和值85到132为总大；所有8个开奖号码的数字总和值36到83为总分小；所有8个开奖号码的数字总和值为84打和；如开奖号码为01、20、02、08、17、09、11，数字总和是68，则总分小。假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖，打和不计算结果。
        </div>
      </div>
      <div className=' section'>
        <div>6.总尾大小</div>
        <div>
          所有8个开奖号码的数字总和数值的个位数大于或等于5为总尾大，小于或等于4为总尾小；假如投注组合符合中奖结果，视为中奖，其余情形视为不中奖。
        </div>
      </div>
      <div className=' section'>
        <div>7.选二任选</div>
        <div>
          指从01至20中任意选择2个号码对开奖号码中任意2个位置的投注。
          投注号码与开奖号码中任意2个位置的号码相符，即中奖。
        </div>
      </div>
      <div className=' section'>
        <div>8.选二连组</div>
        <div>
          指从01至20中任意选择2个号码对开奖号码中按开奖顺序出现的2个连续位置的投注。
          投注号码与开奖号码中按开奖顺序出现的2个连续位置的号码相符（顺序不限），即中奖。
        </div>
      </div>
      <div className=' section'>
        <div>9.选三任选</div>
        <div>
          指从01至20中任意选择3个号码对开奖号码中任意3个位置的投注。
          投注号码与开奖号码中任意3个位置的号码相符，即中奖。
        </div>
      </div>
      <div className=' section'>
        <div>10.选三前组</div>
        <div>
          指从01至20中任意选择3个号码对开奖号码中按开奖顺序出现的前3个连续位置的投注。
          投注号码与开奖号码中按开奖顺序出现的前3个位置的号码相符（顺序不限），即中奖。
        </div>
      </div>
      <div className=' section'>
        <div>11.选四任选</div>
        <div>
          指从01至20中任意选择4个号码，对开奖号码中任意4个位置的投注。
          投注号码与开奖号码中任意4个位置的号码相符，即中奖。
        </div>
      </div>
      <div className=' section'>
        <div>12.选五任选</div>
        <div>
          指从01至20中任意选择5个号码，对开奖号码中任意5个位置的投注。
          投注号码与开奖号码中任意5个位置的号码相符，即中奖。
        </div>
      </div>
      <div className=' section'>
        <div>13.龙虎 以第一球的中奖号码和第八球的中奖号码做为对奖号码。</div>
        <div>
          龙：开出之号码第一球的中奖号码大于第八球的中奖号码。如 第一球开出14
          第八球开出09；第一球开出17 第八球开出08；第一球开出05 第八球开出01…中奖为龙。
        </div>
        <div>
          虎：开出之号码第一球的中奖号码小于第八球的中奖号码。如 第一球开出14
          第八球开出16；第一球开出13 第八球开出18；第一球开出05 第八球开出08…中奖为虎。
        </div>
      </div>
      <div className=' section'>
        <div>14.正码</div>
        <div>
          从01至20中任意选择1个号码进行投注， 投注号码与开奖号码中任意1个位置的号码相符，即中奖。
        </div>
      </div>
    </div>
  )
}
