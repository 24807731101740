export default function Template14() {
  return (
    <div>
      <div className=' section'>
        <div>PK10番摊游戏的投注时间、开奖时间和开奖号码与“北京PK拾”完全同步，北京时间（GMT+8）每天白天从上午09:10开到晚上23:50，每20分钟开一次奖,每天开奖44期。（本游戏开奖结果是取国家官方彩票北京PK拾的前三位数之和，比平常民间玩法更加公平公正）</div>
        <div>前翻摊：此游戏将以番摊的下注方式进行，以开奖号码前三位数(冠亚季军)加总除四的馀数为开奖结果, 番摊下注的数字则为１２３４；例开奖号码加总除四馀数为１等于番摊开１，开奖号码加总除四馀数为２等于番摊开２，开奖号码加总除四馀数为３等于番摊开３，开奖号码加总除四馀数为０等于番摊开４。</div>
        <div>中翻摊：此游戏将以番摊的下注方式进行，以开奖号码中间三位数(5,6,7位)加总除四的馀数为开奖结果, 番摊下注的数字则为１２３４；例开奖号码加总除四馀数为１等于番摊开１，开奖号码加总除四馀数为２等于番摊开２，开奖号码加总除四馀数为３等于番摊开３，开奖号码加总除四馀数为０等于番摊开４。</div>
        <div>后翻摊：此游戏将以番摊的下注方式进行，以开奖号码最后三位数(8,9,10位)加总除四的馀数为开奖结果, 番摊下注的数字则为１２３４；例开奖号码加总除四馀数为１等于番摊开１，开奖号码加总除四馀数为２等于番摊开２，开奖号码加总除四馀数为３等于番摊开３，开奖号码加总除四馀数为０等于番摊开４。</div>
      </div>
      <div className=' section'>
        <div>1.正</div>
        <div>于自1~4任选1号进行投注，当开奖结果与所选的号码相同时，即为中奖。</div>
        <div>开出号码加总除四馀数为1，中奖为1，不中奖为3，其他视为和局；</div>
        <div>开出号码加总除四馀数为2，中奖为2，不中奖为4，其他视为和局；</div>
        <div>开出号码加总除四馀数为3，中奖为3，不中奖为1，其他视为和局；</div>
        <div>开出号码加总除四馀数为0，中奖为4，不中奖为2，其他视为和局。</div>
      </div>
      <div className=' section'>
        <div>2.番</div>
        <div>于自1~4任选1号进行投注，当开奖结果与所选的号码相同时，即为中奖。</div>
        <div>开出号码加总除四馀数为1，中奖为1，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为2，中奖为2，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为3，中奖为3，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为0，中奖为4，其他视为不中奖。</div>
      </div>
      <div className=' section'>
        <div>3.角</div>
        <div>于自1-2，2-3，3-4，1-4任选1个进行投注，当开奖结果与所选的号码相同时，即为中奖。</div>
        <div>开出号码加总除四馀数为1，中奖为1-2角、1-4角，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为2，中奖为2-3角、1-2角，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为3，中奖为2-3角、3-4角，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为0，中奖为1-4角、3-4角，其他视为不中奖。</div>
      </div>
      <div className=' section'>
        <div>4.念</div>
        <div>于自A念B，任选1个进行投注，当开奖结果与A号码相同时，即为中奖，当开奖结果与B号码相同时，则为和局，其他视为不中奖。</div>
        <div>开出号码加总除四馀数为1，中奖为1念2、1念3、1念4，和局为2念1、3念1、4念1，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为2，中奖为2念1、2念3、2念4，和局为1念2、3念2、4念2，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为3，中奖为3念1、3念2、3念4，和局为1念3、2念3、4念3，其他视为不中奖；</div>
        <div>开出号码加总除四馀数为0，中奖为4念1、4念2、4念3，和局为1念4、2念4、3念4，其他视为不中奖。</div>
      </div>
      <div className=' section'>
        <div>5.单，双</div>
        <div>于自单或双任选1个进行投注，当开奖结果为单数则单数中奖，其他视为不中奖。当开奖结果为双数则双数中奖，其他视为不中奖。</div>
      </div>
      <div className=' section'>
        <div>6.三門</div>
        <div>投注三个号码，如果开出的结果是三个号码之一，则中奖，其余为输，如：</div>
        <div>开出的号码加总除四余数为1,中奖号码为134中,124中,123中,不中奖为234中；</div>
        <div>开出的号码加总除四余数为2,中奖号码为234中,124中,123中,不中奖为134中；</div>
        <div>开出的号码加总除四余数为3,中奖号码为234中,134中,123中,不中奖为124中；</div>
        <div>开出的号码加总除四余数为0,中奖号码为234中,134中,124中,不中奖为123中。</div>
      </div>
      <div className=' section'>
        <div>7.大，小</div>
        <div>于自大或小任选1个进行投注。当开奖结果为1、2则为小中奖，其他视为不中奖。当开奖结果为3、4则为大中奖，其他视为不中奖。</div>
      </div>
    </div>
  )
}
  