export default {
  translation: {
    CHANGE_SKIN:'换肤色',
    SET_HOMEPAGE:'设为首页',
    BOOK_MARK:'收藏本站',
    HELP_CENTER:'帮助中心',

    HOME_PAGE:'首页',
    LOTTERY_HOME :'彩票大厅',
    PLAN_RECOMMENDATION:'杀号计划',
    CHANG_LONG_REMINDER:'长龙提醒',
    TREND_CHART:'走势图表',
    DRAW_CALL :'开奖调用',
    TRIAL_BET:'试玩投注',

    ABOUT_US:'关于我们',
    SERVICE_CENTER:'客服中心',
    BUSINESS_COOPERATION:'商务合作',
    FREE_DUTY:'免责声明',
    FAST_LINK:'快速链接',
    DRAW_BUMBER:'开奖号码',
    SOFTWARE_DOWNLOAD:'软件下载',
    FREE_CALL:'免费调用',
    SELF_WEB_NAVIGATOR:'自助网址导航',
    ONLINE_SERVICE:'在线客服',

    NEWS_INFO:'新闻资讯',
    DRAW_CENTER:'开奖中心',
    DRAW_RESULT:'开奖结果',
    DRAW_RECORD:'开奖记录',

    CHANNEL_HOME:'频道首页',
    H_NUMBER_TREND:'横板号码走势',
    V_NUMBER_TREND:'竖版号码走势',
    HOT_NUMBER:'热门号码',
    NUMBER_MISSING:'号码遗漏',
    ZH_LUZHU:'综合路珠',
    GYH_LUZHU:'冠亚和路珠',
    DS_LUZHU:'单双路珠',
    DX_LUZHU:'大小路珠',
    LH_LUZHU:'龙虎路珠',
    RULES:'玩法规则',
    ZH_TREND:'总和走势',
    ZONG_HE_LUZHU:'总和路珠',

    HK6_CHANNEL_HOME:'频道首页',
    HK6_TEMA_SUM:'特码综合',
    HK6_TEMA_DAN_SHUANG:'特码单双',
    HK6_TEMA_WEI:'特码尾数',
    HK6_TEMA_BO_SE:'特码波色',
    HK6_TEMA_DA_XIAO:'特码大小',
    HK6_TEMA_TOU_SHU:'特码头数',
    HK6_TEMA_HE_SHU:'特码合数',
    HK6_TEMA_MISSING:'特码遗漏',


    // 长龙提醒
    CHECK_ALL:'全选',
    CLEAR:'清除',
    CONTINUOUS_OPEN:'连续开出',
    CONTINUOUS_UNOPENED:'连续未开',
    ONLY_SHOW:"只显示",
    INSTALMENT_ABOVE:'期及以上的长龙',
    SOUND_ALARM:'声音报警',
    LOTTERY:"彩种",
    LOCATION:"位置",
    CONTINUOUS_OPEN_INSTALMENT:'连续开出期数',
    LUZHU:'路珠',
    CHECK:"查看",
    CHECK_LUZHU:'查看路珠',
    CONTINUOUS:'连续',
    FILTER:'筛选',
    CONFIRM:"确定",

    // 路珠
    CONTINUOUS_OCCUR:'连续出现',
    LUZHU_ABOVE:'次以上',
    OCCURRENCES:'出现的次数',
    LUZHU_TOTAL:'累计',
    NEWEST:'最新',
    TODAY:'今天',
    YESTERDAY:'昨天',
    THE_DAY_BEFORE_YESTERDAY:'前天',
    FILTER_RANKING:'筛选名次',
    SELECT_LUZHU:'选择路珠',

    // 开奖调用
    CLICK_TO_GET_CODE:'点击获取代码',
    CHOOSE_DISPLAY_LOTTERY:'选择展示彩种',
    CHOOSE_DISPLAY_TYPE:'选择展示类型',
    MULTIPLE_LOTTERY:'多个彩种 （下拉列表切换）',
    SINGLE_LOTTERY:'单个彩种 （仅显示一个彩种）',
    CUSTOME_LOTTERY_STYLE:'自定义彩种风格',
    INFO_STYLE:'资讯网风格',
    INFO_CALL:'资讯网调用',
    BETTING_CALL:'投注网调用',
    LOTTERY_HALL_CALL:'开奖大厅调用',
    COPY:'复制',
    INFO_CONTENT:'我们为投注网提供最热门的高频彩开奖历史数据调用服务，如果您的网站在10000IP以上，可以和我们联系，免费定制专属历史开奖数据。 目前，我们提供 广东快乐十分、北京赛车、重庆欢乐生肖、重庆幸运农场、江苏快三、六合彩等四十几个彩种的历史开奖数据调用。 您可以点击生成调用用代码，复制粘贴到您网站的相应位置，即可完成调用。即可减轻服务器负担，并能提供更全面快速的开奖数据分析！',
    CUSTOME_STYLE_PREVIEW:'自定义风格效果预览（无背景色）',
    CUSTOME_STYLE_PREVIEW_WITH_BG:'自定义风格效果预览（可自行定义背景）',
    CUSTOME_BACKGROUND_COLOR:'自定义背景色',
    DATA_CALL_Q1:'1、开奖调用收费么?',
    DATA_CALL_Q2:'2、数据调用的特色是什么？',
    DATA_CALL_Q3:'3、数据调用数据稳定么?',
    DATA_CALL_Q4:'4、调用地址支持https么?',
    DATA_CALL_A1:'答：所有高频彩彩种调用永久免费',
    DATA_CALL_A2:'答：高频彩彩种全，开奖最快最准时，可同时调用多个彩种，也可单个调用',
    DATA_CALL_A3:'答：本站数据专人维护及监控，保证数据稳定，及时',
    DATA_CALL_A4:'答：支持，您只需将URL中的“http”换成“https”即可使用',
    DATA_CALL_DESC:'数据调用说明',
    GET_CODE_SUCCESS:'获取代码成功',
    PLEASE_COPY:'请将代码拷贝到你要调用的HTML中即可',

    //新闻资讯
    NEWS_CONTENT:'资讯内容',
    READ:'阅读',
    BACK:'返回',
    TOTAL:'共',
    RESULTS:'条结果',

    //热门号码
    NUMBER_SELECT:'号码选择',
    SHOW_INSTALLMENT:'显示期数',
    SETTING:'设置',
    LOTTERY_APP:'彩应用',
  }
};