import { AnimatePresence, motion } from 'framer-motion';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import templates from '../../../constants/templates';
import './styles.scss';
import { useAppSelector } from '../../../redux/hooks';
import { CaretLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const mapStateToProps = (state:RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
});

interface ILiangMian{
  da:number[],
  xiao:number[],
  dan:number[],
  shuang:number[]
}

interface IProps{
  data:(ILiangMian | undefined) | Array<{location:string,count:number}>, 
  mobileLotteryMenuIsVisible:number,
  setMobileLotteryMenuIsVisible:(value:number)=>void
}

function MobileChangLongLiangMianSideMenu ({data, mobileLotteryMenuIsVisible, setMobileLotteryMenuIsVisible}:IProps){
  const {t} = useTranslation()
  const navigate = useNavigate();

  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  
  return <AnimatePresence>
    {mobileLotteryMenuIsVisible && (
      <motion.div
        className='MobileChangLongLiangMianSideMenuWrapper'
        initial={{ x: '100vw'}}
        animate={{ x: 0 }}
        exit={{x:'100vw'}}
        transition={{ duration: 0.5 }}
      >
        <div className='menuHeader flex items-center'>
          <div className=' w-1/4 flex items-center'>
            <CaretLeftOutlined onClick={()=> setMobileLotteryMenuIsVisible(0)} />
          </div>
          <div className=' w-1/2 flex items-center justify-center'>
            {mobileLotteryMenuIsVisible === 1 ? "今日双面/号码统计" : '长龙连开提醒'}
          </div>
          <div className=' w-1/4 flex items-center justify-end text-[14px]' onClick={()=> {
            setMobileLotteryMenuIsVisible(0)
            navigate('/help')
          }}>
            <QuestionCircleOutlined />
            <div className='ml-1'>{t('HELP_CENTER')}</div>
          </div>
        </div>
        <div className='lotteryContent'>
          {
            mobileLotteryMenuIsVisible === 1 ? 
              <div className='liangMianWrapper'>
                <div className='flex font-bold textColor'>
                  <div className='w-[100px] text-center'>名次</div>
                  <div className='flex-1 text-center'>出现次数</div>
                </div>
                {
                  templates[templateAndGame.template].H_V_NumberTrend?.map((item,index)=> <div className='flex' key={item.value}>
                    <div className='w-[100px] text-center'>{item?.rank || item?.label}</div>
                    <div className='flex-1'>
                      <div className='flex'>
                        {
                          ['大','小','单','双'].map(item => <div className='flex-1 text-center' key={item}>{item}</div> )
                        }
                      </div>
                      <div className='flex'>
                        {
                          ['da','xiao','dan','shuang'].map((item)=> <div className='flex-1 text-center textColor' key={item}>
                            {
                              data ?
                                (data as ILiangMian)[item as keyof ILiangMian][index] ?? ''
                                : ''
                            }
                          </div> )
                        }
                      </div>
                    </div>
                  </div> )
                }
              </div> 
              : 
              <div className='mobileChangLongWrapper'>
                {
                  (data as Array<{location:string,count:number}>).map(item=> <div className='changLongItem' key={item.location}>
                    <div>{item.location}</div>
                    <div className='flex flex-1  pl-[50px]'>
                      <div className='textColor'>{item.count}</div>
                      <div>期</div>
                    </div>
                  </div> )
                }
              </div>
          }
        </div>
      </motion.div>
    )}
  </AnimatePresence>
}  

export default connect(mapStateToProps, null)(MobileChangLongLiangMianSideMenu);
