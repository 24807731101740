import {ILatestItem} from '../../../types/index';


const GenerateNumber = (obj:ILatestItem)=>{  
  const _ball  = obj.draw_code.split(',');
  const _flsx = obj.trend?.flsx as Array<string>
  const _result = _ball.slice(0,4).concat(['+',_ball[4],obj.trend?.zh as string, obj.trend?.lh as string]);
  const desc = [_flsx[0],_flsx[1],_flsx[2],_flsx[3],'',_flsx[4],'总和','龙虎']

  return _result.map((item, index)=>{
    if(index < 4 || index === 5){    
      const reminder = Number(item) % 3
      const style = reminder === 0 ? 'red' : reminder === 1 ? 'blue' : 'green'
      return ({value:item, className:`BINGO ${style}`})
    }

    else if(index === 6 || index === 7){
      return ({value:item, className:'BINGO ZHANDLH'})
    }
    else{
      return ({value:item, className:'BINGO textBox'})
    }
  }).concat(desc.map((item)=>({value:item, className:'BINGO textBox'})))
}

export const GenerateChannelResult = (obj:ILatestItem,index:string)=>{
  const _ball = obj.draw_code.split(',');
  const _ds = obj.trend?.ds as string[];
  const _dx = obj.trend?.dx as string[];
  const _flsx = obj.trend?.flsx as string[];
  const _wdx = obj.trend?.wdx as string[];
  const _hds = obj.trend?.hds as string[];
  if(index === 'zh'){
    return ([`${obj.trend?.zh}`,obj.trend?.zdx,obj.trend?.zds,(Number(obj.trend?.zh) % 10 >= 5 ? '尾大' : '尾小'),obj.trend?.lh ] as string[]).map((item,index)=>{
      return <div key={index} className={`${item.includes('大') || item.includes('单') ? 'text-red-400' : ''} h-full flex-1 flex justify-center items-center  whitespace-nowrap border-r border-[#C9C9C9] last:border-r-0`}>{item}</div>
    })
  }else{
    const _index = Number(index);
    return [_ball[_index],_ds[_index],_dx[_index],_wdx[_index],_hds[_index],_flsx[_index]].map((item,index)=>{
      let style = '';
      if(index === 0){
        const reminder = Number(item) % 3
        style = reminder === 0 ? 'BINGO  red' : reminder === 1 ? 'BINGO  blue' : 'BINGO  green'
      }
      return <div key={index} className='h-full flex-1 flex justify-center items-center whitespace-nowrap border-r border-[#C9C9C9] last:border-r-0 '>
        <div className={`${style} ${item.includes('大') || item.includes('单') ? 'text-red-400' : ''}`}>{item}</div>
      </div>
    })
  }
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string)=>{
  const _ball = obj.draw_code.split(',');
  const _flsx = obj.trend?.flsx as string[];
  const arr = Array.from(Array(5),(x,k)=>k);

  if(index === '0'){
    return <div>
      <div className='flex'>{_ball.map((item,index)=> {
        const reminder = Number(item) % 3
        const style = reminder === 0 ? 'red' : reminder === 1 ? 'blue' : 'green'
        return <div key={index} className={`BINGO ${style}`}>{item}</div>} )}</div>
      <div className='flex'>{
        _flsx.map((item, index)=> <div key={index} className='BINGO textBox'>{item}</div> )
      }</div>
    </div>
  }
  else if(index === '1' || index === '2'){
    const _data = index === '2' ? (obj.trend?.dx as string[]) : (obj.trend?.ds as string[]);
    return arr.map((item,index)=>{
      const _temp = _data[index];
      const styleFlag = _temp.includes('大') || _temp.includes('单') || _temp.includes('大') || _temp.includes('单');
      return <div key={item + index} className={`${styleFlag ? 'text-red-400' : ''} py-[13.5px]  h-full flex-1 flex justify-center items-center`}>{_temp}</div>
    } )
  }
  else if(index === '3') {
    return <div className='flex h-full w-full items-center justify-around'>
      <div className='h-full flex-1 flex justify-center items-center py-[13.5px] '>
        {
          obj.trend?.zh
        }
      </div>
      <div className={`h-full flex-1 flex justify-center items-center py-[13.5px]  ${obj.trend?.lh === '大' ? 'text-red-400' : ''}`}>
        {
          obj.trend?.zdx
        }
      </div>
      <div className={`h-full flex-1 flex justify-center items-center py-[13.5px]  ${obj.trend?.lh === '单' ? 'text-red-400' : ''}`}>
        {
          obj.trend?.zds
        }
      </div>
      <div className={`h-full flex-1 flex justify-center items-center py-[13.5px]  ${Number(obj.trend?.zh || 0) % 10 >= 5 ? 'text-red-400' : ''}`}>
        {
          Number(obj.trend?.zh || 0) % 10 >= 5 ? '尾大' : '尾小'
        }
      </div>
      <div className={`h-full flex-1 flex justify-center items-center py-[13.5px]  ${obj.trend?.lh === '龙' ? 'text-red-400' : ''}`}>
        {
          obj.trend?.lh
        }
      </div>
    </div> 
  }
}


const BINGO = {
  GenerateNumber,
  templateWrapper:'BINGOWrapper',
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
    {label:'RULES',menuCode:'RULES'},
  ],
  ChannelDrawResult:{
    web:{
      header:[[{label:'正码一',value:'0'},{label:'正码二',value:'1'},{label:'正码三',value:'2'},{label:'正码四',value:'3'}],[{label:'特码',value:'tm'}],[{label:'总和',value:'zh'}]],
      rowProperty:[
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,index)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,'4')}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,'zh')}</div>
        }
      ]
    },
    mobile:{
      header:[{label:'号码',value:'0'},{label:'单双',value:'1'},{label:'大小',value:'2'},{label:'总和',value:'3'}],
      rowProperty:
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index )}</div>
        }    
    }
  },
}

export default BINGO;