import { useState, useEffect, useMemo } from 'react'
import { apiService } from '../../../services'
import type { ILuZhu, ILuZhuProps, IRanking } from './type'
import dayjs from 'dayjs'
import { useAppSelector } from '../../../redux/hooks'

export interface IData {
  label?: string
  count?: string[] // ["双:257", "单:300"]
  lzList?: string[][]
  lzType?: 'DX' | 'DS' | 'LH'
}
export interface IShowData extends IData {
  /** 当前选中的luzhu 筛选项，需要在表格中显示label */
  lzOption?: ILuZhu
  /** 当前选中的rangking 筛选项，需要在表格中显示label */
  rankOption?: IRanking
}

function useLuZhu({
  rankingOptions,
  luZhuOptions,
  lotteryCode,
}: Pick<ILuZhuProps, 'lotteryCode' | 'luZhuOptions' | 'rankingOptions'>) {
  const [rankings, setRankings] = useState<IRanking[]>([])
  const [luzhu, setLuZhu] = useState<ILuZhu[]>([])
  const [loading, setLoading] = useState(false) // 获取数据时的加载状态
  const [data, setData] = useState<Record<string, IData>>({}) // api 返回的数据
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD')) // 日期 格式 2023-02-23
  const { companyCode } = useAppSelector((state) => state.appReducers)

  const getInfo = async () => {
    try {
      setLoading(true)
      const res = await apiService.get(`/luzhu/${companyCode}/${lotteryCode}/${date}`)
      setData(res.data?.data || {})
      setLoading(false)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  //  获取 数据,
  useEffect(() => {
    getInfo()
  }, [date])

  useEffect(() => {
    setLuZhu((luZhuOptions || [])?.map((item) => ({ ...item, checked: true })))
  }, [luZhuOptions])

  useEffect(() => {
    setRankings(
      (rankingOptions || [])?.map((item, index) => {
        return {
          // 前三个 默认勾选，根据kai8
          checked: index == 0 || index == 1 || index == 2,
          ...item,
        }
      }),
    )
  }, [rankingOptions])

  const checkedLuZhu = useMemo(() => luzhu.filter((i) => i.checked), [luzhu])
  const checkedRankings = useMemo(() => rankings.filter((i) => i.checked), [rankings])

  /** filter 组成的key */
  /** 分好几种情况，
   * 最简单的： 名称value: 0,1,2,3,4...  路珠: DX DS LH   两个组合起来就好
   *
   * 第二种：如彩种： 英国快乐8 KLC-BYGKLC 需要 路珠的value 独立作为 key 值，总和五行：WX
   * 这种情况，用 ifDependent 区分
   *
   * 第三种：只有龙虎特殊，不需要名次作为前缀合并的（或者说，将名次（index==0）的value当作空字符串，也就是ZHLH == LH）。
   * 如彩种：极速分分彩 SSC-JISUSSC 总和单双 key:ZHDS 总和龙湖 key:LH
   * 这种情况，用 setLHIndex0AsEmpty 区分
   *
   */
  const checkedKeys = checkedLuZhu.reduce((acc, item) => {
    if (item.ifDependent) {
      acc.set(item.value as string, { lzOption: item })
      return acc
    }
    for (const rank of checkedRankings) {
      if (rank.setLHIndex0AsEmpty && item.value == 'LH') {
        acc.set('LH', { rankOption: rank, lzOption: item })
        return acc
      }
      if (rank.prefix) {
        acc.set(`${rank.value}${item.value}`, { rankOption: rank, lzOption: item })
      } else {
        acc.set(`${item.value}${rank.value}`, { rankOption: rank, lzOption: item })
      }
    }
    return acc
  }, new Map<string, IShowData>())

  /** 对外展示的data, 携带了当前选中的 option 信息 */
  const showData: IShowData[] = [...Object.keys(data)]
    .filter((i) => checkedKeys.has(i))
    .map((key) => {
      const { count, lzList } = data[key]
      const mapItem = checkedKeys.get(key)
      return {
        label: key,
        lzList,
        count,
        lzOption: mapItem?.lzOption,
        rankOption: mapItem?.rankOption,
      }
    })

  return {
    /** 筛选名次 选项 */
    rankings,
    setRankings,
    /** 路珠选项 */
    luzhu,
    setLuZhu,
    /** 选择的日期 */
    date: dayjs(date),
    setDate,
    /** 需要显示的data数据，列表 */
    showData,
    /** loading */
    loading,
  }
}

export default useLuZhu
