/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import instance from '../apiServiceInstance';

const { CancelToken } = axios || {};
const cancel:{[key:string]: ()=>void | undefined} = {};

/**
 * @param {string} path
 * @param {string} param
 * @param {object} headers
 * @description Method to call GET through Axios
 */

export const get = (path:string, param?:string) => {
  if (cancel[path]) {
    cancel[path]();
    delete cancel[path]
  }

  return instance.get(`${path}${param ? `?${param}` : ''}`, {
    cancelToken: new CancelToken(c => {
      cancel[path] = c;
    }),
  });
};

/**
 * @param {string} path
 * @param {string} data
 * @param {object} headers
 * @description Method to call ApiDelete through Axios
 */

/**
 * @param {string} requests
 * @description Method to call a series of axios call through Axios
 */

export default {
//   post,
  get,
};