import demoActions from '../../../redux/actions/demoActions';
import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';

import './styles.scss';
import { Link } from 'react-router-dom';

const mapStateToProps = (state:RootState) => ({
  apiData: state.demoReducers.apiData,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
});


function MobileLottery (){
   
  return ( 
    <div className='apiCallWrapper'>
      <div className='flex justify-around'>
        <div className='cursor-pointer p-2 border-2 rounded-lg text-white bg-cyan-500 hover:bg-cyan-600'>Home Page</div>
        <Link to={'/demo'}>Go To Demo</Link>
      </div>
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileLottery);
