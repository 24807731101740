export default function Template3() {
  return (
    <div className=' sm:text-[18px] text-[14px]  section'>
      <div>骰宝</div>
      <div>小：三粒骰子之点数总和由4点至10点；</div>
      <div>
        大：三粒骰子之点数总和由11点至17点；注：若三粒骰子平面点数相同，通吃「大」、「小」各注。
      </div>
      <div>三军/鱼虾蟹：任何一粒骰子出现选定之平面点数； 围骰：三粒骰子平面与选定点数相同；</div>
      <div>
        全骰：在一点至六点内，三粒骰子平面点数相同； 点数：由4点至17点，三粒骰子平面点数之总和；
      </div>
      <div>长牌：任两粒骰子之平面点数； 短牌：选定两粒骰子之平面点数；</div>
    </div>
  )
}
