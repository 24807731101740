export default function Template15() {
  return (
    <div>
      <div className=' section'>
        <div>&quot;台湾宾果番摊&quot;每天从07:05:00开第一期结果，每期5分,每天开奖203期。</div>
        <div>台湾宾果番摊取&quot;台湾宾果彩&quot;开奖号码的前5位,每位开奖号码除以4的余数作为番摊对应的开奖结果.
注明:余数得1为1番,余数得2为2番,余数得3为3番,余数得0为4番.</div>
        <div>具体游戏规则如下:</div>
      </div>
      <div className=' section'>
        <div>番:投注单一号码，开出为赢，其余为输。</div>
        <div>1番，开1为赢，2、3、4为输</div>
        <div>2番，开2为赢，1、3、4为输</div>
        <div>3番，开3为赢，1、2、4为输</div>
        <div>4番，开4为赢，1、2、3为输</div>
      </div>
      <div className=' section'>
        <div>念: 投注两个号码，一个为赢，一个为和，另外两个为输。</div>
        <div>1念2，开1为赢，开2为和，3、4为输</div>
        <div>1念3，开1为赢，开3为和，2、4为输</div>
        <div>1念4，开1为赢，开4为和，2、3为输</div>
        <div>2念3，开2为赢，开3为和，1、4为输</div>
        <div>2念4，开2为赢，开4为和，1、3为输</div>
        <div>2念1，开2为赢，开1为和，3、4为输</div>
        <div>3念1，开3为赢，开1为和，2、4为输</div>
        <div>3念2，开3为赢，开2为和，1、4为输</div>
        <div>3念4，开3为赢，开4为和，1、2为输</div>
        <div>4念3，开4为赢，开3为和，1、2为输</div>
        <div>4念2，开4为赢，开2为和，1、3为输</div>
        <div>4念1，开4为赢，开1为和，2、3为输</div>
      </div>
      <div className=' section'>
        <div>角:投注两个号码，开出为赢，其余为输。</div>
        <div>角1 2，开1、2为赢，3、4为输</div>
        <div>角2 3，开2、3为赢，1、4为输</div>
        <div>角3 4，开3、4为赢，1、2为输</div>
        <div>角4 1，开4、1为赢，2、3为输</div>
      </div>
      <div className=' section'>
        <div>正:投注一个号码，开出为赢，一个为输，另外两个为和。</div>
        <div>正1，则开1为赢，3为输，2、4为和</div>
        <div>正2，则开2为赢，4为输，1、3为和</div>
        <div>正3，则开3为赢，1为输，2、4为和</div>
        <div>正4，则开4为赢，2为输，1、3为和</div>
      </div>
      <div className=' section'>
        <div>三门: 投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。</div>
        <div>三门234，开2、3、4为赢，1为输</div>
        <div>三门134，开1、3、4为赢，2为输</div>
        <div>三门124，开1、2、4为赢，3为输</div>
        <div>三门123，开1、2、3为赢，4为输</div>
      </div>
      <div className=' section'>
        <div>单双:</div>
        <div>1、3为单</div>
        <div>2、4为双</div>
      </div>
      <div className=' section'>
        <div>大小:</div>
        <div>1、2为小</div>
        <div>3、4为大</div>
      </div>
    </div>
  )
}
      