import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';

import {AboutUsMenu, AboutUsContent} from '../../../constants/utils/index';

import './styles.scss';
import appActions from '../../../redux/actions/appActions';

const mapStateToProps = (state:RootState) => ({
  aboutTab: state.appReducers.aboutTab,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  setAboutTab: (type:string) => dispatch(appActions.setAboutTab(type)),
});

interface IProps{
  setAboutTab:(type:string)=>void;
  aboutTab:string
}

function MobileAboutUs ({setAboutTab, aboutTab}:IProps){
   
  return ( 
    <div className='MobileAboutUsWrapper'>
      <div className='menuContainer'>
        {
          AboutUsMenu.map(item=> <div
            className={`${aboutTab === item.value ? "selected" : ""}`}
            onClick={()=>setAboutTab(item.value)}
            key={item.value}>{item.label}</div> )
        }
      </div>
      <div className='contentContainer'>
        <div className='contentTitle'>{AboutUsMenu.find(x=>x.value === aboutTab)?.label}</div>
        {
          AboutUsContent[`${aboutTab}`].map(item=> <div className='contentSection' key={`${item.content.mainContent}`}>
            <div className='mainItem'>
              {item.index ? <div className='mr-[10px]'>{item.index}</div> : ''}
              <div>
                {
                  item.content.mainContent
                }
              </div>
            </div>
            {
              item.content.child 
                ? 
                item.content.child.map((sub,index)=> 
                  <div className='childItem' key={`${index.toString()}`}>
                    <div className='mr-[10px]'>{sub.subIndex}</div>
                    <div>{sub.subContent}</div>
                  </div> ) 
                : ''
            }
          </div> 
          )
        }
      </div>
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileAboutUs);
