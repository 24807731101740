export const SET_DATA = 'SET_DATA';
export const SET_THEME = 'SET_THEME';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SET_SUB_SELECTION = 'SET_SUB_SELECTION';
export const SET_LOTTERY_NEWS = 'SET_LOTTERY_NEWS';
export const SET_LOTTERY_LIST = 'SET_LOTTERY_LIST';
export const SET_ALL_LATEST_RESULT = 'SET_ALL_LATEST_RESULT';
export const SET_TEMPLATE_AND_CODE = 'SET_TEMPLATE_AND_CODE';
export const SET_NOTICE = 'SET_NOTICE';
export const SET_LOTTERY_NEWS_ID = 'SET_LOTTERY_NEWS_ID';
export const SET_KILL_PLAN_LOTTERY_LIST = 'SET_KILL_PLAN_LOTTERY_LIST';
export const SET_ABOUT_TAB = 'SET_ABOUT_TAB';
export const SET_BANNER_SHOW = 'SET_BANNER_SHOW';