import demoActions from '../../../redux/actions/demoActions';
import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from "react-dom/server"

import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import templates from '../../../constants/templates';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import appActions from '../../../redux/actions/appActions';
import { apiService } from '../../../services';

import { ILotteryList, ILatestItem, ILotteryItem} from '../../../types/index';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line  } from 'react-chartjs-2';

import type { ScriptableTooltipContext } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const mapStateToProps = (state:RootState) => ({
  apiData: state.demoReducers.apiData,
  lotteryList: state.lotteryReducers.lotteryList,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
});

const templatesList:{[key:string]:string} = {
  'PKS':'PK拾',
  'SSC':'时时彩',
  'KS':'快三',
  'SYXW':'11选5',
  'KLSF':'快乐十分',
}
interface IProps{
  lotteryList:ILotteryList, 
}
interface toolTipProp{
  x:string,
  y:number,
  item:ILatestItem
}

function Chart ({lotteryList}:IProps){
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const { t } = useTranslation()
  const [lotteryData, setLotteryData] = useState<{[key:string]:ILotteryItem[]}>({});
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedLottery, setSelectedLottery] = useState<string>('');
  const [selectedChildMenu, setSelectedChildMenu] = useState<number | string>(1);

  const [trendData, setTrendData] = useState<{datasets:Array<{data:Array<{x:string,y:number}>}>}>({datasets:[]});

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isRndNum, setIsRndNum] = useState(0);

  const CustomTooltip = (obj:toolTipProp) => {  
    let type = ''
    if(templates[selectedTemplate] && templates[selectedTemplate].trendSubMenu){
      type = templates[selectedTemplate].trendSubMenu?.find(x=>x.value === selectedChildMenu)?.label ?? ''
    }
    return (
      <div className="p-2">
        <div className='flex mb-2 justify-center'>
          {`${type} ${obj.x}期 和值为： ${obj.y}`}
        </div>
        <div className={`flex ${templates[`${selectedTemplate}`].templateWrapper}`}>
          {templates[`${selectedTemplate}`].GenerateNumber(obj.item).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
            return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
          }
          ) }
          {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>+{obj.item.rnd}</div>}
        </div>
      </div>
    );  
  };

  const externalTooltipHandler = (context: ScriptableTooltipContext<"line">)=>{
    const {chart, tooltip} = context;
    
    if(chart.canvas.parentNode){
      let tooltipEl:HTMLElement | null = chart.canvas.parentNode.querySelector('#trendTooltipContainer');
      // Hide if no tooltip
      if (tooltip.opacity === 0 && tooltipEl) {
        tooltipEl.style.opacity = '0';
        return;
      }

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'trendTooltipContainer'
      }
      const table = document.createElement('div');
      table.innerHTML = renderToStaticMarkup(CustomTooltip((tooltip.dataPoints[0].raw as toolTipProp)));

      // Remove old children
      while (tooltipEl.firstChild) {
        tooltipEl.firstChild.remove();
      }   
      tooltipEl.appendChild(table); 
      const {offsetLeft: positionX, offsetTop: positionY,offsetWidth} = chart.canvas;
      tooltipEl.style.opacity = '1';
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.transform = `translate(-${Math.floor((tooltip.caretX / offsetWidth) * 100)}%, 0)`;
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  }

  const options = {
    maintainAspectRatio:false,
    layout: {
      padding:0
    },
    elements:{
      line:{
        backgroundColor: '#FFC400',
        borderColor: '#FFC400',
      },
      point:{
        pointStyle: 'circle',
        pointRadius: 4,
        backgroundColor:'#FFC400',
      }
    },
    scales: {
      x: {
        ticks: {
          display: false
        }
      }
    },
    parsing:{
      xAxisKey: 'x',
      yAxisKey: 'y'
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
      legend:{
        display:false
      }
    }
  };

  useEffect(()=>{
    if(lotteryList.lotterys.length > 0){
      const temp = (lotteryList.lotterys as ILotteryItem[]).reduce((a:{[key:string]:ILotteryItem[]},b:ILotteryItem)=>{
        if(templatesList[b.template]){
          if(a[b.template]){
            return {...a,[b.template]:a[b.template].concat(b)}
          }
          else{
            return {...a,[b.template]:[b].concat([])}
          }
        }
        return a;
      },{})
      setLotteryData(temp);
      setSelectedTemplate(Object.keys(temp)[0])   
    }
  },[lotteryList])

  useEffect(()=>{
    if(selectedTemplate && templates[selectedTemplate]?.trendSubMenu){
      setSelectedLottery(lotteryData[selectedTemplate][0].code ?? '')
      setSelectedChildMenu((templates[selectedTemplate]?.trendSubMenu as Array<{[key:string]:number | string}>)[0]?.value)
    }
  },[selectedTemplate])

  const getInfo = async ({
    lotteryCode,
  }: {
    lotteryCode:string;
  }) => {
    try {
      const res = await apiService.get(`/lottery/history/${companyCode}/${lotteryCode}`)
      const data = res.data.data?.list ? res.data.data?.list.slice(0,25) : [];
      const temp:Array<{x:string,y:number,item:ILatestItem}> = data.map((item:{draw_code:string,issue:number})=> {
        return ({
          y:Number(item.draw_code.split(',')[Number(selectedChildMenu) - 1]),
          x:String(item.issue),
          item
        })
      } 
      )
      setTrendData({datasets:[{ data:temp}]})      
    } catch (error) {
      // console.log(error)
    } finally {
      // console.log(error)
    }
  }

  useEffect(()=>{
    if(selectedLottery && selectedChildMenu){
      setIsRndNum(lotteryList.lotterys.find(x=>x.code === selectedLottery)?.isRndNum ?? 0)   
      const obj = {
        lotteryCode:selectedLottery,
      }
      getInfo(obj)
    }
  },[selectedLottery, selectedChildMenu])

  const onItemClick = (subMenu:string,code:string)=>{
    dispatch(appActions.setSubSelection(subMenu));
    dispatch(()=>appActions.setTemplateAndCode({template: selectedTemplate,code:code}));
    navigate('/lottery');
  }
   
  return ( 
    <div className='chartTrendWrapper'>
      <div className='flex border-b-[#B2B2B2] border-b-[1.5px] subMenuListRow'>
        {
          Object.keys(lotteryData).map(item=> <div
            onClick={()=>setSelectedTemplate(item)}
            className={`menuItem ${item === selectedTemplate ? 'selected' : ''}`} 
            key={item}>
            {templatesList[item]}
          </div> )
        }
      </div>

      <div className='chartContainer'>
        <div className='flex flex-wrap'>
          {
            lotteryData[selectedTemplate] && lotteryData[selectedTemplate].map(item=> 
              <div 
                onClick={()=>setSelectedLottery(item.code)}
                key={`${item.code}`} 
                className={`buttonWrapper ${selectedLottery === item.code ? 'selected' : ''}`}>
                {item.name}
              </div> )
          }
        </div>
        <div className='w-full border-b-[#B2B2B2] border-b-[1px] my-[20px]'></div>
        <div className='flex flex-wrap'>
          {
            templates[selectedTemplate] && templates[selectedTemplate].trendSubMenu?.map(item=> 
              <div 
                onClick={()=>setSelectedChildMenu(item.value)}
                className={`buttonWrapper ${selectedChildMenu === item.value ? 'selected' : ''}`}
                key={item.label}>
                {item.label}
              </div> )
          }
        </div>
        <div className='text-[24px] font-bold mt-5 mb-2'>
          走势图
        </div>
        <div className=' w-full relative flex justify-center h-[400px]'>
          {trendData.datasets.length > 0 &&
          <Line options={options} data={trendData} />
          }
        </div>
      </div>

      <div className='gameLinksWrapper flex flex-wrap'>
        {
          lotteryData[selectedTemplate] && lotteryData[selectedTemplate].map(child=> { 
            let lotteryImage;
            try {
              lotteryImage = require(`../../../assets/image/${child.code}.png`)
            } catch (error) {
              lotteryImage = ''
            }
            return<div key={child.code} className=' w-1/2 px-3 py-5 flex'>
              <img className='GameImage' src={lotteryImage} alt="" srcSet="" /> 
              <div className=' ml-4'>
                <div className=' font-bold text-[24px] ml-2'>{child.name}</div>
                <div className='gameLink flex flex-wrap'>
                  {
                    templates[selectedTemplate].subMenuList?.map((item,index,arr)=> 
                      <div onClick={(e)=> {e.stopPropagation();onItemClick(item.menuCode,child.code ?? '')}} className='menuItem m-2' key={item.menuCode}>
                        {`${t(item.label)} ${index === arr.length - 1 ? '' : '/'}`}
                      </div> )
                  }
                </div>
              </div>
            </div>} )
        }
      </div>

    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(Chart);
