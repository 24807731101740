import { AppDispatch, RootState } from '../../../redux/store'
import { connect } from 'react-redux'

import './styles.scss'
import templates from '../../../constants/templates'
import { useTranslation } from 'react-i18next'
import appActions from '../../../redux/actions/appActions'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useEffect, useState } from 'react'
import demoActions from '../../../redux/actions/demoActions'
import LuZhu, { LuZhuSubSelectionsList, mapLuzhuProps } from '../../../components/Web/LuZhu'
import HomeDrawItem from '../../../components/Web/HomeDrawItem'
import { apiService } from '../../../services'
import { ILatestItem, ILotteryList } from '../../../types'
import { AnimatePresence, motion } from 'framer-motion'
import ChannelHome from '../../../components/Web/ChannelHome'
import HotNumber from '../../../components/Web/HotNumber'
import NumberMissing from '../../../components/Web/NumberMissing'
import HorizontalNumberTrend from '../../../components/Web/HorizontalNumberTrend'
import Rules from '../../../components/Web/Rules'
import VerticalNumberTrend from '../../../components/Web/VerticalNumberTrend'

const mapStateToProps = (state:RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  GetList: () => dispatch(demoActions.GetList()),
})

interface IProps {
  lotteryListData: ILotteryList
}

function Lottery({ lotteryListData }: IProps) {
  const { t } = useTranslation()
  const { subSelection } = useAppSelector((state) => state.appReducers)
  const { templateAndGame } = useAppSelector((state) => state.appReducers)
  const { companyCode } = useAppSelector((state) => state.appReducers)
  const dispatch = useAppDispatch()
  const [item, setItem] = useState<ILatestItem>()
  const [clockTimer, setClockTimer] = useState<string>(
    new Date().toString()
  )

  const getLatestResult = async ({ lotteryCode }: { lotteryCode: string }) => {
    try {
      const res = await apiService.get(`/lottery/issue/${companyCode}/${lotteryCode}`)
      const data = res?.data?.data
      const temp = lotteryListData.lotterys.find((x) => x.code === lotteryCode)
      if (temp && data) {
        setItem({
          ...data,
          category: temp.category,
          name: temp.name,
          openCount: temp.openCount ?? 0,
          template: temp.template,
          group: temp.group
        })
      }
    } catch (error) {
      setItem(undefined)
    }
  }

  useEffect(() => {
    getLatestResult({ lotteryCode: templateAndGame?.code })
    const clockId = setInterval((): void => {
      setClockTimer(
        new Date().toString()
      )
    }, 1000)
    const _timerId = setInterval((): void => {
      getLatestResult({ lotteryCode: templateAndGame?.code })
    }, 5000);
    if (
      templateAndGame?.template &&
      templates[templateAndGame.template] &&
      templates[templateAndGame.template]['subMenuList']
    ) {
      const arr = (templates[templateAndGame.template]['subMenuList'] as { menuCode: string }[])      
      if(subSelection === '' || (subSelection && !arr.find(x=>x.menuCode === subSelection))){
        dispatch(
          appActions.setSubSelection(
            arr[0].menuCode,
          ),
        )
      }
    }
    return () => {
      clearInterval(clockId);
      clearInterval(_timerId)
    }
  }, [templateAndGame])
  
  return (
    <div className='lotteryWrapper'>
      <div className='lotteryLatest'>
        {item && (
          <HomeDrawItem
            showLink={false}
            item={item as ILatestItem}
            current={clockTimer}
            target={item?.next_time ?? ''}
          />
        )}
      </div>
      {templates[`${templateAndGame.template}`]?.subMenuList && (
        <div className='flex w-full items-center border-b-[#B2B2B2] border-b-[1.5px] subMenuListRow my-[30px]'>
          {(
            templates[`${templateAndGame.template}`].subMenuList as { [key: string]: string }[]
          ).map((item, index) => (
            <div
              onClick={() => dispatch(appActions.setSubSelection(item.menuCode))}
              className={`menuItem ${item.menuCode === subSelection ? 'selected' : ''}`}
              key={index}
            >
              {t(item.label)}
            </div>
          ))}
        </div>
      )}
      <AnimatePresence mode='wait'>
        <motion.div
          key={subSelection}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 0.5,
          }}
        >
          {
            LuZhuSubSelectionsList.includes(subSelection) 
              ? (
                <LuZhu
                  {...mapLuzhuProps(templateAndGame.template)?.find(
                    (i) => i.subSelection == subSelection,
                  )}
                  title={t(subSelection)}
                  lotteryCode={templateAndGame.code}
                />
              )
              :
              <>
                {
                  subSelection === 'CHANNEL_HOME' && <ChannelHome />
                }  
                {
                  subSelection === 'H_NUMBER_TREND' && <HorizontalNumberTrend />
                }  
                {
                  subSelection === 'V_NUMBER_TREND' && <VerticalNumberTrend />
                }  
                {
                  subSelection === 'HOT_NUMBER' && <HotNumber />
                }  
                {
                  subSelection === 'NUMBER_MISSING' && <NumberMissing />
                }   
                {
                  subSelection === 'RULES' && <Rules />
                }   
              </>
          }
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Lottery)
