import {ILatestItem, IShuBanItem} from '../../../types/index';

const GenerateNumber = (obj:ILatestItem)=>{
  return (obj.draw_code.split(',') as string[]).map(item=>({value:item,className:`SYXW`}))
}


const generateZH = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zh as string, className:''}]

}
const generateDX = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zdx as string, className:''}]
}

const generateDS = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zds as string, className:''}]
}

const generateZWDX = (obj:ILatestItem)=>{
  return [{value:obj.trend?.zwdx as string, className:''}]
}

const generateLH = (obj:ILatestItem)=>{
  return [{value:obj.trend?.lh as string, className:''}]
}

export const killPlanNumber = (data:Array<string>)=>{
  return data.map(item=>({value:item,className:`SYXW`}))
}


export const GenerateChannelResult = (obj:ILatestItem,index:string)=>{
  if(index === '0'){
    return (obj.draw_code.split(',') as string[]).map((item,index)=>(
      <div key={index.toString()} className={`SYXW`}>{item}</div>
    ))
  }
  else if(index === '1'){
    return (obj.trend?.dx as string[]).map(item=>(
      <div key={index.toString()} className={`SYXW ${item === '大' ? 'dx_ds_yellow' : 'dx_ds_grey'}`}>{item}</div>
    ))   
  }
  else if(index === '2') {
    return (obj.trend?.ds as string[]).map(item=>(
      <div key={index.toString()} className={`SYXW ${item === '单' ? 'dx_ds_yellow' : 'dx_ds_grey'}`}>{item}</div>
    ))  
  }
}

export const GenerateChannelZH = (obj:ILatestItem)=>{
  return [obj.trend?.zh,obj.trend?.zdx,obj.trend?.zds,obj.trend?.zwdx,].map((item,index)=>(
    <div key={index.toString()} className={`h-full flex-1 flex justify-center items-center ${(item === '尾大' || item === '大' || item === '单') ? 'text-red-400' : ''}`}>{item}</div>
  ))
}

export const generateChannleLH = (obj:ILatestItem)=>{
  return  <div  className={`h-full flex-1 flex justify-center items-center ${obj.trend?.lh === '龙' ? 'text-red-400' : ''}`}>{obj.trend?.lh}</div> 
}

export const GenerateMobileChannelResult = (obj:ILatestItem,index:string, isRndNum?:number)=>{
  if(index === '0'){
    return (obj.draw_code.split(',') as string[]).map((item,index)=>(
      <div key={index.toString()} className={`SYXW`}>{item}</div>
    )).concat(isRndNum === 1 ? <div className='flex justify-center items-center'>+{obj.rnd}</div> : [])
  }
  else if(index === '1'){
    return (obj.trend?.dx as string[]).map((item,index)=>(
      <div key={index.toString()} className={`SYXW ${item === '大' ? 'dx_ds_yellow' : 'dx_ds_grey'}`}>{item}</div>
    ))   
  }
  else if(index === '2') {
    return (obj.trend?.ds as string[]).map((item, index)=>(
      <div key={index.toString()} className={`SYXW ${item === '单' ? 'dx_ds_yellow' : 'dx_ds_grey'}`}>{item}</div>
    ))  
  }
  else if(index === 'zh/lh') {
    return GenerateChannelZH(obj).concat(generateChannleLH(obj) ) 
  }
}

const generateWebShuBanHeader = (ball:number)=>{
  let header = [];
  if(ball === 0){
    header = [()=> <div>期数</div>,()=> <div>期数</div>, ()=> <div>期数</div>, ()=> <div>期数</div>, ()=> <div>期数</div> ]
  }
  else{
    header = [
      ()=> <div className='h-full w-full flex justify-center items-center'>期号</div>,
      ()=> <div className='h-full w-full flex justify-center items-center'>开奖号码</div>,
      ()=> <div className='headerCellWrapper'>
        <div>{SYXW.H_V_NumberTrend.find(x=>x.value === ball)?.label}走势</div>
        <div>
          {
            Array.from({length:11},(v,k)=>k + 1).map((item, index)=> <div key={index.toString()}>{item}</div> )
          }
        </div>
      </div>,
      ()=> <div className='headerCellWrapper'>
        <div>大小</div>
        <div>
          {
            ['大','小','和'].map((item, index)=> <div key={index.toString()}>{item}</div> )
          }
        </div>
      </div>,
      ()=> <div className='headerCellWrapper'>
        <div>单双</div>
        <div>
          {
            ['单','双'].map((item, index)=> <div key={index.toString()}>{item}</div> )
          }
        </div>
      </div>,
    ]
  }
  return header; 
}

const generateWebShuBanRow = (ball:number)=>{
  let row = [];
  if(ball === 0){
    row = [()=> <div>期数</div>,()=> <div>期数</div>, ()=> <div>期数</div>, ()=> <div>期数</div>, ()=> <div>期数</div> ]
  }
  else{
    row = [
      (item:IShuBanItem)=> <div className='h-full w-full flex justify-center items-center'>{item?.term}</div>,
      (item:IShuBanItem)=> <div className='h-full w-full flex justify-center items-center'>
        {
          GenerateChannelResult(({draw_code:item?.balls ?? ''} as ILatestItem ),'0')
        }
      </div>,
      (item:IShuBanItem)=> <div className='rowCellWrapper'>
        {
          item.zs.slice(0,11).map((data,index)=> {
            const cls = 1 * data > 0 ? 'ball' : 'yilou';
            return <div key={index.toString()}> <div className={cls}>{Math.abs(data)}</div></div> 
          })
        }
      </div>,
      (item:IShuBanItem)=> <div className='rowCellWrapper'>
        {
          item.zs.slice(11,14).map((itm,j)=> {
            const b = 1 * itm > 0;
            const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou';
            let txt = b ? (j == 0 ? '大' : '小') : Math.abs(itm);
            if (b) {
              txt = j == 0 ? '大' : j == 1 ? '小' : '和' ;
            }
            return <div className={cls} key={j.toString()}>{txt}</div>} )
        }
      </div>,
      (item:IShuBanItem)=> <div className='rowCellWrapper'>
        {
          item.zs.slice(14,16).map((itm,j)=> {
            const b = 1 * itm > 0;
            const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou';
            let txt = b ? (j == 0 ? '单' :  '双') : Math.abs(itm);
            if (b) {
              txt = j == 0 ? '单' : '双';
            }
            return <div className={cls} key={j.toString()}>{txt}</div>} )
        }
      </div>,
    ]
  }
  return row; 
}

const generateMobileShuBanHeader = (ball:number,type:string)=>{
  let header;
  if(ball === 0){
    if(type === 'zs'){
      header = [
        ()=> <div className='h-full flex items-center w-[540px]'>
          {
            Array.from({length:17},(v,k)=>k + 3).map((item, index)=> <div className='flex-1 flex justify-center items-center' key={index.toString()}>{item}</div> )
          }
        </div>,
      ]
    }
    else{
      header = [
        ()=> <div className='h-full flex items-center w-full'>
          {
            ['和','大','小','单','双'].map((item, index)=> <div className='flex-1 flex justify-center items-center' key={index.toString()}>{item}</div> )
          }
        </div>,
      ]
    }
  }
  else{
    if(type === 'zs'){
      header = [
        ()=> <div className='h-full w-full flex justify-center items-center'>开奖号码</div>,
        ()=> <div className='h-full flex items-center w-[540px]'>
          {
            Array.from({length:11},(v,k)=>k + 1 ).map((item, index)=> <div className='flex-1 flex justify-center items-center' key={index.toString()}>{item}</div> )
          }
        </div>,
      ]
    }
    else{
      header = [
        ()=> <div className='h-full flex items-center w-full'>
          {
            ['大','小','和','单','双'].map((item, index)=> <div className='flex-1 flex justify-center items-center' key={index.toString()}>{item}</div> )
          }
        </div>,

      ]
    }
  }
  return header ?? []; 
}

const generateMobileShuBanRow = (ball:number,type:string)=>{
  let row;
  if(ball === 0){
    if(type === 'zs'){
      row = [
        (item:IShuBanItem)=> <div className='h-full flex items-center w-[540px]'>
          {
            item.zs.slice(0,17).map((data,index)=> {
              const cls = 1 * data > 0 ? 'ball' : 'yilou';
              return <div className='flex-1 flex justify-center items-center' key={index.toString()}> <div className={cls}>{Math.abs(data)}</div></div> 
            } )
          }
        </div>
      ];
    }
    else{
      row = [
        (item:IShuBanItem)=> <div className='h-full flex items-center w-full'>
          {
            [( <div className='flex-1 flex justify-center items-center' key='zh'>{item?.zh}</div> )].concat(item.zs.slice(17,21).map((itm,j)=> {
              const b = 1 * itm > 0;
              const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou';
              const txt = b ? (j == 0 ? '大' : j == 1 ? '小' : j == 2 ? '单' : '双') : Math.abs(itm);
              return <div  key={j.toString()} className={`${cls} flex-1 flex justify-center items-center`}>{txt}</div>
            }
            ))
          }
        </div>,
      ];
    }
  }
  else{
    if(type === 'zs'){
      row = [
        (item:IShuBanItem)=> <div className='h-full w-full flex justify-center items-center'>
          {
            GenerateChannelResult(({draw_code:item?.balls ?? ''} as ILatestItem ),'0')
          }
        </div>,
        (item:IShuBanItem)=> <div className='h-full flex items-center w-[540px]'>
          {
            item.zs.slice(0,11).map((data,index)=> {
              const cls = 1 * data > 0 ? 'ball' : 'yilou';
              return <div className='flex-1 flex justify-center items-center' key={index.toString()}> <div className={cls}>{Math.abs(data)}</div></div> 
            })
          }
        </div>,
      ]
    }else{
      row = [
        (item:IShuBanItem)=> <div className='h-full w-full flex items-center'>
          {
            item.zs.slice(11,16).map((itm,j)=> {
              const b = 1 * itm > 0;
              const cls = b ? (j % 2 == 0 ? 'dd' : 'xs') : 'yilou';
              let txt = b ? (j == 0 ? '大' : j == 1 ? '小' : j == 2 ? '单' : '双') : Math.abs(itm);
              if (b) {
                txt = j == 0 ? '大' : j == 1 ? '小' : j == 2 ? '和' : j == 3 ? '单' : '双';
              }
              return <div className={`${cls} flex-1 flex justify-center items-center`} key={j.toString()}>{txt}</div>} )
          }
        </div>,
      ]
    }
  }
  return row ?? []; 
}


const SYXW = {
  GenerateNumber,
  killPlanNumber,
  homeTableHeader:[{title:'总和',Colspan:4},{title:'龙虎',Colspan:1}],
  homeTableRowItem:[
    {
      wrapperStyle:'',
      generateData:generateZH
    },
    {
      wrapperStyle:'',
      generateData:generateDX
    },
    {
      wrapperStyle:'',
      generateData:generateDS
    },  
    {
      wrapperStyle:'',
      generateData:generateZWDX
    },    
    {
      wrapperStyle:'',
      generateData:generateLH
    },
  ],
  subMenuList:[
    {label:'CHANNEL_HOME',menuCode:'CHANNEL_HOME'},
    {label:'H_NUMBER_TREND',menuCode:'H_NUMBER_TREND'},
    {label:'V_NUMBER_TREND',menuCode:'V_NUMBER_TREND'},
    {label:'HOT_NUMBER',menuCode:'HOT_NUMBER'},
    {label:'NUMBER_MISSING',menuCode:'NUMBER_MISSING'},
    {label:'ZH_LUZHU',menuCode:'ZH_LUZHU'},
    {label:'DS_LUZHU',menuCode:'DS_LUZHU'},
    {label:'DX_LUZHU',menuCode:'DX_LUZHU'},
    {label:'LH_LUZHU',menuCode:'LH_LUZHU'},
    {label:'RULES',menuCode:'RULES'},
  ],
  trendSubMenu:[
    {label:'第一球',value:1},
    {label:'第二球',value:2},
    {label:'第三球',value:3},
    {label:'第四球',value:4},
    {label:'第五球',value:5},
  ],
  hotAndMissingNumber:[
    {label:'第一球',value:1},
    {label:'第二球',value:2},
    {label:'第三球',value:3},
    {label:'第四球',value:4},
    {label:'第五球',value:5},
  ],
  H_V_NumberTrend:[
    {label:'第一球',value:1},
    {label:'第二球',value:2},
    {label:'第三球',value:3},
    {label:'第四球',value:4},
    {label:'第五球',value:5},
  ],
  ChannelDrawResult:{
    web:{
      header:[
        [{label:'显示号码',value:'0'},{label:'显示大小',value:'1'},{label:'显示单双',value:'2'}],
        [{label:'总和',value:'zh'}],
        [{label:'龙虎',value:'lh'}]
      ],
      rowProperty:[
        (obj:ILatestItem,index:string)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateChannelResult(obj,index)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{GenerateChannelZH(obj)}</div>
        },
        (obj:ILatestItem)=> {
          return <div className=' h-full w-full flex cellWrapper'>{generateChannleLH(obj)}</div>
        }
      ]
    },
    mobile:{
      header:[
        {label:'号码',value:'0'},{label:'大小',value:'1'},{label:'单双',value:'2'},
        {label:'总和/龙虎',value:'zh/lh'},
      ],
      rowProperty:
        (obj:ILatestItem,index:string,isRndNum?:number)=> {
          return <div className=' h-full w-full flex justify-center items-center'>{GenerateMobileChannelResult(obj,index,isRndNum)}</div>
        }
      
    }
  },
  ShuBanHaoMaTrend:{
    web:{
      header:generateWebShuBanHeader,
      rowProperty:generateWebShuBanRow,
    },
    mobile:{
      header:generateMobileShuBanHeader,
      rowProperty:generateMobileShuBanRow, 
    }
  }
}

export default SYXW;