import axios from 'axios';

const instance = axios.create({
  baseURL:  process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

instance.interceptors.request.use(
  config => config,
  error => {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
);

export default instance;