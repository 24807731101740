import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CaretLeftOutlined, MenuOutlined, CheckOutlined } from '@ant-design/icons/lib/icons';

import './styles.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ILotteryList } from '../../../types';
import { AppDispatch, RootState } from '../../../redux/store';
import appActions from '../../../redux/actions/appActions';
import { useTranslation } from 'react-i18next';

import Logo from '../../../assets/image/common/companyLogo.png';
import Brush from '../../../assets/mobile/image/Brush.png';
import noticeActions from '../../../redux/actions/noticeActions';
import MobileKillPlanSideMenu from '../../../components/Mobile/MobileKillPlanSideMenu';
import MobileSideMenu from '../../../components/Mobile/MobileSideMenu';

import {AboutUsMenu} from '../../../constants/utils/index';


const mapStateToProps = (state: RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
  theme: state.appReducers.theme,
  aboutTab: state.appReducers.aboutTab,
})

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  setTheme: (value:string) => dispatch(appActions.setTheme(value)),
});

interface IProps{
  lotteryListData:ILotteryList, 
  theme:string,
  setTheme:(value:string)=>void;
  aboutTab:string
}

function MobileHeader ({lotteryListData,theme,setTheme, aboutTab}:IProps){   

  const location = useLocation();

  const {t} = useTranslation();

  const [colorPanelOpen, setColorPanelOpen] = useState(false)

  const [headerEle, setHeaderEle] = useState<JSX.Element>(<div className='text-white text-[24px]'>ABC123</div>)

  const { templateAndGame } = useAppSelector((state) => state.appReducers)  
  const { lotteryNewsId } = useAppSelector((state) => state.noticeReducers)  

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [mobileKillPlanMenuIsVisible, setMobileKillPlanMenuIsVisible] = useState(false)
  const [mobileLotteryMenuIsVisible, setMobileLotteryMenuIsVisible] = useState(false)

  useEffect(()=>{
    let leftNode:JSX.Element = <CaretLeftOutlined  onClick={()=>navigate(-1)} />  ;
    let middleNode:JSX.Element = <div></div>;
    let rightNode:JSX.Element = <div></div>;
    switch (location?.pathname) {
      case '/changlong':
        middleNode = <div>{t('CHANG_LONG_REMINDER')}</div>
        break;
      case '/chart':
        middleNode = <div>{t('TREND_CHART')}</div>
        break;
      case '/help':
        middleNode = <div>{t('HELP_CENTER')}</div>
        break;
      case '/news':{
        if(lotteryNewsId){
          leftNode = <CaretLeftOutlined  onClick={()=>dispatch(noticeActions.setLotteryNewsId(''))} />  ;
        }
        middleNode = <div>{!lotteryNewsId ? t('NEWS_INFO') : t('NEWS_CONTENT')}</div>
      }
        break;
      case '/about':{
        middleNode = <div>{AboutUsMenu.find(x=>x.value === aboutTab)?.label}</div>
      }
        break;
      case '/plan':{
        const temp = lotteryListData.lotterys.find(x=>x.code === templateAndGame.code)?.name ?? '';
        middleNode = <div>{temp}</div>
        rightNode = <>
          <MenuOutlined  onClick={()=> setMobileKillPlanMenuIsVisible(true)} />
          <MobileKillPlanSideMenu mobileKillPlanMenuIsVisible={mobileKillPlanMenuIsVisible} setMobileKillPlanMenuIsVisible={setMobileKillPlanMenuIsVisible} />
        </>
      }
        break;
      case '/lottery':{
        const temp = lotteryListData.lotterys.find(x=>x.code === templateAndGame.code)?.name ?? '';
        middleNode = <div>{temp}</div>
        rightNode = <>
          <MenuOutlined  onClick={()=> setMobileLotteryMenuIsVisible(true)} />
          <MobileSideMenu mobileLotteryMenuIsVisible={mobileLotteryMenuIsVisible} setMobileLotteryMenuIsVisible={setMobileLotteryMenuIsVisible} />
        </>
      }
        break;
      default:{
        leftNode = <div className='text-white text-[24px] flex items-center justify-center  h-full'>
          <img src={Logo} alt="" srcSet="" className='h-[25px]' onClick={()=>window.open('https://166kai.com','_blank')}  />
        </div>
        rightNode = <div className='h-full w-full flex items-center justify-end'>
          <div className={` h-full flex justify-center items-center px-2 ${colorPanelOpen ? 'colorPanelActive' : ''}`} onClick={()=> setColorPanelOpen(!colorPanelOpen)} >
            <img src={Brush} alt="" srcSet="" className='h-[30px]'/>
          </div>
          <div className={`colorPopover absolute gap-[10px] ${colorPanelOpen ? 'grid' : ' hidden'} `}>
            {['orange','blue','red','green'].map(item=> <div key={item} onClick={()=>{ setColorPanelOpen(false);localStorage.setItem('themeColor', item);setTheme(item)}} className={`colorBlock ${item}`}>{theme === item ? <CheckOutlined /> : ''}</div> )
            }
          </div>
          <MenuOutlined  onClick={()=> setMobileLotteryMenuIsVisible(true)} />
          <MobileSideMenu mobileLotteryMenuIsVisible={mobileLotteryMenuIsVisible} setMobileLotteryMenuIsVisible={setMobileLotteryMenuIsVisible} />
        </div>
      }
        break;
    }
    setHeaderEle(
      <div className='w-full flex items-center'>
        <div className=' w-1/4 flex items-center h-full'>
          {leftNode}
        </div>
        <div className=' w-1/2 flex items-center justify-center'>
          {middleNode}
        </div>
        <div className=' w-1/4 flex items-center justify-end h-full'>
          {rightNode}
        </div>
      </div>
    );    
  },[location, templateAndGame, colorPanelOpen, mobileKillPlanMenuIsVisible, mobileLotteryMenuIsVisible])    
  
  return ( 
    <div className='mobileHeaderWrapper w-full flex items-center'>
      {headerEle}
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);