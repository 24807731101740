import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';

import './styles.scss';
import {ILatestItem, ILotteryList} from '../../../types/index';

import templates from '../../../constants/templates';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import appActions from '../../../redux/actions/appActions';
import dayjs from 'dayjs'

import defaultLogo from '../../../assets/image/defaultLogo.png';

const mapStateToProps = (state:RootState) => ({
  lotteryListData: state.lotteryReducers.lotteryList,
});

interface IProps{
  lotteryListData:ILotteryList,
  current:string,
  target:string, 
  item:ILatestItem,
  clickable?: boolean
}


function MobileHomeDrawItem ({lotteryListData,current, target, item, clickable = true}:IProps){   
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isRndNum, setIsRndNum] = useState(0);
  const [timeResult, setTimeResult] = useState<string | number>()  

  const [gameImage, setGameImage] = useState();
  useEffect(()=>{
    if(item?.code){
      setIsRndNum(lotteryListData.lotterys.find(x=>x.code === item.code)?.isRndNum ?? 0)
      loadImage(item.code);
    }
  },[item?.code])

  const loadImage = (imageName:string) => {
    import(`../../../assets/image/${imageName}.png`).then(image => {        
      setGameImage(image.default)
    }).catch(()=>{
      setGameImage(undefined)
    });
  };

  function getDifferenceTime(current:string, target:string){    
    const targetTime = dayjs(target).valueOf();//获取当前时间对应的毫秒数
    const currentTime = Date.parse(current);//获取八点对应的毫秒数    
    const differenceTime = Math.floor((targetTime - currentTime) / 1000);
    
    if(differenceTime <= 0){
      return differenceTime
    }else{
      const hours = Math.floor(differenceTime / 3600);
      const minutes = Math.floor((differenceTime - (hours * 3600)) / 60);
      const seconds = differenceTime - hours * 3600 - minutes * 60
      return `${hours.toString().padStart(2,"0")}:${minutes.toString().padStart(2,"0")}:${seconds.toString().padStart(2,"0")}`
    }   
  }     
  
  const onItemClick = (subMenu?:string)=>{
    if(clickable){
      const le6Url = process.env.REACT_APP_LE6_URL ?? 'https://le66.me/lottery/';
      if(item?.template === 'HK6' && le6Url){
        return window.open(`${le6Url}${item.code.toLocaleLowerCase()}`,'_blank')
      }
      if(subMenu){
        dispatch(appActions.setSubSelection(subMenu));
      }
      dispatch(appActions.setTemplateAndCode({template:item.template ?? '',code:item.code}));
      navigate('/lottery');
    }
  }

  useEffect(()=>{
    setTimeResult(getDifferenceTime(current,target))
  },[current,target]) 
  
  return ( 
    <div className='MobileHomeDrawItem flex items-center' onClick={()=>onItemClick()}>
      <img className='homeGameImage' src={gameImage || defaultLogo} alt="图片缺失" srcSet="" />
      <div className='flex-1 h-full pl-2'>
        <div className='flex'>
          <span className='font-bold'>{item.name}</span>
          <span className='installmentWrapper pl-[8px]'>{`${item.issue}期`}</span>
          <div className=' ml-auto font-bold'>
            {
              typeof timeResult === 'number' ? <div className='flex'>开奖中 <div className="dots-1"></div></div> : timeResult
            }
          </div>
        </div>
        {
          item?.group === 1000 &&
        <div className='my-[5px]'>
          <span className=' text-[#3B3B3B]'>{`当前${item.index}期,`}</span>
          <span className=' text-[#3B3B3B]'>{`剩${item.openCount - item.index}期`}</span>
        </div>
        }
        <div className='flex items-center'>
          {item?.draw_code && templates[`${item.template}`] &&
          <div className={`flex text-[12px] ${templates[`${item.template}`].templateWrapper}`}>
            {templates[`${item.template}`].GenerateNumber(item).map((item:{value:string | number,className:string,imageURL?:string}, index:number)=> {
              return item?.imageURL ? <img  key={`${item.value}_${index}`} className={`${item.className}`} src={`${item.imageURL}`} alt="" srcSet="" /> : <div className={`${item.className}`} key={`${item.value}_${index}`}>{item.value}</div>
            }
            ) }
          </div>
          }
          {isRndNum === 1 && <div className='flex justify-center items-center font-bold'>+{item?.rnd}</div>}
        </div>

      </div>
    </div>
  );
}
export default connect(mapStateToProps, null)(MobileHomeDrawItem);
