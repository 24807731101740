import { AnimatePresence, motion } from 'framer-motion';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ILotteryItem } from '../../../types';
import './styles.scss';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import appActions from '../../../redux/actions/appActions';
import { CaretLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {category} from '../../../constants/utils/index';

const mapStateToProps = (state:RootState) => ({
  killPlanLotterys: state.lotteryReducers.killPlanLotterys,
});
interface IProps{
  killPlanLotterys:ILotteryItem[], 
  mobileKillPlanMenuIsVisible:boolean,
  setMobileKillPlanMenuIsVisible:(value:boolean)=>void
}

function MobileKillPlanSideMenu ({killPlanLotterys, mobileKillPlanMenuIsVisible, setMobileKillPlanMenuIsVisible}:IProps){
  const {t} = useTranslation()
  const navigate = useNavigate();

  const { templateAndGame } = useAppSelector((state) => state.appReducers);
  const dispatch = useAppDispatch();

  const [lotteryData, setLotteryData] = useState<{[key:string]:ILotteryItem[]}>({});

  useEffect(()=>{
    if(killPlanLotterys?.length > 0){
      const temp = killPlanLotterys.reduce((a:{[key:string]:ILotteryItem[]},b)=>{
        if(category.has(b.template)){
          if(a[b.template]){
            return {...a,[b.template]:a[b.template].concat(b)}
          }
          else{
            return {...a,[b.template]:[b].concat([])}
          }
        }
        return a;
      },{})
      setLotteryData(temp);
    }
  },[killPlanLotterys])

  const onClickToNavigate = (x:ILotteryItem)=>{
    dispatch(appActions.setTemplateAndCode({template:x.template,code:x.code}));
    setMobileKillPlanMenuIsVisible(false)
  }  
  
  return <AnimatePresence>
    {mobileKillPlanMenuIsVisible && (
      <motion.div
        className='mobileKillPlanSideMenuWrapper'
        initial={{ x: '100vw'}}
        animate={{ x: 0 }}
        exit={{x:'100vw'}}
        transition={{ duration: 0.5 }}
      >
        <div className='menuHeader flex items-center'>
          <div className=' w-1/4 flex items-center'>
            <CaretLeftOutlined onClick={()=>  setMobileKillPlanMenuIsVisible(false)} />
          </div>
          <div className=' w-1/2 flex items-center justify-center'>
            杀号计划
          </div>
          <div className=' w-1/4 flex items-center justify-end text-[14px]' onClick={()=> {
            setMobileKillPlanMenuIsVisible(false)
            navigate('/help')
          }}>
            <QuestionCircleOutlined />
            <div className='ml-1'>{t('HELP_CENTER')}</div>
          </div>
        </div>
        <div className='lotteryContent'>
          {
            Object.entries(lotteryData).map(
              ([key,value])=> 
                <div key={`${key}`} className=' mt-[10px]'>
                  <div className='categoryName'>{category.get(key)}</div>
                  <div className='flex flex-wrap'>
                    {
                      value.map(x=>  <div onClick={()=>onClickToNavigate(x)} className={` mt-2 buttonWrapper ${templateAndGame.code === x.code ? 'selected' : ''}`} key={`${x.name}`}>{`${x.name}`}</div> )
                    }
                  </div>
                </div> 
            )
          }
        </div>
      </motion.div>
    )}
  </AnimatePresence>

}  


export default connect(mapStateToProps, null)(MobileKillPlanSideMenu);
