import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appActions from '../../../redux/actions/appActions';
import { useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';

import {ILotteryItem} from '../../../types/index';

import {category} from '../../../constants/utils/index';

import './styles.scss';

const mapStateToProps = (state:RootState) => ({
  killPlanLotterys: state.lotteryReducers.killPlanLotterys,
});

interface IProps{
  killPlanLotterys:ILotteryItem[]
}

function PlanLotteryList ({killPlanLotterys}:IProps){   
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [lotteryData, setLotteryData] = useState<{[key:string]:ILotteryItem[]}>({});

  const onClickToNavigate = (x:ILotteryItem)=>{
    dispatch(appActions.setTemplateAndCode({template:x.template,code:x.code}));
    navigate('/plan');
  }  

  useEffect(()=>{
    if(killPlanLotterys?.length > 0){
      const temp = killPlanLotterys.reduce((a:{[key:string]:ILotteryItem[]},b)=>{
        if(category.has(b.template)){
          if(a[b.template]){
            return {...a,[b.template]:a[b.template].concat(b)}
          }
          else{
            return {...a,[b.template]:[b].concat([])}
          }
        }
        return a;

      },{})
      setLotteryData(temp);
    }
  },[killPlanLotterys])
   
  return ( 
    <div className='webPlanLotteryList w-screen'>
      {
        Object.entries(lotteryData).map(
          ([key,value])=> 
            <div key={`${key}`} className='p-[15px] flex items-center border-b border-[#dcdcdc]'>
              <div className='categoryName'>{category.get(key)}</div>
              <div className='flex flex-1 flex-wrap items-center'>
                {
                  value.map(x=>  <div onClick={()=>onClickToNavigate(x)} className={` mx-4 lotteryItem`} key={`${x.code}`}>{`${x.name}`}</div> )
                }
              </div>
            </div> 
        )
      }
    </div>
  );
}
export default connect(mapStateToProps, null)(PlanLotteryList);
